<template>
    <f7-page name="rejectionReasons" @page:beforeremove="removeSelectedRejection">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title >Rechazos</f7-nav-title>
            <f7-nav-right>
                <f7-link icon-material="refresh" tooltip="Recargar Lista" @click="getRejections"></f7-link>
                <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-material="search"></f7-link>
            </f7-nav-right>
            <f7-searchbar
                class="searchbar-demo"
                remove-diacritics
                expandable
                search-container=".search-list"
                search-in=".item-title"
                :disable-button="!$theme.aurora"
                placeholder="Buscar motivo de rechazo"
            ></f7-searchbar>
        </f7-navbar>

        <f7-list class="searchbar-not-found">
            <f7-list-item title="No hay coincidencias"></f7-list-item>
        </f7-list>
        <f7-list class="no-margin-top search-list searchbar-found" media-list>
            <f7-list-item 
                v-for="(reason, index) in $store.getters.objToArray('allReasons')" 
                :key="index"
                :title="reason.name"
                link="#"
                :class="{selected:(selectedReason.id === reason.id)}"
                @click="goToDetails(reason)"
            ></f7-list-item>
        </f7-list>
        
        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close @click="redirectNewRejection" label="Crear nuevo motivo de rechazo">
                    <f7-icon material="playlist_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>
    </f7-page>
</template>

<script>

import { mapState} from 'vuex';

export default {

    data(){
        return {
            
        }
    },
    computed: {
        ...mapState(['allReasons', 'selectedReason']),
    },
    methods: {
        getRejections(){
            this.$f7.dialog.preloader('Cargando');
            this.$firebase.database().ref('rejections/').orderByChild('enabled').equalTo(true).once('value')
                .then((snapshot) => {
                    this.$store.commit('setObjTo', {what: 'allReasons', to: snapshot.val()})
                    this.$f7.dialog.close();
                })
                .catch((err)=>{
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },

        goToDetails(reason){
            this.$store.commit('setObjTo',{what: 'selectedReason', to: reason});
            this.$f7.views.main.router.navigate('/rejection-details/');
        },

        removeSelectedRejection(){
            this.$store.commit('setWhatTo', {what: 'selectedReason', to: {} });
        },

        redirectNewRejection(){
            this.$store.commit('setWhatTo', {what: 'selectedReason', to: {} });
            if(this.$f7.views.main.router.currentRoute.url === '/rejections/'){
                this.$f7.views.main.router.navigate('/new-rejection/');
                }
            else{
                this.$f7.views.main.router.navigate('/new-rejection/', {reloadCurrent: true});
            }
        }
    },
    mounted(){
        if(!this.allReasons){
            this.getRejections();
        }
    }
}
</script>

<style scoped>
    .selected{
        border-left: 7px solid #36b5aa;
        color: #2d968c;
        background-color: #f3f3f3;
    }
</style>