<template>
	<f7-page name="resultados">
    	<f7-navbar  >
      		<f7-nav-left>
        		<f7-link icon-material="menu" panel-open="left"></f7-link>
      		</f7-nav-left>
      		<f7-nav-title title="Búsqueda paciente" subtitle="Paso 1"></f7-nav-title>
      		<f7-nav-right>
						<f7-block>
        		<f7-preloader v-if="cargandoDataSearch"></f7-preloader>
						</f7-block>
      		</f7-nav-right>
        	<f7-subnavbar :inner="false">
				<f7-searchbar
					:disable-button="true"
					remove-diacritics
					placeholder="por nombres o id"
					custom-search
					@searchbar:search="buscame"
					@searchbar:clear="$store.commit('setObjTo',{'what':'focusedPacienteForResults',to:{}})"
					@searchbar:disable="$store.commit('setObjTo',{'what':'focusedPacienteForResults',to:{}})"
				></f7-searchbar>
    		</f7-subnavbar>
    	</f7-navbar>

		<f7-block v-if="!cargandoDataSearch && typing">{{ typing }}</f7-block>
		<f7-block v-else-if="cargandoDataSearch"> Buscando...</f7-block>
		<f7-list class="no-margin-top paxspreresult" v-else-if="message && resultsBusqueda.length>0" media-list>
			<f7-list-item
				v-for="(unpax,idx) in resultsBusqueda"
				:key="'r'+idx"
				:title="lastNamesAndNames(unpax)"
				:subtitle="formatId(unpax)"
				:text="unpax.nacionalidad"
				:after="suage(unpax.fechaNacimiento)"
				link="#"
				:class="{selectedpax: unpax.id==focusedPacienteForResults.id}"
				@click="navegaAPaciente(unpax)"
			> <f7-icon slot="subtitle" :class="{fem: unpax.sexo=='Femenino'}" material="brightness_1"></f7-icon> </f7-list-item>
		</f7-list>
		<f7-block v-else-if="message && !resultsBusqueda.length"> Sin resultados para {{message}}</f7-block>
  	</f7-page>
</template>

<script>
import { mapState } from 'vuex';
import {paxhelpersmixin} from '../mixins/paxhelpersmixin';


export default {
  mixins: [paxhelpersmixin],
	data:function(){
    	return {
      	message: null,
				typing: null,
				debounce: null,
				cargandoDataSearch: false,
				resultsBusqueda:[]
    	}
	  },
   	computed: {
    	...mapState(['focusedPacienteForResults'])
  	},

  	methods: {
		navegaAPaciente(pacienteMeta){
			this.$store.commit('setObjTo',{what: 'focusedPacienteForResults', to: pacienteMeta});
			this.$f7.views.main.router.navigate('/resultado_paciente/');
		},

		buscame(searchbar, query, previousQuery){
			this.debounceSearch(query);
		},

		debounceSearch(query) {
			this.message = null
			this.typing = 'Ingresando búsqueda'
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.typing = null;
				this.message = query;
				this.serverSearh(query);
			}, 600);
		},
		serverSearh(query){
			let sinspace=query.trim();
			if(sinspace){
				this.cargandoDataSearch = true;
				let searchPatient = this.$firebase.functions().httpsCallable('searchPatient');
				searchPatient({busqueda: sinspace ,prod: (this.$store.state.env=== 'production'?true:false)})
					.then(reslts=>{
						this.cargandoDataSearch = false;
						this.resultsBusqueda = reslts.data.searchResults || [];
						console.log(this.resultsBusqueda);
					}).catch(error=>{
						this.cargandoDataSearch = false;
						this.$f7.dialog.alert(error.message,error.code);
					})
			}
		},
	},

	beforeDestroy(){
        console.log('apagando');
      	this.$store.commit('setObjTo',{'what':'focusedPacienteForResults',to:{}});
    },
}
</script>

<style>
.paxspreresult .item-subtitle .icon {
		font-size: 15px;
    margin-left: 4px;
    padding-bottom: 2px;
		color:#009fff;
}
.paxspreresult .item-subtitle .icon.fem{
	color:#ff00af!important;
}

.paxspreresult .selectedpax .item-title, .paxspreresult .selectedpax  .item-subtitle, .paxspreresult .selectedpax .item-after span{
	font-weight: bolder!important;
}

.paxspreresult .selectedpax .item-link{
	border-left: 9px solid #36b4aa;
}
</style>