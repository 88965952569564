<template>
    <f7-page name="editPatientMeta">
        <f7-navbar title="Editar Metadata de Paciente" back-link></f7-navbar>
        <f7-block class="text-align-center suprwarning">IMPORTANTE: Este módulo debe usarse solo para <b>corregir errores de ingreso/actualizaciones de datos del paciente</b>, cambios puntuales de información tales como: cambio de nombre, telefono, error al ingreso del rut, etc... Cualquier modificación afectará la admisión actual, y el mantenedor de pacientes (todas las admisiones futuras). <br/><b>NO UTILICE ESTE MODULO PARA 'CAMBIAR AL PACIENTE' asociado a la admisión.</b></f7-block>
         <New-patient :save="saveCambiosd" ></New-patient>
    </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import NewPatient from '../components/newPatient.vue';
import { saveInfoMixin } from '../mixins/save-info-mixin';

export default {
    components: {
        NewPatient
    },

    props:['admissionId'],

    computed: {
        ...mapState(['focused_orden', 'profile_userInfo', 'selectedAdmission']),
            patinfo(){
        return this.focused_orden.patinfo;
        },
    },

    mixins: [
        saveInfoMixin,
    ],

    mounted(){
        console.log(this.admissionId,'admissionId');
        console.log(this.focused_orden,'focused_orden');
    },

    methods:{
        saveCambiosd(id, patient, what){
            let allSaves=[
                this.$firebase.database().ref('pacientes/' + id).set(patient),
                this.$firebase.database().ref('admissions/' + this.admissionId +'/patient').set(patient),
            ];
            return Promise.all(allSaves).then(()=>{
                this.$store.commit('setObjTo', { what: 'pacienteSeleccionado', to: patient});
                if(this.selectedAdmission){
                    this.$store.commit('updateObjectAttribute', { obj: 'selectedAdmission', attr: 'patient', value: patient });
                }
                //guardar en la admision en foco tambien updateObjectAttribute
                if(this.focused_orden){
                    this.$store.commit('updateObjectAttribute', { obj: 'focused_orden', attr: 'patinfo', value: patient});
                }
                this.$f7.dialog.close();
                this.$f7.views.main.router.back();
                this.$f7.toast.create({text: 'Los datos se han guardado con éxito', closeTimeout: 2500}).open();
                this.savePatientLog(what, id).catch((error) => {
                    this.$f7.dialog.alert(error.message, error.code)
                });
                const logInfo = {
                    what: 'edit-admission',
                    details: 'Edita datos de paciente',
                    admissionId: this.admissionId,
                };
                this.saveAdmissionLog(logInfo).catch((error) => {
                    this.$f7.dialog.alert(error.message, error.code)
                });
            }).catch((err)=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.message, err.code);
            });
        },
    },
    
    beforeDestroy() {
		this.$store.commit('setWhatTo', { what: 'pacienteSeleccionado', to: null });
	}
}
</script>
<style>
.suprwarning{
    background: #ffe1e1;
    color: #5a0000;
    padding-top: 10px;
    padding-bottom: 10px;
}
</style>    