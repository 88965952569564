<template>
    <div>
        <f7-block>
            <f7-button 
                class="margin-top"
                icon-material="warning"
                fill
                color="purple"
                round
                popup-open=".admission-popup"
            >
                Admisión abreviada COVID-19
            </f7-button>
        </f7-block>

        <f7-popup class="admission-popup" @popup:open="getSampleTypes()">
            <f7-page>
                <f7-navbar title="Detalles de muestra">
                    <f7-nav-right v-if="selectedAdmission.opened">
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <div v-if="isLoading" class="text-align-center margin-top">
                    <f7-preloader color="teal"></f7-preloader>
                </div>
                <f7-block v-else nset class="margin-top-half"> 
                    <f7-block-title>Seleccione un tipo de muestra</f7-block-title>
                    <f7-list no-hairlines class="margin-top-half">
                        <f7-list-item 
                            v-for="sample in sampleTypes"
                            :key=sample.id
                            radio
                            :title="sample.name" 
                            :checked="sample.name === 'Hisopado nasofaríngeo'"
                            @change="selectSample(sample)"
                            name="sample-type"
                        >
                        </f7-list-item>
                        <!-- <f7-list-input
                            class="margin-top"
                            label="Fecha y hora toma de muestra"
                            type="datepicker"
                            placeholder="Fecha y hora toma de muestra"
                            readonly
                            clear-button
                            :calendar-params="{
                                closeOnSelect: true, 
                                value: defaultDate, 
                                timePicker: true, 
                                timePickerPlaceholder: 'Seleccione hora',  
                                maxDate: new Date(),  
                                dateFormat: 'dd/mm/yyyy HH::mm'
                            }"
                            @change="selectDate($event)"
                            error-message="Debe ingresar fecha y hora"
                            :error-message-force="invalidDate"
                        ></f7-list-input> -->
                    </f7-list>
                    <f7-row class="no-margin-bottom">
                        <f7-col width="100" medium="40">
                            <f7-list no-hairlines class="margin-top-half margin-right">
                                <f7-list-input
                                    id= "sample-calendar"
                                    label="Fecha y hora toma de muestra"
                                    type="datepicker"
                                    placeholder="Fecha y hora toma de muestra"
                                    readonly
                                    clear-button
                                    :calendar-params="{ 
                                        closeOnSelect: true,
                                        value: defaultDate,  
                                        timePicker: true, 
                                        timePickerPlaceholder: 'Seleccione hora',  
                                        maxDate: new Date() ,  
                                        dateFormat: 'dd/mm/yyyy HH::mm',
                                    }"
                                    @change="selectDate($event)" 
                                    error-message="Debe ingresar fecha y hora"
                                    :error-message-force="invalidDate"
                                ></f7-list-input>
                            </f7-list>
                        </f7-col>
                        <f7-col width="100" medium="60">
                            <f7-list no-hairlines class="margin-top-half margin-left">
                                <f7-list-item 
                                    checkbox
                                    class="margin-top"
                                    title="Fecha toma de muestra desconocida" 
                                    name="date-unknown"
                                    :key="newSample.dateUnknown"
                                    :checked="newSample.dateUnknown"
                                    @click="newSample.dateUnknown = !newSample.dateUnknown"
                                >
                                </f7-list-item>
                            </f7-list>
                        </f7-col>
                    </f7-row>

                        <f7-row v-if="selectedAdmission.opened">
                            <f7-col width="10" medium="20">
                            </f7-col>
                            <f7-col width="80" medium="60">
                                <f7-button
                                    fill
                                    round 
                                    @click="createSampleId()"
                                >
                                    Procesar admisión
                                </f7-button>
                                
                            </f7-col>
                            <f7-col width="10" medium="20">
                            </f7-col>
                        </f7-row>

                        <f7-row v-else>
                            <f7-col width="100" medium="40">
                            
                            </f7-col>
                            <f7-col width="100" medium="20">
                            </f7-col>
                            <f7-col width="0" medium="40">
                                <f7-button 
                                    outline
                                    popup-close
                                >
                                    Cerrar
                                </f7-button>
                            </f7-col>
                        </f7-row>
                </f7-block>
            </f7-page>
        </f7-popup>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { printermixin } from '../../mixins/printermixin';
import { paxhelpersmixin } from '../../mixins/paxhelpersmixin';
import { saveInfoMixin } from '../../mixins/save-info-mixin';

export default {
    mixins: [ printermixin, paxhelpersmixin, saveInfoMixin ],

    data(){
        return {
            sampleTypes: [],
            newSample: {
                id: '',
                name: 'Hisopado nasofaríngeo',
                externalId: '20',
                dateTime: '',
                dateUnknown: true,
                resp: null,
            },
            newExam: {
                id: '',
                externalId:'COVID-19',
                name: 'PCR Coronavirus (COVID-19)',
                section_id: '2',
                samples: {},
            },
            invalidDate: false,
            isLoading: true,
            defaultDate: [this.$moment()],
            preloader: '',

        }
    },

    props: ['processAdmission'],

    computed: {
        ...mapState(['env', 'selectedAdmission', 'profile_userInfo', 'activeTab'])
    },

    methods: {
        ...mapActions(['getAPIInfo']),

        openPopup(){
            this.$f7.popup.open('.admission-popup');
        },

        getSampleTypes(){
            this.isLoading = true
            this.$firebase.auth().currentUser.getIdToken()
                .then(idToken => this.getAPIInfo({url: 'exams/COVID-19' , userToken: idToken}))
                .then((resp) => {
                    this.sampleTypes = resp.data.data.samples;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error')
                })
        },

        selectSample(sample){
            this.newSample.name = sample.name;
            this.newSample.externalId = sample.id.toString();
        },

        selectDate(event){
            const date = event.target.value;
            this.invalidDate = false;
            this.newSample.dateTime = date;
            (this.$moment(this.defaultDate[0]).format('DD/MM/YYYY HH:mm') !== date) && (this.newSample.dateUnknown = false);
        },

        printLabel(){
            const patient = this.selectedAdmission.patient;
            const fullName = this.lastNamesAndNames(patient);
            this.makelabel(
                fullName, 
                this.formatId(patient), 
                patient.fechaNacimiento, 
                this.suage(patient.fechaNacimiento), 
                this.newSample.id, 
                this.newSample.name, 
                this.newSample.dateTime
            );
        },

        createSampleId(){
            if(!this.newSample.dateTime){
                this.invalidDate = true;
                return
            }
            this.preloader = this.$f7.dialog.preloader('Guardando')
            const createSampleId = this.$firebase.functions().httpsCallable('createSampleId');
            createSampleId({env: this.env}).then((resp) => {
                this.newSample.id = resp.data;
                this.printLabel();
                this.saveAdmission();
            }).catch((error)=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code);
            })
        },

        saveSample(){
            let sample = {};
            sample[this.newSample.id] = this.newSample; 
            this.$store.commit('updateObjectAttribute', {
                obj: 'selectedAdmission',
                attr: 'samples',
                value: sample
            });
            this.newExam.samples[this.newSample.id] = {
                id: this.newSample.id
            }
        },

        saveExam(){
            this.newExam.id = this.$firebase.database().ref('admissions/' + this.selectedAdmission.id + '/exams/').push().key;
            let exam = {};
            exam[this.newExam.id] = this.newExam;
            this.$store.commit('updateObjectAttribute', {
                obj: 'selectedAdmission',
                attr: 'exams',
                value: exam
            });
        },

        saveAdmission(){
            this.saveSample();
            this.saveExam();
            this.processAdmission().then(() => {
                this.preloader.close();
                this.showToast('Admisión guardada con éxito');
                this.$f7.dialog.create({
                    title: '¿Imprimir más etiquetas?',
                    buttons: [
                        {
                            text: 'Imprimir etiquetas',
                            cssClass: 'text-align-center',
                            close: false,
                            onClick: (() => {
                                this.printLabel();
                            })
                        },
                        {
                            text: 'Cerrar',
                            cssClass: 'text-align-center popup-close',
                            close: true,
                            onClick: (() => {
                                this.$f7.views.main.router.navigate('/admit/');
                            })
                        },
                    ],
                    verticalButtons: true,
                }).open();
            }).catch((err)=>{
                this.preloader.close();
                this.$f7.dialog.alert(err.message, err.code);
            })
        },
        
    },

}
</script>

<style scoped>

    .hidden{
        display: none;
    }

</style>