<template>
    <f7-page>
        <f7-navbar
            v-if="!isEditor" 
            :title="admittedExam ? 'Vincular muestra a examen' : 'Agregar muestra a admisión'" 
            :subtitle="admittedExam && admittedExam.name"
            back-link
        />
        <f7-navbar
            v-else
            title="Editar muestra"
            back-link
        />
        <f7-block class="margin-top-half">
           
            <admission-summary />

            <f7-list class="no-margin-bottom">
                <f7-list-item divider title="Datos de muestra"></f7-list-item>s
                <f7-list-item
                    :class="{ borderRed: invalidName }"
                    header="Tipo de muestra"
                    :title="newSample.name || 'Seleccione una muestra'"
                    :link="!isEditor ? '#' : false"
                    @click="!isEditor && openSampleSelector()"
                >
                </f7-list-item>
                <f7-list-item
                    v-if="isEditor"
                    header="Fecha toma de muestra"
                    :title="newSample.dateTime"
                >
                </f7-list-item>
            </f7-list>
            
            <span v-if="invalidName" class="error margin-left">Debe seleccionar algún tipo de muestra</span>
            
            <f7-row v-if="!isEditor" class="no-margin-bottom">
                <f7-col width="100" medium="40">
                    <f7-list no-hairlines class="margin-top-half margin-right">
                        <f7-list-input
                            id= "sample-calendar"
                            label="Fecha y hora toma de muestra"
                            type="datepicker"
                            placeholder="Fecha y hora toma de muestra"
                            readonly
                            clear-button
                            :calendar-params="{ 
                                closeOnSelect: true,
                                value: defaultDate,  
                                timePicker: true, 
                                timePickerPlaceholder: 'Seleccione hora',  
                                maxDate: new Date() ,  
                                dateFormat: 'dd/mm/yyyy HH::mm',
                            }"
                            @change="selectDate($event)" 
                            error-message="Debe ingresar fecha y hora"
                            :error-message-force="invalidDate"
                        ></f7-list-input>
                    </f7-list>
                </f7-col>
                <f7-col width="100" medium="60">
                    <f7-list no-hairlines class="margin-top-half margin-left">
                        <f7-list-item 
                            checkbox
                            class="margin-top"
                            title="Fecha toma de muestra desconocida" 
                            name="date-unknown"
                            :key="newSample.dateUnknown"
                            :checked="newSample.dateUnknown"
                            @click="checkNoDate"
                        >
                        </f7-list-item>
                    </f7-list>
                </f7-col>
            </f7-row>

            <f7-row v-if="newSample.name">
                <f7-col width="100" medium="40">
                    <f7-list no-hairlines-md class="margin-top-half">
                        <f7-list-item 
                            checkbox
                            :checked="newSample.isRejected" 
                            title="Rechazar muestra" 
                            @change="rejectSample"
                        ></f7-list-item>
                    </f7-list>
                </f7-col>
                <f7-col width="100" medium="60">
                    <div v-if="loadingRejections && newSample.isRejected" class="text-align-center margin-top">
                        <f7-preloader color="teal"></f7-preloader>
                    </div>
                    <f7-list no-hairlines-md class="no-margin-top" v-else-if="newSample.isRejected">
                        <f7-list-input
                            label="Motivo de rechazo"
                            type="select"
                            :value="newSample.reason"
                            @change="selectReason($event)"
                            error-message='Debe seleccionar un motivo de rechazo'
                            :error-message-force="invalidReason"
                        >
                            <option></option>
                            <option v-for="(rejection) in sampleRejections" 
                                :key="rejection.id"
                                :selected="newSample.reason === rejection.reason">
                                {{rejection.reason}}
                            </option>
                            <option>Otro motivo</option>
                        </f7-list-input>
                    </f7-list>
                </f7-col>
            </f7-row>

            <f7-list class="no-margin-bottom" media-list>
                <f7-list-item divider title="Datos responsable toma de muestra"></f7-list-item>
                <f7-list-item>
                    <span>Agregar datos de responsable </span>
                    <f7-toggle 
                        class="margin-left responsible-toggle" 
                        :checked="newSample.resp !== null && newSample.resp !== undefined"
                        @change="checkResponsible()"
                    >
                    </f7-toggle>
                </f7-list-item>
            </f7-list>

            <responsible-sample v-if="responsibleInfo" v-on:addResponsibleInfo="addResponsibleInfo" :responsibleData="newSample.resp" />

            <f7-row class="margin-top">
                <f7-col width="10" medium="25">
                </f7-col>
                <f7-col width="80" medium="50">
                    <f7-button 
                        v-if="!isEditor"
                        icon-material="playlist_add" 
                        raised 
                        fill
                        round 
                        @click="addSample()">
                        Agregar muestra
                    </f7-button>
                    <f7-button
                        v-else
                        icon-material="playlist_add" 
                        raised 
                        fill
                        round 
                        @click="editSample()">
                        Guardar cambios
                    </f7-button>
                </f7-col>
                <f7-col width="10" medium="25">
                </f7-col>
            </f7-row>
        </f7-block>

        <sample-selector :externalId="newSample.externalId" v-on:selectSample="selectSample" />
        
    </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { clean } from 'rut.js';
import { saveInfoMixin } from '../../mixins/save-info-mixin';
import { printermixin } from '../../mixins/printermixin';
import { paxhelpersmixin } from '../../mixins/paxhelpersmixin';
import AdmissionSummary from '../../components/admission-components/admission-summary';
import SampleSelector from '../../components/admission-components/sample-selector';
import ResponsibleSample from '../../components/admission-components/responsible-sample';

export default {
    data() {
        return {
            newSample: {
                id: '',
                name: '',
                externalId: '',
                dateTime: '',
                isRejected: null,
                reason: null,
                dateUnknown: true,
                resp: null
            },
            admission: {},
            defaultDate: [this.$moment()],
            sampleRejections: [],
            responsibleInfo: false,
            loadingRejections: false,
            invalidName: false,
            invalidDate: false,
            invalidReason: false,
            isEditor: false,
            samplePromises: [],
            linkedExamsInfo: {
                exams: {},
                sections: {}
            },
        }
    },

    props: ['sampleId', 'changeId'],

    components: {
        AdmissionSummary,
        SampleSelector,
        ResponsibleSample
    },

    computed: {
        ...mapState(['selectedAdmission', 'profile_userInfo', 'env', 'admittedExam']),
    },

    mixins:[ 
        saveInfoMixin, 
        printermixin, 
        paxhelpersmixin,
    ],

    methods: {
        ...mapActions(['getAPIInfo']),

        openSampleSelector() {
            this.$f7.popup.open('.sample-selector', false);
        },

        selectSample(sample) {
            this.invalidName = false;
            const newSample = {
                name: sample.name,
                externalId: sample.id.toString(),
                isRejected: null,
                reason: null,
            };
            this.newSample = {...this.newSample, ...newSample};
        },

        selectDate(event) {
            const date = event.target.value;
            this.invalidDate = false;
            this.newSample.dateTime = date;
            (this.$moment(this.defaultDate[0]).format('DD/MM/YYYY HH:mm') !== date) && (this.newSample.dateUnknown = false);
        },

        checkNoDate() {
            this.newSample.dateUnknown = !this.newSample.dateUnknown;
        },

        getRejections(){
            this.loadingRejections = true;
            this.$firebase.auth().currentUser.getIdToken()
                .then(idToken => this.getAPIInfo({url: `samples/${this.newSample.externalId}/rejections`, userToken: idToken}))
                .then((resp) => {
                    this.sampleRejections = resp.data.data
                    this.loadingRejections = false;
                })
                .catch((err) => {
                    if(err.response.status !== 404){
                        this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error');
                    }
                    else{
                        this.sampleRejections = [];
                        this.loadingRejections = false;
                    }
                });
        },

        rejectSample(event) {
            if(event.target.checked){
                this.newSample.isRejected  = true;
                this.getRejections();
            }
            else{
                this.newSample.isRejected  = null;
                this.newSample.reason = null;
            }
        },

        selectReason(event) {
            this.invalidReason = false;
            this.newSample.reason = event.target.value;
        },

        checkResponsible() {
            const toggle = this.$f7.toggle.get('.responsible-toggle');
            if(!toggle.checked){
                this.$f7.dialog.confirm(
                    '¿Está seguro de eliminar los datos agregados?', 
                    'Eliminar responsable',
                    ()=>{
                        this.responsibleInfo = false;
                        this.newSample.resp = null;
                    },
                    ()=>{
                        this.responsibleInfo = true;
                        toggle.checked = true;
                    }
                );
            }
            else{
                this.responsibleInfo = true;
            }
        },

        addResponsibleInfo(responsible) {
            this.newSample.resp = responsible;
        },

        cleanRUT(){
            if(this.newSample.resp){
                if(this.newSample.resp.idType === 'RUT'){
                    this.newSample.resp.identifier = clean(this.newSample.resp.identifier);
                }
            }
        },

        printLabel(){
            const patient = this.selectedAdmission.patient;
            const fullName = this.lastNamesAndNames(patient);
            this.makelabel(
                fullName, 
                this.formatId(patient), 
                patient.fechaNacimiento, 
                this.suage(patient.fechaNacimiento), 
                this.newSample.id, 
                this.newSample.name, 
                this.newSample.dateTime
            );
        },

        exchangeSample() {
            this.admission.exams = {...this.selectedAdmission.exams};
            Object.values(this.admission.exams).forEach((exam) => {
                let examSamples = {...exam.samples}
                if(examSamples[this.changeId]){
                    delete examSamples[this.changeId];
                    examSamples[this.newSample.id] = { id: this.newSample.id };
                    this.admission.exams[exam.id].samples = examSamples;
                    if(!this.selectedAdmission.opened){
                        this.samplePromises.push(this.$firebase.database().ref('admittedExams/' + exam.section_id + '/' + exam.id + '/samples/')
                        .set(examSamples))
                        const admittedSampleData = {
                            exams: null,
                            sections: null,
                        }
                        this.samplePromises.push(this.$firebase.database().ref('admittedSamples/' + this.changeId).update(admittedSampleData));
                    }
                }
            })
        },

        sendSampleData() {
            this.cleanRUT();
            this.admission = { ...this.selectedAdmission};
            this.admission.samples = { ...this.selectedAdmission.samples };
            this.admission.samples[this.newSample.id] = {...this.newSample};
            const currentDate = this.$moment().unix();
            if(this.changeId){
                this.exchangeSample();
            }
            if(!this.selectedAdmission.opened && !this.isEditor){
                this.admission.samples[this.newSample.id].admissionDate = currentDate;
                this.samplePromises = this.samplePromises.concat(this.addAdmittedSample(this.newSample, this.selectedAdmission, currentDate));
            }
            this.samplePromises.push(this.$firebase.database().ref('admissions/' + this.selectedAdmission.id).set(this.admission));
            Promise.all(this.samplePromises) 
                .then(()=>{
                    this.$store.commit('setObjTo', {
                            what: 'selectedAdmission',
                            to: this.admission,
                        });
                    this.$store.commit('setObjTo', {
                        what: 'previousResponsible',
                        to: this.newSample.resp,
                    })
                    this.showToast('Muestra guardada con éxito');
                    this.$f7.dialog.close();

                    if(!this.selectedAdmission.opened){
                        let details = 'Agrega muestra con ID: ' + this.newSample.id;
                        this.isEditor && (details = 'Edita datos de muestra con ID: ' + this.newSample.id);
                        const admissionLog = {
                            what: 'edit-admission',
                            details: details,
                            admissionId: this.selectedAdmission.id,
                        };
                        this.saveAdmissionLog(admissionLog).catch((error) => {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(error.message, error.code)
                        });
                    }
                    if(this.admittedExam){
                        let examSamples = { ...this.admittedExam.samples }
                        examSamples[this.newSample.id] = { id: this.newSample.id }
                        this.$store.commit('updateObjectAttribute', {
                            obj: 'admittedExam',
                            attr: 'samples',
                            value: examSamples,
                        });
                    }
                    this.$f7.views.main.router.back({ ignoreCache: true});
                })
                .catch((err)=>{
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                })
        },

        createSampleId(){
            const createSampleId = this.$firebase.functions().httpsCallable('createSampleId');
            createSampleId({env: this.env}).then((resp) => {
                this.newSample.id = resp.data;
                this.printLabel();
                this.sendSampleData();
            }).catch((error)=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code);
            })
        },

        addSample() {
            if(!this.newSample.name){
                this.invalidName = true;
                return
            }
            else if(!this.newSample.dateTime){
                this.invalidDate = true;
                return
            }
            else if(this.newSample.isRejected && !this.newSample.reason){
                this.invalidReason = true;
                return
            }
            (this.newSample.dateUnknown === false) && (this.newSample.dateUnknown = null);
            this.$f7.dialog.preloader('Cargando');
            this.createSampleId();   
        },

        editSample(){
            if(this.newSample.isRejected && !this.newSample.reason){
                this.invalidReason = true;
                return
            }
            this.sendSampleData();
        }
    },
    mounted() {
        if(this.sampleId && this.sampleId !== 'change'){
            this.isEditor = true;
            this.newSample = { ...this.selectedAdmission.samples[this.sampleId] };
        }
        else{
            this.openSampleSelector();
        }
    }
}
</script>

<style>

.error{
    color: #ff3b30;
    font-size: 12px;
}

.borderRed{
    border-top: 2px solid red;
    border-bottom: 2px solid red;
}

</style>