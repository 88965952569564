<template>
    <f7-page name="unit-details">
        <f7-navbar 
            title="Detalles unidad de medida" 
            back-link 
            back-link-url="/units/" 
            back-link-force
        >
        </f7-navbar>
        <f7-block-title v-if="!selectedUnit.name">
            Seleccione alguna unidad de medida
        </f7-block-title>
      
        <f7-block v-else>
        <f7-card class="demo-card-header-pic">
            <f7-card-header>
                <f7-block-title medium> {{selectedUnit.name}} </f7-block-title>
            </f7-card-header>
            <f7-card-footer>
                <f7-button 
                    fill 
                    small 
                    round 
                    icon-material="edit" 
                    @click="goToEdit()"
                >
                    Editar
                </f7-button>
                <f7-button 
                    fill 
                    small 
                    round 
                    icon-material="delete" 
                    color="red"
                    @click="deleteUnit()"
                >
                    Eliminar
                </f7-button>
            </f7-card-footer>
        </f7-card>
        </f7-block>

    </f7-page>    
</template>
<script>
import { mapState } from 'vuex';

export default {
    data(){
        return {
        }
    },

    computed: {
        ...mapState(['selectedUnit', 'profile_userInfo'])
    },

    methods: {
        goToEdit(){
            this.$f7.views.main.router.navigate('/new-unit/');
        },

        deleteUnit(){
            this.$f7.dialog.confirm('¿Está seguro que desea eliminar la unidad de medida?', 'Eliminar', 
                () => {
                    this.$firebase.database().ref('units/' + this.selectedUnit.id).remove()
                    .then(()=>{
                        //Se agrega información al nodo log
                        let logInfo = {
                            who: this.profile_userInfo.uid,
                            wht: 'delete-unit',
                            whn: this.$moment().unix(),    
                            dls: 'Elimina agrupador de exámenes',
                            pth: 'units/' + this.selectedUnit.id,
                        }
                        let logId = this.$firebase.database().ref('/log').push().key;
                        this.$firebase.database().ref('log/' + logId).set(logInfo)
                            .catch((err)=>{console.log(err.message)})
                            
                        this.$store.commit('deleteObjectAttribute', {
                            obj: 'allUnits', 
                            attr: this.selectedUnit.id,
                        });

                        this.$store.commit('setObjTo',{ what:'selectedUnit', to: {} });
                        this.$f7.toast.create({text: 'Unidad de medida eliminada', closeTimeout: 2500}).open();
                    })
                    .catch((err)=>{
                        this.$f7.dialog.alert(err.message, err.code);
                    })
                }
            );
        },
    },

}
</script>

<style scoped>

.label{
    color: #8e8e93;
}

</style>