<template>
    <f7-page name="new-processing-method">
        <f7-navbar v-if="!isEditor" title="Agregar nuevo método de procesamiento" back-link></f7-navbar>
        <f7-navbar v-else title="Editar método de procesamiento" back-link></f7-navbar>

        <f7-list no-hairlines-md>
            <f7-list-input
                label="Nombre del método"
                type="text"
                name="method-name"
                placeholder="Ingrese nombre del método"
                :value="method.name"
                @input="method.name = $event.target.value"
                required validate
                error-message="Debe indicar el nombre del método de procesamiento"
            ></f7-list-input>
        </f7-list>

        <f7-block>
            <f7-row>
                <f7-col width="10" medium="33">
                </f7-col>
                <f7-col width="0" medium="33">
                    <f7-button
                        :icon-material="!isEditor ? 'playlist_add' : 'edit'"
                        fill
                        raised
                        round
                        :text="!isEditor ? 'Agregar nuevo método' : 'Guardar cambios'"
                        @click="!isEditor ? addProcessingMethod() : editProcessingMethod()" 
                    />
                </f7-col>
                <f7-col width="10" medium="33">
                </f7-col>
            </f7-row>
        </f7-block>
        
    </f7-page>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    data(){
        return {
            isEditor: false,
            method: {
                name: ''
            }
        }
    },

    computed: {
        ...mapState(['selectedMethod', 'profile_userInfo', 'baseUrl', 'processingMethods']),
    },

    methods: {
        ...mapActions(['getAPIInfo', 'createNewInfo', 'updateInfo', 'deleteAPIInfo']),

        setAddOrEdit(){
            //Si está activado el modo de editar método se deben mostrar datos del método seleccionado
            if(this.selectedMethod.name){
                this.isEditor = true;
                this.method = {...this.selectedMethod};
            }
        },

        addProcessingMethod(){
            if(!this.method.name){
                this.$f7.dialog.alert('Debe ingresar un nombre para el método', 'Error')
                return
            }
            this.$f7.dialog.preloader('Guardando');
            const existingMethod = this.processingMethods && this.processingMethods.find((method) => {
                return method.name.toUpperCase() === this.method.name.toUpperCase()
            });
            if(existingMethod){
                this.$f7.dialog.close();
                this.$f7.dialog.alert('Ya existe un método con el mismo nombre', 'Error')
                return
            }
            this.$firebase.auth().currentUser.getIdToken()
            .then((idToken) => {
                return this.createNewInfo({url: 'exam_processing_methods/', data: this.method, userToken: idToken})
            })
            .then((resp) => {
                this.$store.commit('pushToArray', {array: 'processingMethods', value: resp.data.data})
                this.$store.commit('setObjTo', {what: 'selectedMethod', to: resp.data.data});
                
                //Se agrega información al nodo log
                let info = {
                    who: this.profile_userInfo.uid,
                    wht: 'add-method',
                    whn: this.$moment().unix(),    
                    dls: 'Crea nuevo método de procesamiento',
                    pth: this.baseUrl + '/exam_processing_methods/',
                }
                let logId = this.$firebase.database().ref('/log').push().key;
                this.$firebase.database().ref('log/' + logId).set(info)
                    .catch((err)=>{console.log(err.message)})

                this.$f7.dialog.close();
                this.$f7.toast.create({
                    text: 'Los datos se han guardado con éxito', 
                    closeTimeout: 2000, 
                    position: "center"
                }).open();
                this.$f7.views.main.router.navigate('/method-details/');
            })
            .catch((err) => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error')
            });
        },

        editProcessingMethod(){
            if(!this.method.name){
                this.$f7.dialog.alert('Debe ingresar un nombre para el método', 'Error')
                return
            }
            this.$f7.dialog.preloader('Guardando');
            const existingMethod = this.processingMethods.find((method) => method.name.toUpperCase() === this.method.name.toUpperCase());
            if(existingMethod){
                this.$f7.dialog.close();
                this.$f7.dialog.alert('Ya existe un método con el mismo nombre', 'Error')
                return
            }
            this.$firebase.auth().currentUser.getIdToken()
            .then((idToken) => {
                const method = {
                    name: this.method.name
                }
                return this.updateInfo({url: 'exam_processing_methods/' + this.selectedMethod.id, data: method, userToken: idToken})
            })
            .then(() => {
                //Se agrega información al nodo log
                let logInfo = {
                    who: this.profile_userInfo.uid,
                    wht: 'edit-method',
                    whn: this.$moment().unix(),    
                    dls: 'Edita método de procesamiento',
                    pth: this.baseUrl + 'exam_processing_methods/' + this.selectedMethod.id,
                }
                let logId = this.$firebase.database().ref('/log').push().key;
                this.$firebase.database().ref('log/' + logId).set(logInfo)
                    .catch((err)=>{console.log(err.message)})

                this.$store.commit('replaceInArray', {array: 'processingMethods', attr: 'id', value: this.selectedMethod.id, newValue: this.method});
                this.$store.commit('setObjTo', {what: 'selectedMethod', to: this.method});
                this.$f7.dialog.close();
                this.$f7.toast.create({
                    text: 'Los cambios se han guardado con éxito', 
                    closeTimeout: 2000, 
                    position: "center"
                }).open();
                this.$f7.views.main.router.navigate('/method-details/');
            })
            .catch((err) => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error')
            });
        }
    },

    mounted(){
        this.setAddOrEdit();
    }
}
</script>