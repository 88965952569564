import HomePage from "../pages/home.vue";
import profile from "../pages/profile.vue";
import members from "../pages/members.vue";
import member from "../pages/member.vue";
import privilegios from "../pages/privilegios.vue";
import hold from "../pages/hold.vue";
import pdfviewer from "../pages/pdf.vue";
import scanner from "../pages/scanner";
import SampleInfo from "../pages/sample-info";
import SampleMovementSelector from "../pages/sample-movement-selector";
import ChangeSampleLocation from "../pages/change-sample-location";
import Export from "../pages/export";

import coladetrabajo from "../pages/coladetrabajo.vue";
import ordendetrabajo from "../pages/ordenTrabajo.vue";
import pendientesIngreso from "../pages/pendientesIngreso.vue";
import pendientesfirma from "../pages/pendientesfirma.vue";
import historicofirmados from "../pages/historicofirmados.vue";
import ExamsFilter from "../pages/exams-filter.vue";
import editPatientMeta from "../pages/editPatientMeta.vue";

import HomeAdmission from "../pages/admission/homeAdmission";
import NewOrderAdmission from "../pages/admission/newOrderAdmission";
import Admission from "../pages/admission/admission.vue";
import NewExamAdmission from "../pages/admission/new-exam-admission.vue";
import SampleAdmission from "../pages/admission/new-sample-admission.vue";
import ImportAdmissions from "../pages/admission/import-admissions";

import resultados from "../pages/resultados";
import resultado_paciente from "../pages/resultado_paciente";

import pcrcovidbatch from "../pages/pcrcovidbatch";

import paxs from "../pages/maintainers/paxs";
import onepax from "../pages/maintainers/onepax";

import pacientes from "../pages/maintainers/pacientes";
import neopaciente from "../pages/maintainers/neopaciente";
import PatientDetails from "../pages/maintainers/patientDetails";

import Institutions from "../pages/maintainers/institutions";
import NewInstitution from "../pages/maintainers/newInstitution";
import InstitutionDetails from "../pages/maintainers/institutionDetails";

import Samples from "../pages/maintainers/samples";
import NewSample from "../pages/maintainers/newSample";
import SampleDetails from "../pages/maintainers/sampleDetails";

import Agreements from "../pages/maintainers/agreements";
import NewAgreement from "../pages/maintainers/newAgreement";
import AgreementDetails from "../pages/maintainers/agreementDetails";

import RejectionReasons from "../pages/maintainers/rejections";
import NewRejectionReason from "../pages/maintainers/newRejection";
import RejectionDetails from "../pages/maintainers/rejectionDetails";

import Exams from "../pages/maintainers/exams";
import NewExam from "../pages/maintainers/newExam";
import ExamDetails from "../pages/maintainers/examDetails";

import ProcessingMethods from "../pages/maintainers/processing-methods";
import NewProcessingMethod from "../pages/maintainers/new-processing-method";
import ProcessingMethodDetails from "../pages/maintainers/processing-method-details";

import Units from "../pages/maintainers/units";
import NewUnit from "../pages/maintainers/new-unit";
import UnitDetails from "../pages/maintainers/unit-details";

import zebra from "../pages/zebra.vue";
var routes = [
    {
        path: "/",
        component: HomePage,
    },
    {
        path: "/profile/",
        component: profile,
        options: {
            transition: "f7-cover-v",
        },
    },

    {
        path: "/pcrcovidbatch/",
        component: pcrcovidbatch,
    },
    {
        path: "/printer/",
        component: zebra,
    },
    {
        path: "/admit/",
        component: HomeAdmission,
    },
    {
        path: "/resultados/",
        component: resultados,
        master: true,
        detailRoutes: [
            {
                path: "/resultado_paciente/",
                component: resultado_paciente,
            },
        ],
    },
    {
        path: "/coladetrabajo/",
        component: coladetrabajo,
    },
    {
        path: "/editPatientMeta/:admissionId",
        component: editPatientMeta,
    },
    {
        path: "/pendientesIngreso/",
        component: pendientesIngreso,
    },
    {
        path: "/pendientesfirma/",
        component: pendientesfirma,
    },
    {
        path: "/historicofirmados/",
        component: historicofirmados,
    },
    {
        path: "/exams-filter/",
        component: ExamsFilter,
    },
    {
        path: "/ordendetrabajo/",
        component: ordendetrabajo,
    },
    {
        path: "/pdfviewer/",
        component: pdfviewer,
    },
    {
        path: "/scanner/",
        component: scanner,
    },
    {
        path: "/hold/",
        component: hold,
    },
    {
        path: "/sample-info/",
        component: SampleInfo,
    },
    {
        path: "/sample-move/",
        component: SampleMovementSelector,
    },
    {
        path: "/sample-location/",
        component: ChangeSampleLocation,
    },
    //Rutas Mantenedores
    {
        path: "/members/",
        component: members,
        master: true,
        detailRoutes: [
            {
                path: "/member/",
                component: member,
            },
        ],
    },
    {
        path: "/pacientes/",
        component: pacientes,
        master: true,
        detailRoutes: [
            {
                path: "/neopaciente/",
                component: neopaciente,
            },
            {
                path: "/paciente/",
                component: PatientDetails,
            },
        ],
    },
    {
        path: "/paxs/",
        component: paxs,
        master: true,
        detailRoutes: [
            {
                path: "/onepax/",
                component: onepax,
            },
        ],
    },
    {
        path: "/privilegios/",
        component: privilegios,
    },
    {
        path: "/institutions/",
        component: Institutions,
        master: true,
        detailRoutes: [
            {
                path: "/new-institution/",
                component: NewInstitution,
            },
            {
                path: "/institution/",
                component: InstitutionDetails,
            },
        ],
    },
    {
        path: "/samples/",
        component: Samples,
        master: true,
        detailRoutes: [
            {
                path: "/new-sample/",
                component: NewSample,
            },
            {
                path: "/sample/",
                component: SampleDetails,
            },
        ],
    },
    {
        path: "/agreements/",
        component: Agreements,
        master: true,
        detailRoutes: [
            {
                path: "/new-agreement/",
                component: NewAgreement,
            },
            {
                path: "/agreement/",
                component: AgreementDetails,
            },
        ],
    },
    {
        path: "/rejections/",
        component: RejectionReasons,
        master: true,
        detailRoutes: [
            {
                path: "/new-rejection/",
                component: NewRejectionReason,
            },
            {
                path: "/rejection-details/",
                component: RejectionDetails,
            },
        ],
    },
    {
        path: "/exams/",
        component: Exams,
    },
    {
        path: "/new-exam/",
        component: NewExam,
    },
    {
        path: "/exam/:examId",
        component: ExamDetails,
    },
    {
        path: "/exam/",
        component: ExamDetails,
    },
    {
        path: "/methods/",
        component: ProcessingMethods,
        master: true,
        detailRoutes: [
            {
                path: "/new-method/",
                component: NewProcessingMethod,
            },
            {
                path: "/method-details/",
                component: ProcessingMethodDetails,
            },
        ],
    },
    {
        path: "/units/",
        component: Units,
        master: true,
        detailRoutes: [
            {
                path: "/new-unit/",
                component: NewUnit,
            },
            {
                path: "/unit-details/",
                component: UnitDetails,
            },
        ],
    },
    {
        path: "/export/",
        component: Export,
    },
    {
        path: "/admission/",
        component: Admission,
    },
    {
        path: "/exam-admission/:examId",
        component: NewExamAdmission,
    },
    {
        path: "/exam-admission/",
        component: NewExamAdmission,
    },
    {
        path: "/sample-admission/:sampleId/:changeId",
        component: SampleAdmission,
    },
    {
        path: "/sample-admission/:sampleId",
        component: SampleAdmission,
    },
    {
        path: "/sample-admission/",
        component: SampleAdmission,
    },
    {
        path: "/add-order/",
        component: NewOrderAdmission,
    },
    {
        path: "/import-admissions/",
        component: ImportAdmissions,
    },
];

export default routes;
