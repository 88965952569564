<template>
    <f7-page class="backgris" name="coladetrabajo" @page:beforein="traedata(true)">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title  :title="focused_section.name" subtitle="Gestión de la sección"></f7-nav-title>
            <f7-nav-right>
                <f7-block><f7-preloader v-if="cargando"></f7-preloader></f7-block>
                <f7-link @click="traedata(true)"><f7-icon material="refresh"></f7-icon></f7-link>
            </f7-nav-right>
        </f7-navbar>
    <f7-list class="no-margin-top">
        <f7-list-item divider title="Menu"></f7-list-item>
        <f7-list-item link="/pendientesIngreso/" :badge="exsConMustrsPendIngrASeccion.length||'0'" :badge-color="exsConMustrsPendIngrASeccion.length==0?'':'red'" footer="Examenes admitidos a Inmunocel" :title="'Exs con muestras pendiente de ingreso a '+focused_section.name"></f7-list-item>
        <f7-list-item link="/pendientesfirma/" :badge="oppositeexsConMustrsPendIngrASeccion.length||'0'" :badge-color="oppositeexsConMustrsPendIngrASeccion.length==0?'':'red'" :footer="'Examenes ingresados a '+focused_section.name" title="Cola de Trabajo"></f7-list-item>
        <f7-list-item link="/historicofirmados/" footer="Log exámenes firmados" title="Examenes"></f7-list-item>
        <f7-list-item link="/exams-filter/" footer="Filtro de exámenes por fecha e institución" title="Filtro exámenes firmados"></f7-list-item>
    </f7-list>


    <f7-list v-if="focused_section.id=='2'" class="margin-top">
        <f7-list-item divider title="PCR COVID Batch Process"></f7-list-item>
        <f7-list-item link="/pcrcovidbatch/" footer="Firma masiva de Examenes de PCR COVID-19" title="Firma en lotes"></f7-list-item>
    </f7-list>
    </f7-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    data(){
        return{
            cargando: false
        }
    },

      watch:{
        focused_section(newval){
             this.traedata(true);
        }
    },
      computed: {
        ...mapState(['focused_section','exmsPendFirma']),
        ...mapGetters(['exsConMustrsPendIngrASeccion','oppositeexsConMustrsPendIngrASeccion'])
    },

    methods: {
           traedata(force){
            if(this.exmsPendFirma.length==0 || force){
                  this.cargando=true;
            this.$store.commit('setWhatTo',{what:'exmsPendFirma',to:[]});
            let allexams={};
            let promesxSamplesToCall=[];
            this.$firebase.database().ref('admittedExams/'+this.focused_section.id).orderByChild('firmado').equalTo(null).once('value').then(multisnaos=>{
                multisnaos.forEach(childnsap => {
                    let unsnap=childnsap.val();
                    let samples=unsnap.samples || {};
                    let sampleskeys=Object.keys(samples) || [];
                    sampleskeys.forEach(unasampleid => {
                        promesxSamplesToCall.push(this.$firebase.database().ref('admittedSamples/' + unasampleid ).once('value'));
                    });
                });
                allexams=multisnaos.val() || {};
             return Promise.all(promesxSamplesToCall);
            }).then(multisnaps=>{
                multisnaps.forEach(unsnap => {
                    let lasample=unsnap.val() || {};
                    let susexams=lasample.exams || {};
                    let examsids=Object.keys(susexams) || [];
                    examsids.forEach(unexamid => {
                        if(!allexams[unexamid]){
                            console.log('adimte samples',lasample);
                            console.log('trajo resultados del examen',unexamid,'que NO esta en ',allexams);
                        }
                        else{
                            allexams[unexamid].samples[unsnap.key]=lasample;
                        }
                    });
                });
                console.log('FULLDATED',allexams);
                 let allUnsigned=Object.values(allexams) || [];
                 this.$store.commit('setWhatTo',{what:'exmsPendFirma',to:allUnsigned});
                 this.cargando=false;
            }).catch(error=>{
                 this.cargando=false;
                console.log('errores',error);
            });
            }
        }
    },
     mounted(){
       // this.traedata(false);
    }

 

}
</script>
<style>


</style>