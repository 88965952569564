<template>
    <f7-page @page:beforeremove="apagaObs()" name="ordersAdmission">
        <f7-navbar title="Órdenes" back-link>
        </f7-navbar>

        <f7-block-title class="margin-top margin-left">
            Órdenes asociadas a esta admisión
        </f7-block-title>
        <f7-list media-list>
            <f7-list-item v-if="Object.keys(ordenes).length === 0"> 
                No existen órdenes asociadas a esta admisión
            </f7-list-item>
            <f7-list-item 
                v-for="(unaorden, okey) in ordenes" 
                :key="okey" 
                :title="unaorden.fileName" 
                :subtitle="unaorden.contentType"
            > 
                <f7-link 
                    icon-material="pageview" 
                    @click="vamosAlPreview(unaorden)" 
                    icon-size="30" 
                    class="margin0auto" 
                    slot="media"
                >
                </f7-link>
                <f7-link 
                    @click="borrarOrden(okey)" 
                    icon-material="delete" 
                    class="text-color-gray" 
                    slot="after"
                >
                </f7-link>
            </f7-list-item>
        </f7-list>

        <input 
            type="file" 
            id="myorden-input" 
            @change="filesChange($event)" 
            accept="image/*,application/pdf" 
        />
        <f7-photo-browser
            :photos="photos"
            type="standalone"
            popup-close-link-text="Cerrar"
            navbar-of-text="de"
            ref="photovista"
        >
        </f7-photo-browser>

        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon material="close"></f7-icon>
            <f7-fab-buttons>
                <f7-fab-button 
                    fab-close 
                    @click="subitOrden()" 
                    label="Seleccionar archivo"
                >
                    <f7-icon material="attachment"></f7-icon>
                </f7-fab-button>
                <f7-fab-button 
                    fab-close 
                    @click="qrcodeOpened = true" 
                    label="Desde el celular"
                >
                    <f7-icon material="important_devices"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>

        <f7-popup 
            class="qr-orden-popup" 
            :opened="qrcodeOpened" 
            @popup:closed="qrcodeOpened = false">
            <f7-page>
                <f7-navbar title="Carga remota de documentos">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block>
                    <p>Escanee este código QR con Inmunocel Portal desde su celular...</p>
                    <qrcode-vue 
                        :value="stringToQode" 
                        size="300" 
                        level="L" 
                        class="margin-top text-align-center"
                    >
                    </qrcode-vue>
                </f7-block>
            </f7-page>
        </f7-popup>

    </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import QrcodeVue from 'qrcode.vue'
import { scannmixin } from '../../mixins/scannmixin';

export default {
    components: {
      QrcodeVue,
    },

    mixins: [ scannmixin ],
    
    data(){
        return {
            progressdialog: null,
            notifLoading: null,
            observableOrdenes: null,
            ordenes:{},
            photos:[],
            qrcodeOpened: false
        }
    },

    computed: {
        ...mapState(['profile_userInfo']),

        stringToQode(){
            return 'scan-order@' + this.selectedAdmission.id;
        },

        lasOrdenes(){
            return this.selectedAdmission.ordenes || {};
        }
    },

    methods: {
        vamosAlPreview(laorden){
            if(laorden.photoIDX >= 0){
                this.$refs.photovista.open(laorden.photoIDX);
            }
            else if(laorden.contentType == 'application/pdf'){
                this.$store.commit('setWhatTo', { what:'focusedPDF',to:laorden.fileUrl });
                this.$f7.views.main.router.navigate('/pdfviewer/');
            }
        },

        borrarOrden(oredrkey){
            this.$f7.dialog.confirm('Confirme que desea eliminar esta órden', () => {
                this.$f7.dialog.preloader('Eliminando...');
                this.$firebase.database().ref('admissions/' + this.selectedAdmission.id + '/ordenes/' + oredrkey)
                .set(null).then(() => {
                    this.$f7.dialog.close();
                }).catch(err => {
                    this.$f7.dialog.close();
                });
            });
        },

        subitOrden: function () {
            this.$$('#myorden-input').click();
        },

        apagaObs(){
            if(this.observableOrdenes){
                console.log('APAGANDO')
                this.observableOrdenes.off()
            }
        },

        encenderObservableOrdenes(){
        if(this.observableOrdenes){
            this.observableOrdenes.off()
        }
        this.observableOrdenes = this.$firebase.database().ref('admissions/' + this.selectedAdmission.id + '/ordenes');
        this.observableOrdenes.on('child_added', (data) => {
             //aqui cargar al arreglo de imagenes
             let ladata=data.val();
             let contentType=ladata.contentType || 'no/no';
             if(contentType.includes("image")){
                 let topush={
                     caption:ladata.fileName,
                     url: ladata.fileUrl
                 };
                 ladata.photoIDX = this.photos.push(topush) - 1;
             }
            

            let lallave=data.key;
            let lobg={};
            lobg[lallave]=ladata;
            this.ordenes=Object.assign({},this.ordenes,lobg);
            this.$store.commit('updateObjectAttribute',{obj:'selectedAdmission',attr:'ordenes',value:this.ordenes});

            if(this.notifLoading){
              this.notifLoading.close();
            }
        });
        this.observableOrdenes.on('child_removed', (data)=> {
             let prevorders=this.ordenes || {};
                let preval=prevorders[data.key] || {};
                if(preval.photoIDX){
                    //borrar
                    this.photos[preval.photoIDX]={
                     caption:'Imagen eliminada',
                     url: ''
                 };
                }
               
                 delete prevorders[data.key];
                 this.ordenes=Object.assign({},prevorders);
                this.$store.commit('updateObjectAttribute',{obj:'selectedAdmission',attr:'ordenes',val:this.ordenes});
        });
        },
        abrirLoadinNoti(){
        if(!this.notifLoading){
          this.notifLoading=this.$f7.notification.create({
            title:'Carga Ok',
            titleRightText:'100%',
            subtitle: 'Procesando imagen',
            text:'Esto puede tardar algunos segundos...',
            closeTimeout: 90000,
            cssClass: "procesImageUpload",
            icon: '<i class="material-icons">cloud_upload</i>'
        });
        }
        this.notifLoading.open();
      },

    },
    mounted(){
       this.encenderObservableOrdenes();
    }
}
</script>

<style >
#myorden-input{
    display:none!important;
}

</style>