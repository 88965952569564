<template>
    <f7-card>
        <f7-card-header class="justify-content-center">
            <f7-block-title medium> {{section.name}} </f7-block-title>
        </f7-card-header>
        <f7-card-content>
            <f7-list v-if="!examsBySection">
                <f7-list-item title="No hay exámenes en proceso">
                </f7-list-item>
            </f7-list>
            <f7-list media-list v-else>
                <f7-list-item 
                    v-for="(exam, index) in examsBySection"
                    :key="index"
                    :title="exam.name" 
                    :subtitle="examStatus(exam)"
                    >
                </f7-list-item>
            </f7-list>
        </f7-card-content>
    </f7-card>
</template>

<script>

export default {
    props: ['section', 'examsBySection'],

    data(){
        return {}
    },

    methods: {
        examStatus(exam){
            if(exam.firmado){
                return 'Firmado'
            }
            else if(exam.confirmado){
                return 'Confirmado'
            }
            else if(exam.ingresado){
                return 'Ingresado'
            }
            else{
                return 'Admitido'
            }
        }
    },

}
</script>