import Vuex from 'vuex';
import Vue from 'vue';
import * as moment from 'moment';
const axios = require('axios');

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        appName:'Portal',
        appUrl: 'https://portal.inmunocel.com',
        appVersion: '0.3.9',
        activeTab:'/',
        loginOpened: true,
        profile_userInfo: {},
        misprivx:{},
        defsPrivx:null,
        allMembers:[],
        loadingAvatar:'../static/img/ripple.gif',
        profile_defaultAvatar: '../static/img/default_original.png',
        focused_member:{},
        tempOldAvatar:'',
        env: process.env.NODE_ENV,
        sections: [],
        //cola de trabajo
        focused_section:{},
        focused_orden:null,
        exmsPendFirma:[],
        allMyFirmados:[],
        baseUrl: 'https://inmunocel-portal.uc.r.appspot.com/',
        //holder para url de pdf en vista
        focusedPDF: null, //no se puede pasar como prop por que incluye ->/ en la ruta de la url
        //Datos admisión de muestra
        openedAdmissions: null,
        selectedAdmission: {},
        filteredExams: [],
        admittedExam: null,
        previousDoctor: {},
        previousResponsible: {},
        //Datos mantenedor de pacientes
        pacienteSeleccionado: null,
        //Datos mantenedor institución
        allInstitutions: null,
        selectedInstitution: {},
        //Datos mantenedor tipos de convenio
        allAgreements: null,
        selectedAgreement: {},
        //Datos mantenedor de muestras
        allSamples: null,
        selectedSample: {},
        sampleRejections: [],
        //Datos mantenedor de motivos de rechazo
        allReasons: null,
        selectedReason: {},
        //Datos mantenedor de examenes
        allExams: null,
        selectedExam: null,
        examSamplesInfo: null,
        examResults: [],
        //Datos mantenedor de métodos de procesamiento
        processingMethods: null,
        selectedMethod: {},
        //Datos mantenedor agrupador de exámenes
        allExamsPacks: null,
        selectedExamsPack: {},
        //Datos mantenedor de unidades
        allUnits: null,
        selectedUnit: {},
        //PAXS
        allPaxsUsersHolder:[],
        focusedPax:{},
        //resultados
        focusedPacienteForResults:{},
        //pcr covid 19 stuff
        covidMethods:[],
        covidBatchToSign:[],
        instituxToFilter:{},
        covidActiveFilters:[]
    },
    getters: {
        myAvatar: state => {
                return (state.profile_userInfo && state.profile_userInfo.photoURL) ? state.profile_userInfo.photoURL : state.profile_defaultAvatar;
        },
        allMembers: state=>{
            let miembrs=state.allMembers;
            miembrs.sort((a,b)=> {
                let x = a.displayName.toLowerCase();
                let y = b.displayName.toLowerCase();
                if (x < y) {return -1;}
                if (x > y) {return 1;}
                return 0;
            });
            return miembrs;
        },
        objToArray: (state) => (obj) =>{
            let list = [];
            if(state[obj]){
                list = Object.values(state[obj])
                list.sort((a, b) => {
                    const x = a.name.toLowerCase();
                    const y = b.name.toLowerCase();
                    if(x > y){
                        return 1
                    }
                    else if(x < y){
                        return -1
                    }
                    else{
                        return 0
                    }
                });
            }
            return list
        },
        sortArray: (state) => (array) => {
            let list = [];
            if(state[array]){
                list = state[array].slice();
                list.sort((a, b) => {
                    const x = a.name.toLowerCase();
                    const y = b.name.toLowerCase();
                    if(x > y){
                        return 1
                    }
                    else if(x < y){
                        return -1
                    }
                    else{
                        return 0
                    }
                });
            }
            return list
        },
        age: (state) => {
            if(state.pacienteSeleccionado && state.pacienteSeleccionado.fechaNacimiento){
                let age = moment(state.pacienteSeleccionado.fechaNacimiento, "DD/MM/YYYY");
                let today = moment();
                let years = today.diff(age, 'years');
                age.add(years, 'y');
                let months = today.diff(age, 'months');
                if(years === 1){
                    if(months === 1){
                        return `${years} año y ${months} mes`
                    }
                    else{
                        return `${years} año y ${months} meses`
                    }
                }
                else if(months === 1) {
                    return `${years} años y ${months} mes`
                }
                else{
                    return `${years} años y ${months} meses`
                }
            }
        },
        exsConMustrsPendIngrASeccion: (state) => {
            return state.exmsPendFirma.filter(unexam=>{
                let samples=unexam.samples || {};
                let samplesArry=Object.values(samples) || [];
                let faltaAlguno = samplesArry.some(unasample => {
                   return (!unasample.sections || !unasample.sections[state.focused_section.id]);
                });
                return faltaAlguno;
             });
        },
        oppositeexsConMustrsPendIngrASeccion: (state, getters) => {
            return state.exmsPendFirma.filter(unexam=>{
                let found = getters.exsConMustrsPendIngrASeccion.find(eseexam => eseexam.id == unexam.id);
                return  !found;
             });
        },
        arrExsAunNoIngresados: (state) => {
            return state.exmsPendFirma.filter(unexam=>{
               return !unexam.ingresado;
            });
        },
        arrExsPendFirma: (state) => {
            return state.exmsPendFirma.filter(unexam=>{
               return unexam.ingresado;
            });
        },
        getFromArrayByProVal: (state) => (valor,arreglo,propiedad) => {
            return state[arreglo].find(uno => uno[propiedad] === valor);
        },
    },
    mutations: {
        conactToArray: (state,payload) => {
            let concatenado = state[payload.array].concat(payload.value);
            state[payload.array] = concatenado;
        },
        setWhatTo: (state,payload)=>{
            state[payload.what] = payload.to;
        },
        setObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},payload.to);
        },
        pushToArray: (state,payload) => {
            state[payload.array].push(payload.value);
        },
        removeFromArray: (state,payload)=>{
            let indiexist = state[payload.array].findIndex(unitem => unitem[payload.attr]==payload.value);
            if(indiexist!=-1){
                state[payload.array].splice(indiexist,1);
            }
        },
        removeFromArraySimple: (state,payload)=>{
            if(state[payload.array].includes(payload.value)){
                state[payload.array].splice(state[payload.array].indexOf(payload.value), 1);
            }
        },
        replaceInArray: (state, payload) => {
            let index = state[payload.array].findIndex((item) => item[payload.attr] == payload.value);
            if(index !== 1){
                state[payload.array].splice(index, 1, payload.newValue);
            }
        },
        updateMyAvatar: (state,avatarURL)=>{
            state.profile_userInfo.photoURL=avatarURL;
        },
        updateObjectAttribute: (state, payload) => {
            let neoobjs={};
            neoobjs[payload.attr]= payload.value;
            state[payload.obj] = Object.assign({},state[payload.obj],neoobjs);
        },
        deleteObjectAttribute: (state, payload) => {
            let obj = {...state[payload.obj]};
            delete obj[payload.attr];
            state[payload.obj] = obj;
        },
        populateInstituxToFilter: (state, payload) => {
            if(!state.instituxToFilter[payload.id]){
                let neoobjs={};
                neoobjs[payload.id]= payload.value;
                state.instituxToFilter =  Object.assign({},state.instituxToFilter,neoobjs);
            }
        },
    },
    actions:{
        getAPIInfo({ state }, payload){
            return axios.get(state.baseUrl + payload.url, {
                headers: {'Authorization': 'Bearer ' + payload.userToken}
            })
        },
        createNewInfo({ state }, payload){
            return axios.post(state.baseUrl + payload.url, payload.data, {
                headers: {
                    'Authorization': 'Bearer ' + payload.userToken,
                    'Content-Type': 'application/json',
                }
            })
        },
        updateInfo({ state }, payload){
            return axios.patch(state.baseUrl + payload.url, payload.data, {
                headers: {
                    'Authorization': 'Bearer ' + payload.userToken,
                    'Content-Type': 'application/json',
                }
            })
        },
        deleteAPIInfo({ state }, payload){
            return axios.delete(state.baseUrl + payload.url, {
                headers: {'Authorization': 'Bearer ' + payload.userToken}
            })
        }
    }
})