<template>
    <f7-popup class="sample-popup" @popup:opened="setSampleInfo" @popup:close="saveRejections">
        <f7-view>
            <f7-page>
                <f7-navbar :title="'Detalles de muestra: ' + selectedSample.name">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block inset class="margin-top-half"> 
                    <f7-list no-hairlines class="no-margin-bottom margin-top-half">
                        <f7-list-item 
                            checkbox 
                            title="Muestra requerida (obligatoria)" 
                            name="details-checkbox"
                            :checked="sample.is_required == 1" 
                            @change="checkRequiredSample">
                        </f7-list-item>
                    </f7-list>

                    <f7-row>
                        <f7-col>
                            <f7-list no-hairlines class="no-margin-vertical">
                                <f7-list-input
                                    label="Número de muestras mínimo"
                                    type="number"
                                    min="1"
                                    :value="sample.n_required_samples"
                                    @input="sample.n_required_samples = $event.target.value"
                                    required validate
                                    error-message="Debe indicar el número mínimo de muestras"
                                ></f7-list-input>
                                <f7-list-input
                                    label="Vólumen mínimo de la muestra (μL)"
                                    type="number"
                                    placeholder="Vol. mínimo"
                                    :value="sample.min_volume"
                                    @input="sample.min_volume = $event.target.value"
                                    required validate
                                    error-message="Debe indicar el volumen mínimo"
                                ></f7-list-input>
                            </f7-list>
                        </f7-col>
                        <f7-col>
                            <f7-list no-hairlines-md class="no-margin-vertical">
                                <f7-list-input
                                    label="Código LOINC"
                                    type="text"
                                    placeholder="Código LOINC"
                                    :value="sample.loinc_code"
                                    @input="sample.loinc_code = $event.target.value"
                                ></f7-list-input>
                            </f7-list>
                        </f7-col>
                    </f7-row>
                    
                    <f7-list no-hairlines-md class="no-margin-vertical">
                        <f7-list-input
                            label="Observaciones"
                            type="textarea"
                            placeholder="Ingrese observaciones (opcional)"
                            :value="sample.observations"
                            @input="sample.observations = $event.target.value"
                        ></f7-list-input>
                    </f7-list>

                    <div class="list margin-top-half margin-horizontal no-hairlines">
                        <ul>
                            <li>
                                <a href="#" class="item-link smart-select select-rejections" 
                                    data-searchbar="true"
                                    data-searchbar-placeholder="Busque los motivos de rechazo"
                                    @smartselect:close="selectRejections()"
                                    >
                                    <select name="select-rejections" multiple>
                                        <option 
                                            v-for="(rejection, index) in $store.getters.objToArray('allReasons')"
                                            :key="index" 
                                            :value="rejection.name"
                                            :selected="sample.rejections.includes(rejection.name)"
                                            >{{rejection.name}}
                                        </option>
                                    </select>
                                    <div class="item-content"> 
                                        <div class="item-inner">
                                            <div class="item-title">Motivos de rechazo</div>
                                            <div class="item-after" :key="isReloading">{{rejectionNames}}</div>
                                        </div>
                                    </div>      
                                </a>
                            </li>
                        </ul>
                    </div>

                    <f7-list no-hairlines-md >
                        <f7-list-item divider title="Tiempo de estabilidad de la muestra (Hrs)"></f7-list-item>
                        <f7-list-input
                            label="A temperatura ambiental"
                            type="number"
                            placeholder="Ingrese tiempo en horas"
                            :value="sample.ambient_storage_time"
                            @input="sample.ambient_storage_time = $event.target.value"
                        ></f7-list-input>
                        <f7-list-input
                            label="Refrigerada (2°C - 8°C)"
                            type="number"
                            placeholder="Ingrese tiempo en horas"
                            :value="sample.cooled_storage_time"
                            @input="sample.cooled_storage_time = $event.target.value"
                        ></f7-list-input>
                        <f7-list-input
                            label="Congelada"
                            type="number"
                            placeholder="Ingrese tiempo en horas"
                            :value="sample.frozen_storage_time"
                            @input="sample.frozen_storage_time = $event.target.value"
                        ></f7-list-input>
                    </f7-list>

                    <f7-row>
                        <f7-col width="10" medium="25">
                        </f7-col>
                        <f7-col width="80" medium="50">
                            <f7-button 
                                icon-material="playlist_add" 
                                fill 
                                @click="$emit('saveSample', sample)" 
                                popup-close
                                >Guardar cambios
                            </f7-button>
                        </f7-col>
                        <f7-col width="10" medium="25">
                        </f7-col>
                    </f7-row>
                </f7-block>
            </f7-page>
        </f7-view>
    </f7-popup>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    data(){
        return {
            sample: {
                name: '',
                sample_id: '',
                is_required: 0,
                n_required_samples: '',
                loinc_code: '',
                min_volume: 0,
                volume: 0, //evaluar si este campo debe mantenerse
                unit_id: 0, //evaluar si este campo debe mantenerse
                observations: '',
                ambient_storage_time: null,
                cooled_storage_time: null,
                frozen_storage_time: null,
                rejections: [],
            },
            rejections: [],
            isReloading: false,
            rejectionNames: ''
        }
    },
    props:{
        selectedSample: Object,
        state: Boolean,

    },
    computed: {
        ...mapState(['sections', 'allSamples']),
    },
    methods: {
        ...mapActions(['createNewInfo', 'deleteAPIInfo']),

        setSampleInfo(){
            this.sample = {...this.selectedSample};
            if(!this.sample.n_required_samples){
                this.sample.n_required_samples = 1;
            }
            if(this.sample.id){
                this.rejections = this.sample.rejections;
                this.sample.rejections = this.sample.rejections.map((rejection) => rejection.reason);
            }
            this.getSelectedRejections()
            this.$f7.dialog.close();
        }, 

        getRejections(){
            if(!this.allReasons){
                this.$firebase.database().ref('rejections/').orderByChild('enabled').equalTo(true).once('value')
                .then((snapshot) => {
                    this.$store.commit('setObjTo', {what: 'allReasons', to: snapshot.val()});
                })
                .catch((err)=>{
                    this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error');
                });
            }
        },

        checkRequiredSample(event){
            if(event.target.checked){
                this.sample.is_required = 1;
            }
            else{
                this.sample.is_required = 0;
            }
        },

        selectRejections(){
            const value = this.$f7.smartSelect.get('.select-rejections').getValue();
            if(this.sample.id){
                let userToken
                this.rejections.forEach((rejection, index) => {
                    if(!value.includes(rejection.reason)){
                        this.$firebase.auth().currentUser.getIdToken()
                        .then((idToken) => {
                            userToken = idToken;
                            return this.deleteAPIInfo({url: `exams/${this.sample.id}/rejections/${rejection.id}`, userToken});
                        })
                        .then(() => this.rejections.splice(index, 1))
                        .catch((err) => this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error'))
                    }
                });
                value.forEach((rejection) => {
                    if(!this.rejections.find((item) => item.reason === rejection)){
                        let newRejection = {reason: rejection};
                        this.$firebase.auth().currentUser.getIdToken()
                        .then((idToken) => {
                            userToken = idToken;
                            return this.createNewInfo({
                                url: `exams/${this.sample.id}/rejections`, 
                                data: newRejection, 
                                userToken
                            })
                        })
                        .then((resp) => this.rejections.push(resp.data.data))
                        .catch((err) => this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error'))
                    }
                })
            }
            this.sample.rejections = value;
            //Esto es para mostrar los valores recien seleccionados en el smart-select
            this.rejectionNames = '';
            value.forEach((rejection) => this.rejectionNames += (rejection + ', '))
            this.isReloading = !this.isReloading
            //
        },

        getSelectedRejections(){
            //Se obtienen los motivos de rechazo seleccionados para mostrarlas en el after del smart-select
            this.$f7.smartSelect.create({
                el: '.select-rejections', 
                setValueText: true, 
                formatValueText:(values) =>{
                    return this.sample.rejections
                }
            }) 
            // 
        },

        saveRejections(){
            if(this.sample.id){
                this.sample.rejections = this.rejections;
            }
            const rejectionsSelector = this.$f7.smartSelect.get('.select-rejections')
            rejectionsSelector && rejectionsSelector.destroy() 
        },
        
    },
    mounted(){
        this.getRejections();
    }
}
</script>