<template>
    <f7-popup 
        class="exam-selector" 
    >
        <f7-page>
            <f7-navbar title="Seleccione un examen">
                <f7-nav-right>
                    <f7-link popup-close>Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block>
                <f7-searchbar
                    disable-button-text="Cancel"
                    placeholder="Para buscar, ingrese el nombre de un examen"
                    search-container=".search-list"
                    search-in=".item-title"
                    :clear-button="true"
                    remove-diacritics
                    :backdrop="false"
                    class="searchbar"
                ></f7-searchbar>
               
                <f7-list class="searchbar-not-found">
                    <f7-list-item title="No se encontraron resultados"></f7-list-item>
                </f7-list>

                <f7-list class="search-list searchbar-found">
                    <f7-list-item 
                        radio
                        v-for="exam in $store.getters.sortArray('filteredExams')" 
                        :key="exam.id"
                        :title="exam.name" 
                        name="exam-radio"
                        :checked="externalId === exam.code"
                        @click="selectExam(exam)"
                    >
                    </f7-list-item>
                </f7-list>

            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
export default {
    props: ['externalId'],

    methods: {
        selectExam(exam) {
            this.$emit('selectExam', exam);
            this.$f7.popup.close('.exam-selector');
        }
    }
}
</script>