<template>
    <f7-page name="neopaciente">
        <f7-navbar v-if="!pacienteSeleccionado" title="Crear nuevo paciente" back-link></f7-navbar>
        <f7-navbar v-else title="Editar datos de paciente" back-link></f7-navbar>
        <New-patient :save="saveData"></New-patient>
    </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import { saveInfoMixin } from '../../mixins/save-info-mixin';
import NewPatient from '../../components/newPatient.vue';

export default {
    components: {
        NewPatient
    },

    computed: {
        ...mapState(['pacienteSeleccionado', 'profile_userInfo'])
    },

    mixins: [ 
        saveInfoMixin, 
    ],

    methods:{
        saveData(id, patient, what){
            this.$firebase.database().ref('pacientes/' + id).set(patient).then(()=>{
                this.$store.commit('setObjTo', {
                    what: 'pacienteSeleccionado',
                    to: patient,
                });
                this.$f7.dialog.close();
                this.$f7.views.main.router.navigate('/paciente/');
                this.showToast('Datos guardados con éxito');
                this.savePatientLog(what, id).catch((err) => {
                    this.$f7.dialog.alert(error.message, error.code)
                });
            }).catch((err)=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.message, err.code);
            });
        },
    } 
}
</script>