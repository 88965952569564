<template>
    <f7-page name="institutionDetails">
        <f7-navbar 
            title="Detalles Institución" 
            back-link
            back-link-url="/institutions/" 
            back-link-force
        >
        </f7-navbar>
        <f7-block-title v-if="!selectedInstitution.name">
            Seleccione alguna institución
        </f7-block-title>
      
        <f7-block v-else>
        <f7-card class="demo-card-header-pic">
            <f7-card-header>
                <f7-block-title medium> {{selectedInstitution.name}} </f7-block-title>
            </f7-card-header>
            <f7-card-content>
                <f7-block-title class="no-margin-top no-margin-left">DATOS INSTITUCIÓN</f7-block-title>
                <f7-row>
                    <f7-col width="20">
                        <p><span class="label">País:</span></p>
                    </f7-col>
                    <f7-col width="80">
                        <p>{{selectedInstitution.country}}</p>
                    </f7-col>
                </f7-row>
                <f7-row>
                    <f7-col width="20">
                        <p><span class="label">{{selectedInstitution.identifierType}}:</span></p>
                    </f7-col>
                    <f7-col width="80">
                        <p>{{selectedInstitution.identifier}}</p>
                    </f7-col>
                </f7-row>
                <f7-row>
                    <f7-col width="20">
                        <p><span class="label">Dirección:</span></p>
                    </f7-col>
                    <f7-col width="80">
                        <p>{{selectedInstitution.address}}</p>
                    </f7-col>
                </f7-row>
                <f7-row>
                    <f7-col width="20">
                        <p><span class="label">Tipo de convenios:</span></p>
                    </f7-col>
                    <f7-col width="80">
                        <p v-for="(value, key, index) in selectedInstitution.agreements" :key="index">
                            {{key}}
                        </p>
                    </f7-col>
                </f7-row>
                <f7-block-title class="no-margin-left">DATOS DE CONTACTO</f7-block-title>
                <f7-row>
                    <f7-col width="20">
                        <p><span class="label">Nombre:</span></p>
                    </f7-col>
                    <f7-col width="80">
                        <p>{{selectedInstitution.contactName}}</p>
                    </f7-col>
                </f7-row>
                <f7-row>
                    <f7-col width="20">
                        <p><span class="label">Teléfono:</span></p>
                    </f7-col>
                    <f7-col width="80">
                        <p>{{selectedInstitution.phone}}</p>
                    </f7-col>
                </f7-row>
                <f7-row>
                    <f7-col width="20">
                        <p><span class="label">Email:</span></p>
                    </f7-col>
                    <f7-col width="80">
                        <p>{{selectedInstitution.email}}</p>
                    </f7-col>
                </f7-row>              
            </f7-card-content>
            <f7-card-footer>
                <f7-button fill small round icon-material="edit" @click="goToEdit">
                    Editar
                </f7-button>
                <f7-button fill small round icon-material="delete" color="red" @click="deleteInstitution(selectedInstitution)">
                    Eliminar
                </f7-button>
            </f7-card-footer>
        </f7-card>
        </f7-block>

    </f7-page>    
</template>
<script>
import { mapState } from 'vuex';

export default {
    data(){
        return {
        }
    },

    computed: {
        ...mapState(['selectedInstitution', 'profile_userInfo'])
    },

    methods: {
        goToEdit(){
            this.$f7.views.main.router.navigate('/new-institution/');
        },
        deleteInstitution(){
            this.$f7.dialog.confirm('¿Está seguro que desea eliminar la institución?', 'Eliminar', 
                () => {
                    this.selectedInstitution.enabled = false;
                    this.$firebase.database().ref('institutions/' + this.selectedInstitution.id)
                    .set(this.selectedInstitution)
                    .then(()=>{
                        //Se agrega información al nodo log
                        let info = {
                            who: this.profile_userInfo.uid,
                            wht: 'delete-institution',
                            whn: this.$moment().unix(),    
                            dls: 'Elimina institucion',
                            pth: 'institutions/' + this.selectedInstitution.id,
                        }
                        let id = this.$firebase.database().ref('/log').push().key;
                        this.$firebase.database().ref('log/' + id).set(info)
                            .catch((err)=>{console.log(err.message)})
                            
                        //Se elimina institución de store
                        this.$store.commit('deleteObjectAttribute', {
                            obj: 'allInstitutions', 
                            attr: this.selectedInstitution.id
                        });
                        this.$store.commit('setWhatTo', {what: 'selectedInstitution', to: {} });
                        this.$f7.toast.create({text: 'Institución eliminada', closeTimeout: 2500}).open();
                    })
                    .catch((err)=>{
                        this.$f7.dialog.alert(err.message, err.code);
                    })
                }
            );
        }
    }
}
</script>

<style scoped>

.label{
    color: #8e8e93;
}

</style>