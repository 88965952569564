import accents from "remove-accents";

export const printermixin = {
    data() {
        return {
            selected_device: null,
            devices: [],
        };
    },
    mounted() {
        this.setup();
    },
    methods: {
        setup() {
            //Get the default device from the application as a first step. Discovery takes longer to complete.
            this.$BrowserPrint.getDefaultDevice(
                "printer",
                (device) => {
                    //Add device to list of devices and to html select element
                    this.selected_device = device;
                    this.devices.push(device);

                    //Discover any other devices available to the application
                    this.$BrowserPrint.getLocalDevices(
                        (device_list) => {
                            for (var i = 0; i < device_list.length; i++) {
                                //Add device to list of devices and to html select element
                                var device = device_list[i];
                                if (
                                    !this.selected_device ||
                                    device.uid != this.selected_device.uid
                                ) {
                                    this.devices.push(device);
                                }
                            }
                        },
                        () => {
                            alert("Error getting local devices");
                        },
                        "printer"
                    );
                },
                (error) => {
                    this.$f7.toast
                        .create({
                            text: "Error de conexión a Zebra",
                            position: "center",
                            closeTimeout: 2500,
                            destroyOnClose: true,
                        })
                        .open();
                    console.log(error);
                    console.log("edbio haber gatilado toast");
                }
            );
        },
        makelabel(
            namestinrg,
            rut,
            nacimiento,
            edad,
            codigo,
            muestra,
            fecha,
            seccion = "--"
        ) {
            namestinrg = accents.remove(namestinrg);
            namestinrg = namestinrg.substring(0, 30);
            muestra = accents.remove(muestra);
            muestra = muestra.substring(0, 20);
            seccion = accents.remove(seccion);
            nacimiento = nacimiento.replace(/-/g, "/");

            let codetosend=	'~CT~~CD,~CC^~CT~'+
                '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR4,4~SD15^JUS^LRN^CI0^XZ'+
                '^XA'+
                '^MMT'+
                '^PW831'+
                '^LL0168'+
                '^LS0'+
                '^FO64,64^A0N,20,30^BY3^BCN,50,Y,Y,D^FD>;'+codigo+'^FS'+

                '^FO480,64^A0N,20,30^BY3^BCN,50,Y,Y,D^FD>;'+codigo+'^FS'+


                '^FT50,150^A0N,19,18^FH\^FD'+namestinrg+'^FS'+
                '^FT270,34^A0N,19,16^FH\^FD'+fecha+'^FS'+
                '^FT384,131^A0B,19,14^FB84,1,0,R^FH\^FD'+rut+'^FS'+
                '^FT0,131^A0N,21,19^FB185,1,0,R^FH\^FD'+muestra+'^FS'+
                '^FT332,131^A0N,21,19^FH\^FD'+edad+'^FS'+
                '^FT247,131^A0N,19,16^FH\^FD'+nacimiento+'^FS'+
                '^FT234,36^A0N,21,19^FB20,1,0,R^FH\^FD'+seccion+'^FS'+

                '^FT467,150^A0N,19,18^FH\^FD'+namestinrg+'^FS'+
                '^FT690,34^A0N,19,16^FH\^FD'+fecha+'^FS'+
                '^FT800,131^A0B,19,14^FB84,1,0,R^FH\^FD'+rut+'^FS'+
                '^FT424,131^A0N,21,19^FB185,1,0,R^FH\^FD'+muestra+'^FS'+
                '^FT747,131^A0N,21,19^FH\^FD'+edad+'^FS'+
                '^FT667,131^A0N,19,16^FH\^FD'+nacimiento+'^FS'+
                '^FT654,36^A0N,21,19^FB20,1,0,R^FH\^FD'+seccion+'^FS'+
                '^PQ1,0,1,Y^XZ';

            console.log("printer code", codetosend);
            if (!this.selected_device) {
                return this.$f7.dialog.alert(
                    "No se ha podido determinar etiquetadora default"
                );
            }
            this.selected_device.send(
                codetosend,
                undefined,
                this.errorCallback
            );
        },
        errorCallback(errorMessage) {
            console.log("Error: " + errorMessage);
            return this.$f7.dialog.alert(errorMessage);
        },
        onDeviceSelected(selecteduid) {
            for (var i = 0; i < this.devices.length; ++i) {
                if (selecteduid == this.devices[i].uid) {
                    this.selected_device = this.devices[i];
                    return;
                }
            }
        },
    },
};
