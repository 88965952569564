<template>
    <f7-popup 
        class="institution-selector" 
        :opened="isOpened" 
        @popup:open="uploadInstitutionInfo()" 
        @popup:closed="isOpened = false"
    >
        <f7-page>
            <f7-navbar title="Seleccione una institución">
                <f7-nav-right>
                    <f7-link popup-close>Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block>
                <f7-searchbar
                    disable-button-text="Cancel"
                    placeholder="Para buscar, ingrese nombre institución"
                    search-container=".search-list"
                    search-in=".item-title"
                    :clear-button="true"
                    remove-diacritics
                    :backdrop="false"
                    class="searchbar"
                ></f7-searchbar>
               
                <f7-list class="searchbar-not-found">
                    <f7-list-item title="No se encontraron resultados"></f7-list-item>
                </f7-list>
                <f7-row class="margin-top">
                    <f7-col width="10" medium="25">
                    </f7-col>
                    <f7-col width="80" medium="50">
                        <f7-button 
                            class="searchbar-not-found"
                            raised 
                            fill
                            round 
                            icon-material="playlist_add"
                            @click="addNewInstitution()"
                            > Agregar nueva institución
                        </f7-button>
                    </f7-col>
                    <f7-col width="10" medium="25">
                    </f7-col>
                </f7-row>

                <f7-list class="search-list searchbar-found">
                    <f7-list-item 
                        radio
                        v-for="institution in $store.getters.objToArray('allInstitutions')" 
                        :key="institution.id"
                        :title="institution.name" 
                        name="institution-radio"
                        :value="institution.name"
                        :checked="institutionName === institution.name"
                        @click="selectInstitution(institution)"
                    >
                    </f7-list-item>
                </f7-list>

            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { mapState } from 'vuex';
import { saveInfoMixin } from '../../mixins/save-info-mixin';

export default {
    props: ['selectedInstitution'],

    mixins: [saveInfoMixin],

    data(){
        return {
            isOpened: false,
            institutionName: '',
            institution_id: '',
            agreementName: ''
        }
    },

    computed:{
        ...mapState(['selectedAdmission', 'profile_userInfo'])
    },

    methods: {
        getInstitutions(){
            this.$firebase.database().ref('institutions/').orderByChild('enabled').equalTo(true).once('value')
                .then((snapshot) => {
                    this.$store.commit('setObjTo', {what: 'allInstitutions', to: snapshot.val()});
                    this.$f7.dialog.close();
                })
                .catch((err)=>{
                    this.$f7.dialog.alert(err.message, err.code);
            });
        },

        uploadInstitutionInfo(){
            if(!this.allInstitutions){
                this.getInstitutions();
            }
            this.institutionName = this.selectedInstitution;
        },

        selectInstitution(institution){
            this.institutionName = institution.name;
            this.institution_id = institution.id;
            this.agreementName = 'Sin convenio';
            this.saveInstitutionInfo(institution);
        },

        saveInstitutionInfo(institution){
            this.$f7.dialog.preloader('Guardando');
            const institutionInfo = {
                institution: this.institutionName,
                agreement: this.agreementName,
                institution_id: this.institution_id,
            };
            this.$emit('sendAgreementsInfo', institution.agreements);
            this.$f7.popup.close('.institution-selector');
            const admission = {...this.selectedAdmission, ...institutionInfo}
            this.$firebase.database().ref('admissions/' + this.selectedAdmission.id).update(institutionInfo)
                .then(()=>{
                    this.$store.commit('setObjTo', {what: 'selectedAdmission', to: admission});
                    this.$f7.dialog.close();
                    this.showToast('Institución guardada con éxito');

                    if(!this.selectedAdmission.opened){
                        const logInfo = {
                            what: 'edit-admission',
                            details: 'Actualiza institución asociada',
                            admissionId: this.selectedAdmission.id,
                        };
                        this.saveAdmissionLog(logInfo).catch((error)=>{
                            this.$f7.dialog.alert(error.message, error.code)
                        });
                    }
                })
                .catch((err)=>{
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                })
        },

        addNewInstitution(){
            const searchbar = this.$f7.searchbar.get('.searchbar')
            this.$f7.dialog.prompt('Nombre:',
                'Crear nueva institución', 
                (name) => {
                    let institution = {
                        name: name,
                        country: 'Chile',
                        identifierType: 'RUT',
                        identifier: null,
                        address: null,
                        agreement: null,
                        contactName: null,
                        phone: null,
                        email: null,
                        id: '',
                        enabled: true
                    }
                    this.$f7.dialog.preloader('Guardando');

                    const id = this.$firebase.database().ref('/institutions').push().key;
                    institution.id = id,
                    this.$firebase.database().ref('institutions/' + id).set(institution)
                        .then(()=>{
                            this.$store.commit('updateObjectAttribute', {
                                obj: 'allInstitutions',
                                attr: id,
                                value: institution,
                            })
                            this.institutionName = institution.name;
                            this.institution_id = institution.id;
                            this.agreementName = 'Sin convenio';
                            this.$f7.dialog.close();
                            this.saveInstitutionInfo(institution);

                
                            let logInfo = {
                                who: this.profile_userInfo.uid,
                                wht: 'add-institution',
                                whn: this.$moment().unix(),    
                                dls: 'Crea nueva institucion',
                                pth: 'institutions/' + institution.id,
                            }
                            this.saveLogInfo('log/', logInfo).catch((error)=>{
                                this.$f7.dialog.alert(error.message, error.code)
                            })
                        })     
                        .catch((err)=>{
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(err.message, err.code);
                        })
                }, ()=>{searchbar.clear()}, searchbar.query
            );
        },
    }
}
</script>