<template>
    <div>
        <div class="collection-container margin-horizontal-half margin-bottom">
            <div 
                v-if="isLoadingExams" 
                class="text-align-center margin-vertical-half"
            >
                <p class="preloaderText">Cargando lista de exámenes...</p>
                <f7-preloader color="teal"></f7-preloader>
            </div>
            <div v-else class="list no-margin-vertical no-hairlines">
                <div class="list margin-horizontal margin-vertical-half">
                    <ul>
                        <li>
                            <a href="#" 
                                class="item-link smart-select select-exams" 
                                data-searchbar="true"
                                data-open-in="popup"
                                data-popup-close-link-text="Cerrar"
                                data-searchbar-placeholder="Busque los exámenes"
                                @smartselect:close="selectExams()"
                            >
                                <select name="select-exams" multiple>
                                    <option 
                                        v-for="(exam) in examsBySection"
                                        :key="exam.id" 
                                        :value="exam.code"
                                        :selected="checkSelectedExams(exam.code)"
                                    >
                                        {{exam.name}}
                                    </option>
                                </select>
                                <div class="item-content no-padding-left">
                                    <div class="item-inner">
                                        <div class="item-title">Seleccione los exámenes asociados a esta colección</div>
                                        <div class="item-after hidden"></div>
                                    </div>
                                </div>      
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <f7-list 
                media-list 
                class="no-margin-top margin-bottom-half" 
                :key="reloader"
                sortable 
                @sortable:sort="onSort"
                @sortable:enable="buttonTitle = 'Desactivar'"
                @sortable:disable="buttonTitle = 'Activar'"
            >
                <f7-list-item
                    v-for="(exam, index) in renderizedExams"
                    :key="index" 
                    :title="exam.name"
                >
                    <f7-link
                        slot="after"
                        tooltip="Eliminar examen de la lista"
                        icon-material="clear"
                        @click="removeExam(exam.code)">
                    </f7-link>
                </f7-list-item>
            </f7-list>
            <f7-row>
            <f7-col width="100" medium="70">
            </f7-col>
            <f7-col width="100" medium="30">
                <f7-button 
                    v-if="associatedExams.length > 1" 
                    sortable-toggle=".sortable" 
                    class="margin-horizontal margin-bottom-half"
                    outline 
                    small
                    round
                    :icon-material="buttonTitle === 'Activar' ? 'swap_vert' : ''"
                >
                    {{buttonTitle}} ordenar exámenes
                </f7-button>
            </f7-col>
        </f7-row>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    data(){
        return {
            renderizedExams: [],
            associatedExams: [],
            examsBySection: [],
            isLoadingExams: false,
            reloader: false,
            buttonTitle: 'Activar'
        }
    },

    computed: {
        ...mapState(['allExams', 'selectedExam'])
    },

    props: ['sectionId'],

    methods: {
        ...mapActions(['getAPIInfo']),

        emitAssociatedExamInfo(){
            const examsCodes= this.associatedExams.map((exam) => exam.code)
            this.$emit('selectAssociatedExams', examsCodes);
        },

        selectExams(){
            const selectedExams = this.$f7.smartSelect.get('.select-exams').getValue();
            this.associatedExams = selectedExams.map((code) => {
                let exam = this.examsBySection.find((exam) => exam.code === code); 
                return exam
            });
            this.renderizedExams = this.associatedExams.slice();
            this.reloader = !this.reloader;
            this.emitAssociatedExamInfo();
        },

        getExamsBySection(sectionId){
            this.isLoadingExams = true;
            let selectedExamId = 0;
            if(this.selectedExam){
                selectedExamId = this.selectedExam.id;
            }
            if(this.allExams){
                this.examsBySection = this.allExams.filter((exam) => {
                    return (exam.section.id == sectionId) && (exam.associations.length === 0) && (exam.id != selectedExamId);
                });
                this.isLoadingExams = false;
            } else {
                this.$firebase.auth().currentUser.getIdToken().then(idToken => {
                    return this.getAPIInfo({url: `exams/section/${sectionId}/list`, userToken: idToken})
                }).then((resp) => {
                    const exams = resp.data.data;
                    this.examsBySection = exams.filter((exam) => {
                        return (exam.associations.length === 0) && (exam.enabled === 1) && (exam.id != selectedExamId);
                    })
                    this.isLoadingExams = false;
                }).catch((err) => {
                    this.isLoadingExams = false;
                    this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error')
                });
            }
        },

        removeExam(code){
            const examIndex = this.associatedExams.findIndex((exam) => exam.code === code)
            this.associatedExams.splice(examIndex, 1);
            this.renderizedExams = this.associatedExams.slice();
            this.emitAssociatedExamInfo();
        },

        checkSelectedExams(examCode){
            return this.associatedExams.find((exam) => exam.code === examCode);
        },

        onSort(sortedExamData){
            const sortedExam = this.associatedExams.splice(sortedExamData.from, 1);
            this.associatedExams.splice(sortedExamData.to, 0, sortedExam[0]);
            this.emitAssociatedExamInfo();
        }
    },

    mounted(){
        this.getExamsBySection(this.sectionId);
        if(this.selectedExam && this.selectedExam.name){
            this.associatedExams = this.selectedExam.associations.map((association) => {
                return association.associated_exam
            });
            this.renderizedExams = this.associatedExams.slice();
        }
    }
}
</script>

<style scoped>

.collection-container{
    border: solid 1px #c7c6c6;
    border-radius: 10px;
}

.gray-color{
    border-top: 1px solid #c7c6c6;
}

.hidden{
    display: none;
}

</style>