<template>
    <f7-page name="method-details">
        <f7-navbar 
            title="Detalles método de procesamiento" 
            back-link
            back-link-url="/methods/" 
            back-link-force
        >
        </f7-navbar>
        <f7-block-title v-if="!selectedMethod.name">
            Seleccione algún método de procesamiento
        </f7-block-title>
      
        <f7-block v-else>
        <f7-card class="demo-card-header-pic">
            <f7-card-header>
                <f7-block-title medium> {{selectedMethod.name}} </f7-block-title>
            </f7-card-header>
            <f7-card-footer>
                <f7-button 
                    fill 
                    small 
                    round 
                    icon-material="edit" 
                    @click="goToEdit"
                    >Editar
                </f7-button>
                <f7-button 
                    fill 
                    small 
                    round 
                    icon-material="delete" 
                    color="red"
                    @click="deleteMethod()"
                    >Eliminar
                </f7-button>
            </f7-card-footer>
        </f7-card>
        </f7-block>

    </f7-page>    
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
    data(){
        return {
        }
    },

    computed: {
        ...mapState(['selectedMethod', 'profile_userInfo', 'baseUrl'])
    },

    methods: {
        ...mapActions(['getAPIInfo', 'updateInfo']),

        goToEdit(){
            this.$f7.views.main.router.navigate('/new-method/');
        },

        deleteMethod(){
            this.$f7.dialog.confirm('¿Está seguro que desea eliminar el método de procesamiento?', 'Eliminar', () => {
                let {id, createdAt, updatedAt, ...editedMethod} = this.selectedMethod;
                editedMethod.enabled = 0;
                this.$firebase.auth().currentUser.getIdToken()
                .then((idToken) => {
                    return this.updateInfo({url: 'exam_processing_methods/' + id, data: editedMethod, userToken: idToken})
                })
                .then(() => {
                    this.$store.commit('removeFromArray', {array: 'processingMethods', attr: 'id', value: id});

                    //Se agrega información al nodo log
                    let logInfo = {
                        who: this.profile_userInfo.uid,
                        wht: 'delete-method',
                        whn: this.$moment().unix(),    
                        dls: 'Elimina método de procesamiento',
                        pth: this.baseUrl + 'exam_processing_methods/' + id
                    }
                    let logId = this.$firebase.database().ref('/log').push().key;
                    this.$firebase.database().ref('log/' + logId).set(logInfo)
                    .catch((err)=>{console.log(err.message)})

                    this.$f7.toast.create({text: 'El examen se ha eliminado', closeTimeout: 2000}).open();
                    this.$store.commit('setWhatTo', {what: 'selectedMethod', to: {}});
                })
                .catch((err) => {
                    this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error')
                })
            })
        }
    },
}
</script>

<style scoped>

.label{
    color: #8e8e93;
}

</style>