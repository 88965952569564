<template>
    <f7-page class="backgris" name="onepax">
        <f7-navbar :title="focusedPax.email" :subtitle="focusedPax.displayName" back-link></f7-navbar>

        <f7-list class="unpaxholder" media-list inset>
            <f7-list-item
            :title="focusedPax.email"
            :subtitle="focusedPax.displayName"
            :text="focusedPax.uid">
            <img :src="focusedPax.photoURL || '../../static/img/default_original.png'" slot="media" />
            </f7-list-item>
        </f7-list>

        <f7-block-title>Paciente de Inmunocel</f7-block-title>

        <f7-block-title>Delegado de terceros</f7-block-title>

        <f7-block-title>Permisos Institucionales</f7-block-title>
    </f7-page>
</template>
<script>
import { mapState } from 'vuex';

export default {
    data(){
        return {

        }
    },

    computed: {
        ...mapState(['focusedPax'])
    },

    methods: {

    }
}
</script>
<style>
.unpaxholder img{
    width: 60px;
}</style>