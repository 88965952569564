<template>
    <f7-page name="examDetails" @page:beforein="updateDetails">
        <f7-navbar title="Detalles de examen" back-link back-link-url="/exams/" ></f7-navbar>
        
        <f7-block>
            <f7-card class="demo-card-header-pic">
                <f7-card-header>
                    <f7-block-title medium> 
                        {{selectedExam.name}}
                        <span class="label collection" v-if="associatedExamsCount > 0">
                            (Colección de exámenes)
                        </span>
                    </f7-block-title>
                </f7-card-header>
                <f7-card-content>
                    <f7-block-header v-if="selectedExam.only_group === 1">
                        <f7-icon material="style" />
                        Este examen sólo puede ser solicitado como parte de una colección de exámenes
                    </f7-block-header>
                    <f7-row>
                        <f7-col width="35" medium="30">
                            <p class="label">Código:</p>
                        </f7-col>
                        <f7-col width="65" medium="70">
                            <p>{{selectedExam.code}}</p>
                        </f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col width="35" medium="30">
                            <p class="label">Sección:</p>
                        </f7-col>
                        <f7-col width="65" medium="70">
                            <p>{{selectedExam.section ? selectedExam.section.name: ''}}</p>
                        </f7-col>
                    </f7-row>
                    <f7-row v-if="associatedExamsCount > 0">
                        <f7-col width="35" medium="30">
                            <p><span class="label">Examenes asociados:</span></p>
                        </f7-col>
                        <f7-col width="65" medium="70" class="padding-top">
                            <p 
                                v-for="association in selectedExam.associations" 
                                :key="association.associated_exam.id" 
                                class="no-margin-vertical"
                            >
                                {{association.associated_exam.name}}
                            </p>
                        </f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col width="35" medium="30">
                            <p class="label">Métodos de procesamiento:</p>
                        </f7-col>
                        <f7-col width="65" medium="70" class="padding-top">
                            <p 
                                v-for="(method, index) in selectedExam.exam_processing_methods" 
                                :key="index" 
                                class="no-margin-vertical"
                            >
                                {{method.name}}
                            </p>
                        </f7-col>
                    </f7-row>
                    <f7-row v-if="associatedExamsCount === 0">
                        <f7-col width="35" medium="30">
                            <p class="label">Tipos de resultados:</p>
                        </f7-col>
                        <f7-col width="65" medium="70" class="padding-top">
                            <p 
                                v-for="(result, index) in resultsTypes" 
                                :key="index" 
                                class="no-margin-vertical"
                            >
                                {{result.name}} 
                                    <span class="label" v-for="(value, key, index) in result.values" :key="index">
                                        <span v-if="index > 0">-</span>
                                        ({{key}})
                                    </span>
                            </p>
                        </f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col width="35" medium="30">
                            <p class="label">Tiempo entrega resultado:</p>
                        </f7-col>
                        <f7-col width="65" medium="70">
                            <p>{{selectedExam.normal_processing_time}} hrs</p>
                        </f7-col>
                    </f7-row>
                    <f7-row class="margin-top">
                        <f7-col width="35" medium="30">
                            <p class="label">Días de procesamiento:</p>
                        </f7-col>
                        <f7-col width="65" medium="70">
                            <f7-row v-for="(schedule, index) in days" :key="index">
                                <f7-col width="20">{{schedule.day}}</f7-col>
                                <f7-col width="80">{{showProcessingSchedule(schedule.start_hour, schedule.end_hour)}}</f7-col>
                            </f7-row>
                        </f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col width="35" medium="30">
                            <p><span class="label">Descripción:</span></p>
                        </f7-col>
                        <f7-col width="65" medium="70">
                            <p>{{selectedExam.description}}</p>
                        </f7-col>
                    </f7-row>

                    <f7-list media-list class="margin-top">
                        <f7-list-item divider title="Tipos de muestras"></f7-list-item>
                    </f7-list>

                    <f7-block v-if="isLoading" class="text-align-center margin-top">
                        <f7-preloader color="teal"></f7-preloader>
                    </f7-block>

                    <f7-list v-else-if="noSamples" media-list class="margin-top">
                        <f7-list-item title="No hay muestras asociadas al examen"></f7-list-item>
                    </f7-list>

                    <f7-list v-else media-list class="margin-top">
                        <f7-list-item v-for="(sample, index) in samples"
                            :key="index"
                            :header="(sample.is_required === 1) ? 'Muestra obligatoria' : ''"
                            :title="sample.sample.name"
                            :subtitle="sample.min_volume ? ('Vól. mínimo: ' + sample.min_volume + ' μL') : ''"
                            :text="sample.observations" 
                            :after="sample.n_required_samples ? ('N° min. muestras: ' + sample.n_required_samples) : ''"
                            :footer="sample.loinc_code ? ('Código LOINC: ' + sample.loinc_code) : ''"
                        >
                            <f7-link
                                @click="showSampleInfo(sample)"
                                class="margin-left info-link" 
                                slot="after"
                                icon-material="info">
                            </f7-link>
                        </f7-list-item>
                    </f7-list> 
                          
                </f7-card-content>
                <f7-card-footer v-if="!examId">
                    <f7-button fill small round icon-material="edit" @click="redirectEditExam">
                        Editar
                    </f7-button>
                    <f7-button fill small round icon-material="delete" color="red" @click="deleteExam">
                        Eliminar
                    </f7-button>
                </f7-card-footer>
            </f7-card>
        </f7-block>
        
        <f7-popover class="sample-info">
            <f7-block-title class="margin-top">
                <f7-icon f7="thermometer"></f7-icon>
                Estabilidad de la muestra
            </f7-block-title>
            <f7-block>    
                <p>T° ambiental: {{selectedSample.ambient_storage_time}}</p>
                <p>Refrigerada: {{selectedSample.cooled_storage_time}}</p>
                <p>Congelada: {{selectedSample.frozen_storage_time}}</p>
            </f7-block>
            <f7-block-title class="margin-top">
                <f7-icon material="format_color_reset"></f7-icon>
                Motivos de rechazo
            </f7-block-title>
            <f7-block>    
                <p v-for="(rejection, index) in selectedSample.rejections" :key="index">
                    {{rejection.reason}}
                </p>
            </f7-block>
        </f7-popover>
              
    </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    data(){
        return {
            samples: [],
            resultsTypes: [],
            isLoading: true,
            selectedSample: {},
            noSamples: false,
            days: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
        }
    },

    computed:{
        ...mapState(['selectedExam', 'allExams', 'profile_userInfo', 'baseUrl']),

        associatedExamsCount(){
            return this.selectedExam.associations.length
        }
    },

    props: ['examId'],

    methods: {
        ...mapActions(['getAPIInfo', 'updateInfo']),

        showProcessingSchedule(start_hour, end_hour){
            if(start_hour && end_hour){
                return start_hour.slice(0,5) + ' - ' + end_hour.slice(0,5)
            }
            else if(start_hour || end_hour){
                return start_hour.slice(0,5) || end_hour.slice(0,5)
            }
            else{
                return ''
            }
        },

        getExamSamples(){
            this.$firebase.auth().currentUser.getIdToken()
            .then(idToken => this.getAPIInfo({url: `exams/${this.selectedExam.code}/samples`, userToken: idToken}))
            .then(resp => {
                this.samples = resp.data.data;
                this.isLoading = false;
                this.noSamples = false;
            })
            .catch((err) => {
                this.isLoading = false;
                if(err.response.status !== 404){
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error');
                }
                else{
                    this.noSamples = true;
                }
            })
        },

        updateDetails(){
            console.log(this.selectedExam)
            this.$f7.dialog.preloader('Cargando datos');
            this.$firebase.database().ref('results_types/' + this.selectedExam.code).once('value')
                .then((snapshot) => {
                    if(snapshot.val()){
                        this.resultsTypes = Object.values(snapshot.val())
                    }
                    this.getExamSamples();
                    //Se ordenan días de procesamiento
                    this.days.forEach((day, index) => {
                        const schedule = this.selectedExam.exam_schedules.find((schedule) => schedule.day === day);
                        schedule && this.days.splice(index, 1, schedule);
                    })
                    this.$f7.dialog.close();
                }).catch((error) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error');
                })
        },

        redirectEditExam(){
            const samplesExam = this.samples.map((item) => {
                let {sample, unit, ...auxSample} = item;
                auxSample.unit_id = unit ? unit.id : 0;
                auxSample.name = sample.name;
                return auxSample
            })
            this.$store.commit('setWhatTo', {what: 'examSamplesInfo', to: samplesExam});
            this.$store.commit('setWhatTo', {what: 'examResults', to: this.resultsTypes});
            this.$f7.views.main.router.navigate('/new-exam/');
        },

        showSampleInfo(sample){
            this.selectedSample = sample;
            this.$f7.popover.open('.sample-info', '.info-link');
        },

        deleteExam(){
            this.$f7.dialog.confirm('¿Está seguro que desea eliminar el examen?', 'Eliminar', () => {
                this.$f7.dialog.preloader('Guardando');
                let {
                    id, 
                    createdAt, 
                    updatedAt, 
                    samples, 
                    exam_schedules, 
                    section, 
                    exam_processing_methods,
                    associations,  
                    ...deletedExam
                } = this.selectedExam;
                this.exam = Object.assign(deletedExam, {
                    section_id: section.id, 
                    exams_processing_methods: exam_processing_methods.map((method) => method.id),
                    associated_exams: associations.map((association) => {
                        return {
                            exam: association.associated_exam.code, 
                            order: association.order,
                        }
                    }), 
                    enabled: 0
                });
                !deletedExam.normal_processing_time && (deletedExam.normal_processing_time = 0);
                !deletedExam.description && delete deletedExam.description;
                this.$firebase.auth().currentUser.getIdToken()
                .then((idToken) => {
                    return this.updateInfo({url: 'exams/' + deletedExam.code, data: deletedExam, userToken: idToken})
                })
                .then(() => {
                    this.$f7.dialog.close();

                    //Se agrega información al nodo log
                    let logInfo = {
                        who: this.profile_userInfo.uid,
                        wht: 'delete-exam',
                        whn: this.$moment().unix(),    
                        dls: 'Elimina examen',
                        pth: this.baseUrl + 'exams/' + deletedExam.code
                    }
                    let logId = this.$firebase.database().ref('/log').push().key;
                    this.$firebase.database().ref('log/' + logId).set(logInfo)
                        .catch((err)=>{console.log(err.message)})

                    this.$store.commit('removeFromArray', {array: 'allExams', attr: 'id', value: this.selectedExam.id});
                    this.$f7.toast.create({text: 'El examen se ha eliminado con éxito', closeTimeout: 2500}).open();
                    this.$f7.views.main.router.navigate('/exams/');
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error')
                })
            })
        }
    },

}
</script>

<style scoped>

.label{
    color: #8e8e93;
}

.collection{
    font-size: 18px;
}

</style>