<template>
  <f7-page name="profile">
    <!-- Top Navbar -->
    <f7-navbar  >
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title >Mi Perfil</f7-nav-title>
    </f7-navbar>
   
   

   

   <f7-block>
  <f7-row>
    <f7-col width="100" medium="50">
      <f7-block-title>Mi Avatar</f7-block-title>
      <f7-block class="text-align-center" >
      <img :src="myAvatar" class="miavatar" />
            <input type="file" id="myavtr-input" @change="filesChange($event)" accept="image/*" />

      <f7-button @click="tomarFoto()" large outline class="margin-top">Cambiar mi Avatar</f7-button>
      </f7-block>
    </f7-col>
    <f7-col width="100" medium="50">
      <f7-block-title>Información</f7-block-title>
      <f7-block inset strong><b>Nombre:</b> {{profile_userInfo.displayName}} <br/> <b>Email:</b> {{profile_userInfo.email}}</f7-block>
    </f7-col>
  </f7-row>
   </f7-block>


  </f7-page>
</template>

<script>
import { mapState,mapGetters } from 'vuex';
import {photoMixin} from '../mixins/photomixin';

export default {
     mixins: [photoMixin],
computed:{
  ...mapState(['profile_userInfo']),
  ...mapGetters(['myAvatar'])
}  ,
methods:{
  
}
}
</script>
<style>
.miavatar{
  width: 50%;
  border-radius: 50%;
  border: 5px solid white;
}
#myavtr-input{
  display: none;
}
</style>