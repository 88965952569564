<template>
    <f7-page name="patientDetails" @page:beforein="formatRut">
        <f7-navbar title="Detalles del paciente" back-link></f7-navbar>

        <f7-block-title v-if="!pacienteSeleccionado">
            Seleccione algun paciente
        </f7-block-title>

        <f7-block v-else>
            <f7-card class="demo-card-header-pic">
                <f7-card-header>
                    <f7-block-title medium>
                        {{pacienteSeleccionado.nombres}} {{pacienteSeleccionado.primerApellido}} {{pacienteSeleccionado.segundoApellido}}
                    </f7-block-title>
                </f7-card-header>
                <f7-card-content>
                    <p><span class="label">Nacionalidad: </span>{{pacienteSeleccionado.nacionalidad}}</p>
                    <p><span class="label">{{pacienteSeleccionado.tipoIdentificador}}: </span>{{pacienteSeleccionado.identificador}}</p>
                    <p><span class="label">Fecha de nacimiento: </span>{{pacienteSeleccionado.fechaNacimiento}}</p>
                    <p><span class="label">Edad: </span>{{age}}</p>
                    <p><span class="label">Sexo: </span>{{pacienteSeleccionado.sexo}}</p>
                    <p><span class="label">Teléfono: </span>{{pacienteSeleccionado.telefono}}</p>
                    <p><span class="label">Email: </span>{{pacienteSeleccionado.email}}</p>
                    <p><span class="label">Dirección: </span>{{pacienteSeleccionado.direccion}}</p>
                    <p><span class="label">Comuna: </span>{{pacienteSeleccionado.comuna}}</p>
                    <p><span class="label">Prevision: </span>{{pacienteSeleccionado.prevision}}</p>
                </f7-card-content>
                <f7-card-footer>
                    <f7-button fill small round icon-material="edit" @click="goToEdit">
                        Editar
                    </f7-button>
                </f7-card-footer>
            </f7-card>
        </f7-block>

    </f7-page>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { format } from 'rut.js';

export default {
    data(){
        return {

        }
    },

    computed: {
        ...mapState(['pacienteSeleccionado', 'profile_userInfo']),
        ...mapGetters(['age'])
    },

    methods: {
        formatRut(){
            if(this.pacienteSeleccionado.tipoIdentificador === 'RUT'){
                this.pacienteSeleccionado.identificador = format(this.pacienteSeleccionado.identificador)
            }
        },

        goToEdit(){
            this.$f7.views.main.router.navigate('/neopaciente/');
        },

    }
}
</script>

<style scoped>

.label{
    color: #8e8e93;
}

</style>