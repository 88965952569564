<template>
    <f7-page name="newInstitution">
        <f7-navbar v-if="!isEditor" title="Agregar nueva Institución" back-link></f7-navbar>
        <f7-navbar v-else title="Editar Institución" back-link></f7-navbar>
        <f7-block-title>Complete tantos datos como pueda</f7-block-title>
    
        <f7-list no-hairlines-md class="no-margin-top no-margin-bottom">
            <f7-list-item divider title="Datos institución"></f7-list-item>
            <f7-list-input
                class="margin-top"
                label="Nombre Institución"
                type="text"
                name="institutionName"
                placeholder="Nombre"
                :value="institution.name"
                @input="institution.name = $event.target.value"
                required validate
                error-message="Debe indicar el nombre de la institución"
            ></f7-list-input>
            <li class="item-content item-input">
                <div class="item-inner">
                    <div class="item-title item-label">País</div>
                    <div class="item-input-wrap">
                        <input 
                            id="country-autocomplete" 
                            :value="institution.country" 
                            @change="selectCountry($event)" 
                            type="text"  
                            autocomplete="no" 
                            placeholder="País">
                        <span class="input-clear-button"></span>
                    </div>
                </div>
            </li>
            <f7-list-input
                :label="institution.identifierType"
                name="identifier"
                type="text"
                :placeholder="institution.identifierType"
                :value="institution.identifier"
                @input="validateIdentifier($event)"
                error-message='RUT inválido'
                :error-message-force="invalidRut"
            ></f7-list-input>
            <f7-list-input
                label="Dirección"
                name="address"
                type="text"
                placeholder="Dirección"
                :value="institution.address"
                @input="institution.address = $event.target.value"
            ></f7-list-input>
        </f7-list>

        <div class="list no-hairlines no-margin-top margin-bottom-half">
            <ul>
                <li>
                    <!-- Add smart-select-init class -->
                    <a href="#" class="item-link smart-select smart-select-init select-agreement" 
                        data-searchbar="true"
                        data-searchbar-placeholder="Busque el convenio"
                        data-open-in="popup"
                        data-popup-close-link-text="Cerrar"
                        @smartselect:close="selectAgreements"
                        >
                        <!-- select -->
                        <select name="select-agreement" multiple>
                            <option 
                                v-for="(agreement, index) in $store.getters.objToArray('allAgreements')"
                                :key="index" 
                                :value="agreement.name"
                                :selected="agreementsName.includes(agreement.name)"
                                >{{agreement.name}}
                            </option>
                        </select>
                        <div class="item-content">
                            <div class="item-inner">
                                <div v-if="institution.agreements" class="item-title">Convenios</div>
                                <div v-else class="item-title">Seleccione uno o más convenios</div>
                            </div>
                        </div>      
                    </a>
                </li>
            </ul>
        </div>

        <f7-list no-hairlines-md class="margin-top-half">
        <f7-list-item divider title="Datos de contacto"></f7-list-item>
            <f7-list-input
                class="margin-top"
                label="Nombre"
                name="contactName"
                type="text"
                placeholder="Nombre contacto"
                :value="institution.contactName"
                @input="institution.contactName = $event.target.value"
            ></f7-list-input>
            <f7-list-input
                label="Teléfono"
                name="phone"
                type="text"
                placeholder="Teléfono"
                :value="institution.phone"
                @input="institution.phone = $event.target.value"
            ></f7-list-input>
            <f7-list-input
                label="Correo electrónico"
                name="email"
                type="email"
                placeholder="Correo electrónico"
                :value="institution.email"
                @input="institution.email = $event.target.value"
            ></f7-list-input>
        </f7-list>

        <f7-block>
            <f7-row>
                <f7-col width="10" medium="33">
                </f7-col>
                <f7-col width="0" medium="33">
                    <f7-button
                        :icon-material="!isEditor ? 'playlist_add' : 'edit'" 
                        fill
                        raised
                        round
                        :text="!isEditor ? 'Agregar nueva institución' : 'Guardar cambios'"
                        @click="!isEditor ? saveInstitution() : editInstitution()" 
                    />
                </f7-col>
                <f7-col width="10" medium="33">
                </f7-col>
            </f7-row>
        </f7-block>

    </f7-page>
</template>

<script>
import {validate, format, clean} from 'rut.js';
import countries from 'i18n-iso-countries';
import { mapState, mapGetters } from 'vuex';

countries.registerLocale(require("i18n-iso-countries/langs/es.json"));

export default {
    data(){
        return {
            countries: [],
            invalidRut: false,
            agreementsName: [],
            institution: {
                name: '',
                country: 'Chile',
                identifierType: 'RUT',
                identifier: null,
                address: null,
                agreements: null,
                contactName: null,
                phone: null,
                email: null,
                id: '',
                enabled: true
            },
            isEditor: false,
        }
    },
    computed: {
        ...mapState(['allAgreements', 'profile_userInfo', 'selectedInstitution']),
    },

    methods: {
        setAddOrEdit(){
            //Si está activado el modo de editar institución se deben mostrar datos de institución seleccionada
            if(this.selectedInstitution.name){
                this.isEditor = true;
                this.institution = { ...this.selectedInstitution};
                this.agreementsName = this.institution.agreements ? Object.keys(this.institution.agreements) : [];
            }
        },
        selectCountry(event){
            this.institution.country = event.target.value;
            if(this.institution.country === 'Chile'){
                this.institution.identifierType = 'RUT';
            }
            else{
                this.invalidRut = false;
                this.institution.identifierType = 'ID Tributario';
            }
        },
        validateIdentifier(event){
            let identifier = event.target.value;
            if(this.institution.identifierType === 'RUT' && identifier.trim().length > 0){
                identifier = format(identifier);
                if(!validate(identifier)){
                    this.invalidRut = true;
                }
                else{
                    this.invalidRut = false;
                }
            }
            this.institution.identifier = identifier; 
        },
        selectAgreements(){
            const value = this.$f7.smartSelect.get('.select-agreement').getValue();
            if(value.length > 0){
                this.institution.agreements = {};
                value.forEach((item)=>{this.institution.agreements[item] = true});
            }
        },
        redirectInstitutionDetails(){
            this.$store.commit('setWhatTo',{what: 'selectedInstitution', to: this.institution});
            this.$f7.views.main.router.navigate('/institution/');
        },
        saveInstitution(){
            this.$f7.dialog.preloader('Guardando');
            const id = this.$firebase.database().ref('/institutions').push().key;
            this.institution.id = id;
            this.$firebase.database().ref('institutions/' + id).set(this.institution)
                .then(()=>{
                    this.$store.commit('updateObjectAttribute', {
                        obj: 'allInstitutions',
                        attr: id,
                        value: this.institution
                    })
                    this.redirectInstitutionDetails();
                    this.$f7.dialog.close();
                    this.$f7.toast.create({text: 'Los datos se han guardado con éxito', closeTimeout: 2500}).open();
                    
                    //Se agrega información al nodo log
                    let info = {
                        who: this.profile_userInfo.uid,
                        wht: 'add-institution',
                        whn: this.$moment().unix(),    
                        dls: 'Crea nueva institucion',
                        pth: 'institutions/' + this.institution.id,
                    }
                    let id = this.$firebase.database().ref('/log').push().key;
                    this.$firebase.database().ref('log/' + id).set(info)
                        .catch((err)=>{console.log(err.message)})
                })
                .catch((err)=>{
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                })
        },
        editInstitution(){
            this.$f7.dialog.preloader('Editando');
            this.$firebase.database().ref('institutions/' + this.institution.id).update(this.institution)
            .then(()=>{
                this.$store.commit('updateObjectAttribute', {
                    obj: 'allInstitutions',
                    attr: this.institution.id,
                    value: this.institution
                })
                this.redirectInstitutionDetails();
                this.$f7.dialog.close();
                this.$f7.toast.create({text: 'Los cambios se han guardado con éxito', closeTimeout: 2500}).open();
                
                //Se agrega información al nodo log
                    let info = {
                        who: this.profile_userInfo.uid,
                        wht: 'edit-institution',
                        whn: this.$moment().unix(),    
                        dls: 'Edita institucion',
                        pth: 'institutions/' + this.institution.id,
                    }
                    let id = this.$firebase.database().ref('/log').push().key;
                    this.$firebase.database().ref('log/' + id).set(info)
                        .catch((err)=>{console.log(err.message)})
                })

            .catch((err)=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.message, err.code);
            })
        },
        getAgreements(){
            if(!this.allAgreements){
                this.$firebase.database().ref('agreements/').orderByChild('enabled').equalTo(true).once('value')
                    .then((snapshot) => {
                        this.$store.commit('setObjTo', {what: 'allAgreements', to: snapshot.val()});
                    })
                    .catch((err)=>{
                        this.$f7.dialog.alert(err.message, err.code);
                });
            }
        }
    },
    mounted(){
        this.getAgreements();
        this.setAddOrEdit();
        //Renderizado de dropdown de países
        let countryNames = countries.getNames("es");
        this.countries = Object.values(countryNames);

        let autocompleteDropdownTypeahead = this.$f7.autocomplete.create({
            inputEl: '#country-autocomplete',
            openIn: 'dropdown',
            dropdownPlaceholderText: 'Comienza a escribir el nombre del pais',
            typeahead: true,
            source:  (query, render)=> {
                let results = [];
                if (query.length === 0) {
                    render(results);
                    return;
                }
                // Find matched items
                for (let i = 0; i < this.countries.length; i++) {
                    if (this.countries[i].toLowerCase().indexOf(query.toLowerCase()) === 0) 
                        results.push(this.countries[i]);
                }
                // Render items by passing array with result items
                render(results);
            }
        });
    }
}
</script>