<template>
    <f7-page
        class="backgris"
        name="historicofirmados"
        infinite
        :infinite-preloader="showPreloader"
        @infinite="loadMore"
    >
        <f7-navbar
            title="Examenes firmados en"
            :subtitle="focused_section.name"
            back-link
        >
        </f7-navbar>
        <f7-list class="no-margin unexm" media-list>
            <ul>
                <pend-ingreso
                    v-for="noingressd in allMyFirmados"
                    :key="noingressd.id"
                    :soyhistorico="true"
                    :condetail="false"
                    :ordentrabajo="noingressd"
                ></pend-ingreso>
            </ul>
        </f7-list>
    </f7-page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import pendIngreso from "../components/pendIngreso.vue";

export default {
    components: {
        pendIngreso,
    },
    data() {
        return {
            referenceToOldestKey: null,
            showPreloader: true,
            allowInfinite: true,
        };
    },

    watch: {
        focused_section(newval) {
            this.$store.commit("setWhatTo", { what: "allMyFirmados", to: [] });
            this.loadMore();
        },
    },
    computed: {
        ...mapState(["focused_section", "allMyFirmados"]),
        // ...mapGetters(['arrExsAunNoIngresados'])
    },

    methods: {
        loadMore() {
            if (!this.allowInfinite) return;
            this.getFirstData();
        },
        getFirstData(done = null) {
            this.allowInfinite = false;
            this.showPreloader = true;
            let puntero;
            if (!this.referenceToOldestKey) {
                puntero = this.$firebase
                    .database()
                    .ref("admittedExams/" + this.focused_section.id)
                    .orderByKey()
                    .limitToLast(20);
            } else {
                puntero = this.$firebase
                    .database()
                    .ref("admittedExams/" + this.focused_section.id)
                    .orderByKey()
                    .endAt(this.referenceToOldestKey)
                    .limitToLast(20);
            }
            puntero
                .once("value")
                .then((snah) => {
                    let vals = snah.val();
                    console.log("vals", vals);
                    let arrayOfKeys = Object.keys(vals)
                        .reverse();

                    console.log("arrayOfKeys", arrayOfKeys);
                    if (arrayOfKeys.length <= 1) {
                        this.allowInfinite = false;
                        this.showPreloader = false;
                        if (done) {
                            done();
                        }
                        return false;
                    }
                    let results = arrayOfKeys.map((key) => {
                        let lclsnao = vals[key];
                        return lclsnao;
                    });
                    let neoresults = results.filter((unexam) => {
                        return (
                            unexam.firmado &&
                            unexam.id != this.referenceToOldestKey
                        );
                    });
                    console.log(neoresults);
                    this.referenceToOldestKey =
                        arrayOfKeys[arrayOfKeys.length - 1];
                    this.$store.commit("conactToArray", {
                        array: "allMyFirmados",
                        value: neoresults,
                    });
                    this.allowInfinite = true;
                    this.showPreloader = false;
                    if (done) {
                        done();
                    }
                    if (this.allMyFirmados.length < 20) {
                        this.getFirstData();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.allowInfinite = true;
                    this.showPreloader = false;
                    if (done) {
                        done();
                    }
                });
        },
    },
    mounted() {
        this.getFirstData();
    },
    beforeDestroy() {
        this.$store.commit("setWhatTo", { what: "allMyFirmados", to: [] });
    },
};
</script>
<style>
.unexm,
.unexm ul {
    padding: 0px !important;
}
.unexm .item-media img {
    width: 50px;
}
.unexm .item-media {
    text-align: center;
    font-size: 14px;
}
</style>
