<template>
    <f7-page @page:beforein="reloadPage()">
        <f7-navbar 
            :title="!isEditor ? 'Agregar examen a admisión' : 'Editar examen'" 
            back-link
        >
        </f7-navbar>
        <f7-block class="margin-top-half">

            <admission-summary />

            <f7-list media-list class="no-margin-bottom">
                <f7-list-item divider title="Datos de examen"></f7-list-item>
                <f7-list-item
                    v-if="isEditor"
                    header="Sección"
                    :title="section.name"
                >
                </f7-list-item>
                <f7-list-input
                    v-else
                    label="Seleccione una sección"
                    type="select"
                    :value="newExam.section_id"
                    @change="selectSection($event)"
                    error-message='Debe seleccionar una sección'
                    :error-message-force="invalidSection"
                >
                    <!--Temporalmente desactivadas la sección de anatomía patológica-->
                    <option></option>
                    <option 
                        v-for="section in $store.getters.sortArray('sections')" 
                        :key="section.id"
                        :value="section.id"
                        :selected="section.id == newExam.section_id"
                    >
                        {{section.name}}
                    </option>
                </f7-list-input>
                <div 
                    v-if="newExam.section_id && isLoadingExams" 
                    class="text-align-center margin-vertical-half"
                >
                    <p class="preloaderText">Cargando lista de exámenes...</p>
                    <f7-preloader color="teal"></f7-preloader>
                </div>
                <f7-list-item
                    v-else-if="newExam.section_id"
                    :class="{ borderRed: invalidExam }"
                    header="Examen"
                    :title="newExam.name || 'Seleccione un examen'"
                    :link="!isEditor ? '#' : false"
                    @click="!isEditor && openExamSelector()"
                >
                </f7-list-item>
            </f7-list>

            <div class="exam-info">
                <f7-link v-if="selectedExam" class="margin-right" @click="redirectExamDetails()">
                    Ver detalles de examen
                </f7-link>
            </div>

            <span v-if="invalidExam" class="error red margin-left">
                Debe seleccionar algún examen
            </span>

            <f7-list 
                media-list 
                :class="{ borderRed: noSamples, 'no-margin-bottom': true, 'margin-top-half': true }" 
                :key="reloadingSampleList"
            >
                <f7-list-item divider title="Muestras a vincular"></f7-list-item>
                <f7-list-item 
                    v-if="!newExam.name" 
                    title="Debe seleccionar una sección y un examen"
                >
                </f7-list-item>
                <f7-list-item 
                    v-else-if="isLoadingSamples"
                >
                    <div class="text-align-center margin-top">
                        <f7-preloader color="teal"></f7-preloader>
                    </div>
                </f7-list-item>
                <f7-list-item 
                    v-else-if="linkableSamples.length === 0" 
                    title="Ingrese alguna muestra para vincular">
                </f7-list-item>

                <f7-list-item
                    checkbox
                    name="sample-checkbox"
                    v-for="sample in linkableSamples" 
                    :key="sample.id"
                    :title="sample.name" 
                    :subtitle="sample.id"
                    :text="newExam.samples[sample.id] && (newExam.samples[sample.id].isRejected && 'Muestra Rechazada')"
                    :after="calculateTime(sample.dateTime)"
                    :value="sample.id"
                    @change="selectSample($event)"
                    :checked="Object.keys(newExam.samples).includes(sample.id)">
                    <f7-link
                        v-if="Object.keys(newExam.samples).includes(sample.id)"
                        @click="getRejections(sample)"
                        class="margin-left" 
                        slot="after"
                        tooltip="Rechazar muestra"
                        popover-open=".reject-sample" 
                        icon-material="format_color_reset">
                    </f7-link>        
                </f7-list-item>
                <f7-list-item 
                    checkbox
                    class="borderRed"
                    name="exceptional-sample"
                    v-for="sample in exceptionalSamples" 
                    :key="sample.id"
                    :title="sample.name" 
                    :subtitle="sample.id"
                    :after="calculateTime(sample.dateTime)"
                    :value="sample.id"
                    @change="selectSample($event)"
                    :checked="Object.keys(newExam.samples).includes(sample.id)">
                    <f7-icon
                        slot="after" 
                        material="warning" 
                        class="red margin-left"
                        tooltip="Muestra vinculada excepcionalmente a este examen. Requiere autorización">
                    </f7-icon>          
                </f7-list-item>
                
            </f7-list>

            <span v-if="noSamples" class="error red margin-left">
                Debe vincular al menos una muestra
            </span>

            <f7-row class="margin-top" v-if="newExam.name">
                <f7-col width="10" medium="25">
                </f7-col>
                <f7-col width="80" medium="50">
                    <f7-button
                        v-if="!isEditor" 
                        outline 
                        small 
                        class="margin-bottom-half" 
                        @click="addNewSample()"
                    >
                        Ingresar nueva muestra
                    </f7-button>
                </f7-col>
                <f7-col width="100" medium="25" class="text-align-right">
                    <f7-link 
                        class="margin-right" 
                        tooltip="Permite ingresar muestra admitida NO compatible con examen" 
                        popover-open=".other-samples">
                        Agregar otra muestra
                    </f7-link>
                </f7-col>
            </f7-row>

            <f7-list no-hairlines media-list class="no-margin-bottom">
                <f7-list-item divider title="Medico solicitante"></f7-list-item>
                <f7-list-item>
                    <span>Agregar médico solicitante </span>
                    <f7-toggle 
                        class="margin-left doctor-toggle" 
                        :checked="newExam.doctor !== null && newExam.doctor !== undefined"
                        @change="checkDoctor()"
                    >
                    </f7-toggle>
                </f7-list-item>
            </f7-list>

            <doctor-search 
                v-if="doctorInfo"
                v-on:saveDoctorData="saveDoctor($event)" 
                :doctorData="newExam.doctor"
                :key="doctorReloader"
            />

            <f7-row class="margin-top">
                <f7-col width="10" medium="30">
                </f7-col>
                <f7-col width="80" medium="40">
                    <f7-button
                        :icon-material="!isEditor ? 'playlist_add' : 'edit'" 
                        raised 
                        fill
                        round
                        :text="!isEditor ? 'Agregar examen' : 'Guardar cambios'" 
                        @click="addExam()"
                    />
                </f7-col>
                <f7-col width="10" medium="30">
                </f7-col>
            </f7-row>

            <exam-selector v-on:selectExam="selectExam" :externalId="newExam.code"></exam-selector>

            <f7-popover class="other-samples">
                <f7-block v-if="otherSamples.length === 0" class="margin-vertical-half">
                    <f7-row>
                        <f7-col width="25" class="padding-top text-align-center">
                            <f7-icon material="warning" class="icon-warning"></f7-icon> 
                        </f7-col>
                        <f7-col width="75">
                            <p>Debe admitir primero la muestra en la sección correspondiente</p>
                        </f7-col>
                    </f7-row>
                </f7-block>
                <f7-list media-list v-else>
                    <f7-list-item
                        v-for="sample in otherSamples" 
                        popover-close 
                        checkbox
                        name="exceptional-sample"  
                        :key="sample.id" 
                        :value="sample.id" 
                        :title="sample.name"
                        :subtitle="sample.id"
                        :checked="Object.keys(newExam.samples).includes(sample.id)"
                        @change="selectSample">
                    </f7-list-item>
                </f7-list>
            </f7-popover>

            <f7-popover class="reject-sample">
                <f7-block-title class="margin-top">
                    Seleccione el motivo de rechazo
                </f7-block-title>
                <f7-block class="margin-vertical-half">
                    <div v-if="isLoadingRejections" class="text-align-center margin-top">
                        <f7-preloader color="teal"></f7-preloader>
                    </div>
                    <f7-list v-else no-hairlines class="margin-top-half no-margin-bottom">
                        <f7-list-input
                            type="select"
                            @change="selectRejection"
                        >
                            <option></option>
                            <option 
                                v-for="(rejection, index) in rejections" 
                                :key="index"
                                :value="rejection.reason"
                                :selected="newExam.samples[selectedSample.id] && newExam.samples[selectedSample.id].reason === rejection.reason">
                                {{rejection.reason}}
                            </option>
                            <option
                                v-if="selectedSample"
                                :selected="newExam.samples[selectedSample.id] && newExam.samples[selectedSample.id].reason === 'Otro motivo'"
                            >
                                Otro motivo
                            </option>
                        </f7-list-input>
                    </f7-list>
                </f7-block>
            </f7-popover>
        
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { clean, validate } from 'rut.js';
import { saveInfoMixin } from '../../mixins/save-info-mixin';
import AdmissionSummary from '../../components/admission-components/admission-summary';
import ExamSelector from '../../components/admission-components/exam-selector';
import DoctorSearch from '../../components/admission-components/doctor-search';

export default {
    data() {
        return {
            newExam: {
                id: '',
                externalId:'',
                name: '',
                section_id: '',
                doctor: null, 
                samples: {},
            },
            newDoctor: {},
            selectedExam: null,
            linkableSamples: [],
            otherSamples: [],
            exceptionalSamples: [],
            rejections: [],
            selectedSample: null,
            isLoadingExams: false,
            isLoadingSamples: true,
            isLoadingRejections: false,
            reloadingSampleList: 'sampleListReloaderOff',
            doctorReloader: 'doctorReloaderOff',
            invalidSection: false,
            invalidExam: false,
            noSamples: false,
            isEditor: false,
            section: {},
            examPromises: [],
            doctorInfo: false,
        }
    },

    props: ['examId'],

    components: {
        AdmissionSummary,
        ExamSelector,
        DoctorSearch,
    },

    computed: {
        ...mapState(['filteredExams', 'selectedAdmission', 'filteredSamples', 'admittedExam', 'sections' ])
    },

    mixins:[ 
        saveInfoMixin, 
    ],

    methods: {
        ...mapActions(['getAPIInfo', 'createNewInfo']),

        filterSamples(samples){
            if(this.selectedAdmission.samples){
                this.linkableSamples = [];
                this.exceptionalSamples = [];
                Object.values(this.selectedAdmission.samples).forEach((sampleAdmission) => {
                    samples.forEach((sample) => {
                        if(sample.id == sampleAdmission.externalId){
                            this.linkableSamples.push(sampleAdmission);
                        }
                    })
                });
                this.otherSamples = Object.values(this.selectedAdmission.samples).filter((sampleAdmission) => {
                    return !this.linkableSamples.find((sample) => sampleAdmission.id === sample.id);
                });
                let samplesId = this.linkableSamples.map((sample) => sample.id);
                Object.keys(this.newExam.samples).forEach((id) => {
                    if(!samplesId.includes(id)){
                        this.exceptionalSamples.push(this.selectedAdmission.samples[id]);
                    }
                })
            }
            this.isLoadingSamples = false;
        },

        getExams(section_id){
            this.isLoadingExams = true;
            this.$firebase.auth().currentUser.getIdToken().then(idToken => {
                return this.getAPIInfo({url: `exams/section/${section_id}/list`, userToken: idToken});
            }).then((resp) => {
                let enabledExams = resp.data.data.filter((exam) => exam.enabled === 1 && (exam.only_group !== 1));
                this.$store.commit('setWhatTo', {what: 'filteredExams', to: enabledExams});
                this.isLoadingExams = false;
                this.openExamSelector();
            }).catch((err) => {
                this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error');
            });
        },
        
        reloadPage() {
            if(this.admittedExam){
                this.newExam = { ...this.admittedExam};
                this.selectedExam = this.filteredExams.find((exam) => exam.code === this.admittedExam.externalId);
                this.noSamples = false;
                this.filterSamples(this.selectedExam.samples);
                this.$store.commit('setWhatTo', { what: 'admittedExam', to: null });
            }
            if(this.examId){
                this.isEditor = true;
                this.newExam = { ...this.selectedAdmission.exams[this.examId] };
                this.newExam.samples = { ...this.selectedAdmission.exams[this.examId].samples};
                this.doctorReloader = this.doctorReloader == 'doctorReloaderOn' ? 'doctorReloaderOff' : 'doctorReloaderOn'
                this.section = this.sections.find((section) => section.id == this.newExam.section_id);
                this.$firebase.auth().currentUser.getIdToken().then(idToken => {
                    return this.getAPIInfo({ url: 'exams/' + this.newExam.externalId, userToken: idToken })
                }).then((resp) => {
                    this.selectedExam = resp.data.data;
                    this.filterSamples(this.selectedExam.samples);
                }).catch((err) => {
                    this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error')
                });
            }
        },

        selectSection(event) {
            this.invalidSection = false;
            this.newExam.section_id = event.target.value;
            if(this.selectedExam){
                this.selectedExam = null;
                this.newExam.name = '';
                this.newExam.externalId = '';
                this.newExam.samples = {};
                this.linkableSamples = [];
                this.exceptionalSamples = [];
            }
            this.getExams(event.target.value);
        },

        openExamSelector() {
            this.$f7.popup.open('.exam-selector');
        },

        selectExam(exam) {
            this.invalidExam = false;
            this.selectedExam = { ...exam };
            const examInfo = {
                name: exam.name,
                externalId: exam.code,
                samples: {}
            };
            this.newExam = { ...this.newExam, ...examInfo };
            this.filterSamples(this.selectedExam.samples);
        },

        redirectExamDetails(){
            this.$store.commit('setObjTo', { what: 'selectedExam', to: this.selectedExam });
            this.$f7.views.main.router.navigate('/exam/' + this.newExam.externalId);
        },

        addNewSample(){
            this.$store.commit('setObjTo', { what: 'admittedExam', to: this.newExam });
            this.$f7.views.main.router.navigate('/sample-admission/')
        },

        calculateTime(dateTime){
            return 'Tomada hace ' + this.$moment(dateTime, "DD-MM-YYYY HH:mm").toNow(true);
        },

        selectSample(event){
            const sampleId = event.target.value;
            if(event.target.checked){
                this.noSamples = false;
                this.newExam.samples[sampleId] = { id: sampleId };
                (event.target.name === 'exceptional-sample') && this.exceptionalSamples.push(this.selectedAdmission.samples[sampleId]);
            }
            else{
                delete this.newExam.samples[sampleId];
                if(event.target.name === 'exceptional-sample'){
                    let index = this.exceptionalSamples.findIndex((sample) => sample.id == sampleId);
                    this.exceptionalSamples.splice(index, 1);
                }
            }
            this.reloadingSampleList = (this.reloadingSampleList === 'sampleListReloaderOff') ? 'sampleListReloaderOn' : 'sampleListReloaderOff';
            this.$f7.popover.close('.other-samples');
        },

        getRejections(sample){
            this.isLoadingRejections = true;
            this.selectedSample = sample;
            this.$firebase.auth().currentUser.getIdToken()
                .then((idToken) => this.getAPIInfo({url: `exams/${this.selectedExam.code}/samples`, userToken: idToken}))
                .then((resp) => {
                    const auxSample = resp.data.data.find((item) => item.sample_id == sample.externalId);
                    this.rejections = auxSample.rejections;
                    this.isLoadingRejections = false;
                })
                .catch((err) => {this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error')});
        },

        selectRejection(event){
            this.newExam.samples[this.selectedSample.id].isRejected = true;
            this.newExam.samples[this.selectedSample.id].reason = event.target.value;
            this.$f7.popover.close('.reject-sample');
            this.reloadingSampleList = (this.reloadingSampleList === 'sampleListReloaderOff') ? 'sampleListReloaderOn' : 'sampleListReloaderOff';
        },

        checkDoctor() {
            const toggle = this.$f7.toggle.get('.doctor-toggle');
            if(!toggle.checked){
                this.$f7.dialog.confirm(
                    '¿Está seguro de eliminar los datos agregados?', 
                    'Eliminar médico solicitante',
                    ()=>{
                        this.doctorInfo = false;
                        this.newExam.doctor = null;
                    },
                    ()=>{
                        this.doctorInfo = true;
                        toggle.checked = true;
                    }
                );
            }
            else{
                this.doctorInfo = true;
            }
        },

        saveDoctor(doctor){
            let doctorData = { ...doctor }; 
            if(doctorData.formatedName){
                !this.newExam.doctor && (this.newExam.doctor = {});
                this.newExam.doctor.name = doctorData.formatedName;
                this.newExam.doctor.rut = doctorData.rut;
                delete doctorData.formatedName;
                if(doctorData.rut){
                   this.newDoctor = doctorData;
                   this.newExam.doctor.rut = doctorData.rut;
                }
            }
            else{
                this.newExam.doctor = doctorData;
            }
        },

        createNewDoctor(){
            let doctor = { ...this.newDoctor }
            if(!doctor.lastname_m){
                delete doctor.lastname_m
            }  
            if(validate(doctor.rut)){
                doctor.rut = clean(doctor.rut);
                this.$firebase.auth().currentUser.getIdToken()
                .then((idToken) => {
                    return this.createNewInfo({url: 'clinicians/', data: doctor, userToken: idToken})
                })
                .then((resp) => {
                    let logInfo = {
                        who: this.profile_userInfo.uid,
                        wht: 'add-doctor',
                        whn: this.$moment().unix(),    
                        dls: 'Crea nuevo medico',
                        pth: 'https://api.inmunocel.com/clinicians/'
                    }
                    this.saveLogInfo('log/', logInfo).catch((error)=>{
                        this.$f7.dialog.alert(error.message, error.code)
                    })
                })
                .catch((error) => {
                    this.$f7.dialog.alert(error.response.data.message || error.message, 'Error al guardar datos de médico');
                });
            }
            
        },

        saveExamInfo() {
            this.$f7.dialog.preloader('Cargando');
            if(this.newExam.doctor && this.newExam.doctor.rut){
                this.newExam.doctor.rut = clean(this.newExam.doctor.rut);
            }
            this.$store.commit('setObjTo', { what: 'previousDoctor', to: this.newExam.doctor });
            this.examPromises.push(this.$firebase.database().ref('admissions/' + this.selectedAdmission.id + '/exams/' + this.newExam.id)
                .set(this.newExam))
            if(!this.selectedAdmission.opened){
                let action = 'admitido';
                this.isEditor && (action = 'editado');
                this.examPromises = this.examPromises.concat(this.addAdmittedExam(this.newExam, this.selectedAdmission, action));
            }
            Promise.all(this.examPromises).then(()=>{
                let exams = { ...this.selectedAdmission.exams };
                exams[this.newExam.id] = this.newExam;
                this.$store.commit('updateObjectAttribute', {
                    obj: 'selectedAdmission',
                    attr: 'exams',
                    value: exams
                });
                this.$f7.dialog.close();
                this.showToast('Datos de examen guardados con éxito');
                if(!this.selectedAdmission.opened){
                    let details = 'Agrega examen ' + this.newExam.name;
                    this.isEditor && (details = 'Edita datos de examen ' + this.newExam.name)
                    const logInfo = {
                        what: 'edit-admission',
                        details: details,
                        admissionId: this.selectedAdmission.id,
                    };
                    this.saveAdmissionLog(logInfo).catch((error) => {
                        this.$f7.dialog.alert(error.message, error.code)
                    });
                }
                this.$f7.views.main.router.back();
                if(this.newDoctor.name && this.newDoctor.lastname_f){
                    this.createNewDoctor();
                }
                this.$store.commit('setWhatTo', { what: 'admittedExam', to: null });
            }).catch((err)=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.message, err.code);
            })
        },

        saveAdmittedSampleInfo(samples){
            let samplesPromises = [];
            if(this.isEditor){
                const originalSamples = Object.keys(this.selectedAdmission.exams[this.newExam.id].samples);
                samples.forEach((sampleId) => {
                    if(!originalSamples.includes(sampleId)){
                        samplesPromises.push(this.$firebase.database().ref('admittedSamples/' + sampleId).once('value'));
                    }
                })
                originalSamples.forEach((sampleId) => {
                    if(!samples.includes(sampleId)){
                        this.examPromises.push(this.$firebase.database().ref('admittedSamples/' + sampleId + '/exams/' + this.newExam.id)
                            .set(null));
                    }
                })
            }
            else{
                samples.forEach((sampleId) => {
                    samplesPromises.push(this.$firebase.database().ref('admittedSamples/' + sampleId).once('value'));
                })
            }
            Promise.all(samplesPromises).then((snapshots) => {
                snapshots.forEach((value) => {
                    let sampleInfo = value.val();
                    if(!sampleInfo.exams){
                        sampleInfo.exams = {};
                        sampleInfo.sections = {};
                    }
                    sampleInfo.exams[this.newExam.id] = false;
                    !sampleInfo.sections[this.newExam.section_id] && (sampleInfo.sections[this.newExam.section_id] = false);
                    this.examPromises.push(this.$firebase.database().ref('admittedSamples/' + value.key).set(sampleInfo))
                })
                this.saveExamInfo();
            }).catch((error) => {this.$f7.dialog.alert(error.message, error.code)});
        },

        addExam() {
            const examSamples = Object.keys(this.newExam.samples);
            if(!this.newExam.section_id){
                this.invalidSection = true;
                return
            }
            else if(!this.newExam.name){
                this.invalidExam = true;
                return
            }
            else if (examSamples.length === 0){
                this.noSamples = true;
                return
            }
            if(!this.isEditor){
                this.newExam.id = this.$firebase.database().ref('admissions/' + this.selectedAdmission.id + '/exams/').push().key;
            }
            if(!this.selectedAdmission.opened){
                this.saveAdmittedSampleInfo(examSamples);
            }
            else{
                this.saveExamInfo();
            }
        },

    },

}
</script>

<style scoped>

.borderRed{
    border-top: 2px solid red;
    border-bottom: 2px solid red;
}

.error{
    font-size: 12px;
}

.exam-info{
    text-align: right;
}

.red{
    color: #ff3b30;
}

.preloaderText{
    font-size: 12px;
    color: gray;
}

</style>