<template>
    <f7-popup 
        class="sample-selector" 
        @popup:open="!externalId && getSamples()"
    >
        <f7-page>
            <f7-navbar title="Seleccione una muestra">
                <f7-nav-right>
                    <f7-link popup-close>Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <div v-if="isLoading" class="text-align-center margin-top">
                <p class="preloaderText">Cargando lista de muestras...</p>
                <f7-preloader color="teal"></f7-preloader>
            </div>

            <f7-block v-else>
                <f7-searchbar
                    disable-button-text="Cancel"
                    placeholder="Para buscar, ingrese el nombre de una muestra"
                    search-container=".search-list"
                    search-in=".item-title"
                    :clear-button="true"
                    remove-diacritics
                    :backdrop="false"
                    class="searchbar"
                ></f7-searchbar>
               
                <f7-list class="searchbar-not-found">
                    <f7-list-item title="No se encontraron resultados"></f7-list-item>
                </f7-list>

                <f7-list class="search-list searchbar-found">
                    <f7-list-item 
                        radio
                        v-for="sample in samples" 
                        :key="sample.id"
                        :title="sample.name" 
                        name="exam-radio"
                        :checked="externalId === sample.id"
                        @click="selectSample(sample)"
                    >
                    </f7-list-item>
                </f7-list>

            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: ['externalId'],

    data() {
        return {
            isLoading:false,
            samples: []
        }
    },

    computed: {
        ...mapState(['admittedExam'])
    },

    methods: {
        ...mapActions(['getAPIInfo']),

        getSamples(){
            if(this.admittedExam){
                this.isLoading = true
                this.$firebase.auth().currentUser.getIdToken()
                    .then(idToken => this.getAPIInfo({url: 'exams/' + this.admittedExam.externalId , userToken: idToken}))
                    .then((resp) => {
                        this.samples = resp.data.data.samples;
                        this.isLoading = false;
                    })
                    .catch((err) => {this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error')})
            }
            else{
                if(!this.allSamples){
                    this.isLoading = true;
                    this.$firebase.auth().currentUser.getIdToken()
                        .then(idToken => this.getAPIInfo({url: 'samples/list', userToken: idToken}))
                        .then((resp) => {
                            this.$store.commit('setWhatTo', {what: 'allSamples', to: resp.data.data});
                            this.samples = this.$store.getters.sortArray('allSamples');
                            this.isLoading = false;
                        })
                }
                else{
                    this.samples = this.$store.getters.sortArray('allSamples');
                }
            }
        },

        selectSample(sample) {
            this.$emit('selectSample', sample);
            this.$f7.popup.close('.sample-selector');
        }
    }
}
</script>

<style scoped>

.preloaderText{
    font-size: 12px;
    color: gray;
}

</style>