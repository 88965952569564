<template>
    <div>
        <f7-block class="form-background no-padding-horizontal padding-bottom-half margin-bottom-half">
            <f7-progressbar v-if="loadingExamInfo" infinite></f7-progressbar>
            <f7-list class="margin-bottom-half no-margin-top" no-hairlines>
                <f7-list-item divider>Informe</f7-list-item>
            </f7-list>
            <div 
                v-if="loadingExamInfo || loadingExamsResults" 
                class="text-align-center margin-vertical-half"
            >
                <p class="preloaderText">Cargando información...</p>
                <f7-preloader color="teal"></f7-preloader>
            </div>

            <div v-else>
            <f7-row>
                <f7-col width="40">
                    <f7-block-title class="margin-top-half">
                        EXAMEN
                    </f7-block-title>
                </f7-col>
                <f7-col width="60">
                    <f7-block-title class="margin-top-half">
                        RESULTADOS
                    </f7-block-title>
                </f7-col>
            </f7-row>

            <f7-row
                v-for="(result, index) in resultsTypes"
                :key="index"
            >
                <f7-col width="40">
                    <f7-list no-hairlines media-list class="no-margin-vertical">
                        <f7-list-item 
                            class="no-padding-vertical"
                            :title="result.examName"
                        >
                        </f7-list-item>
                    </f7-list>
           
                </f7-col>
                <f7-col
                    width="60"
                    v-if="result.resultType === 'cualitativo'"
                >
                    <f7-list no-hairlines class="no-margin-vertical">
                        <f7-list-input
                            type="select"
                            @change="addResult($event, result)"
                            :value="results[result.examCode] && results[result.examCode].value"
                            error-message='Debe seleccionar un resultado'
                            :error-message-force="(invalidResults === result.examCode) || 
                                (invalidResults === 'cualitativo')"
                        >
                            <option value="">Seleccione un resultado</option>
                            <option
                                v-for="(value, index) in result.qualitativeValues"
                                :key="'value-' + index"
                            >
                                {{value}}
                            </option>
                            <option>Sin resultado</option>
                        </f7-list-input>
                    </f7-list>
                </f7-col>
                <f7-col
                    width="60"
                    v-else-if="result.resultType === 'cuantitativo'"
                >
                    <f7-row>
                        <f7-col>
                            <f7-list no-hairlines class="no-margin-vertical">
                                <f7-list-input
                                    type="text"
                                    :value="results[result.examCode] && results[result.examCode].numericalValue"
                                    @input="addResult($event, result)"
                                    error-message='Debe ingresar un resultado'
                                    :error-message-force="(invalidResults === result.examCode) || 
                                        (invalidResults === 'cuantitativo')"
                                >
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                        <f7-col>
                            <f7-list no-hairlines class="no-margin-vertical">
                                <f7-list-input
                                    type="select"
                                    @change="selectUnit($event, result)"
                                    :value="results[result.examCode] && results[result.examCode].unit"
                                    error-message='Debe seleccionar una unidad'
                                    :error-message-force="invalidUnit === result.examCode"
                                >
                                    <option value="">Seleccione unidad de medida</option>
                                    <option
                                        v-for="(unit) in $store.getters.objToArray('allUnits')" 
                                        :key="unit.id"
                                    >
                                        {{unit.name}}
                                    </option>
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                    </f7-row>
                </f7-col>
                <f7-col width="60" v-else>
                    <f7-list no-hairlines class="no-margin-vertical">
                        <f7-list-input
                            type="text"
                            :value="results[result.examCode] && results[result.examCode].value"
                            @input="addResult($event, result)"
                            error-message='Debe ingresar un resultado'
                            :error-message-force="invalidResults === result.examCode"
                        >
                        </f7-list-input>
                    </f7-list>
                </f7-col>
            </f7-row>
            </div>

            <f7-list no-hairlines>
                <f7-list-input
                    class="margin-top"
                    label="MÉTODO"
                    type="select"
                    @change="selectProcessingMethod($event)"
                    error-message='Debe seleccionar un método'
                    :error-message-force="invalidMethod"
                >
                    <option></option>
                    <option
                        v-for="(method, index) in examInfo.exam_processing_methods"
                        :key="'method-' + index"
                        :value="method.id"
                        :selected="(resultsDetails.method_id === method.id) || (resultsDetails.method === method.name)"
                    >
                        {{method.name}}
                    </option>
                </f7-list-input>
                <f7-list-input
                    label="OBSERVACIONES"
                    type="textarea"
                    :value="resultsDetails.obs"
                    @input="resultsDetails.obs = $event.target.value"
                >
                </f7-list-input>
                <f7-list-input
                    label="TIPO DE INFORME"
                    type="select"
                    :value="resultsDetails.report_type"
                    @change="selectReportType($event)"
                    error-message='Debe seleccionar un tipo de reporte'
                    :error-message-force="invalidReportType"
                >
                    <option></option>
                    <option>Informe preliminar</option>
                    <option>Informe final</option>
                </f7-list-input>
            </f7-list>
        </f7-block>
        <f7-row>
            <f7-col></f7-col>
            <f7-col v-if="misprivx.vercolas[focused_section.id].ing">
                <f7-button 
                    v-if="!focused_orden.examen.ingresado" 
                    fill 
                    raised 
                    @click="saveResults()"
                >
                    Guardar Resultados
                </f7-button>
                <f7-button 
                    v-else 
                    fill 
                    raised 
                    tooltip="Será necesario volver a confirmar y firmar el examen" 
                    @click="saveResults()"
                >
                    Modificar resultados
                </f7-button>
            </f7-col>
        </f7-row>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    data() {
        return {
            examInfo: {},
            resultsTypes: [],
            resultsDetails: {
                method: null,
                method_id: null,
                obs: null,
                report_type: '',
            },
            loadingExamInfo: false,
            loadingExamsResults: false,
            results: {},
            structured_result: {},
            collection_results: {},
            invalidResults: '',
            invalidUnit: '',
            invalidMethod: false,
            invalidReportType: false,
        }
    },

    computed: {
        ...mapState(['focused_orden', 'misprivx', 'focused_section', 'allUnits']),
    },

    props: ['sendResults'],

    methods: {
        ...mapActions(['getAPIInfo']),

        getUnits(){
            return this.$firebase.database().ref('units/').once('value')
        },

        addResultTypes(responses){
            responses.forEach((resp) => {
                let examName = this.examInfo.name;
                if(this.examInfo.associations.length > 0){
                    const associatedExamInfo = this.examInfo.associations.find((association) => {
                        return association.associated_exam.code === resp.key
                    });
                    examName = associatedExamInfo.associated_exam.name;
                }
                if(resp.val()){
                    Object.values(resp.val()).forEach((result) => {
                        let resultInfo = {
                            examCode: resp.key,
                            examName,
                            resultType: result.name,
                        };
                        if(result.name === 'cualitativo'){
                            resultInfo.qualitativeValues = Object.keys(result.values);
                        }
                        else{
                            if(!this.allUnits){
                                this.getUnits().then((snapshot) => {
                                    this.$store.commit('setObjTo', { what: 'allUnits', to: snapshot.val() })
                                }).catch((err) => {
                                    this.loadingExamInfo = false;
                                    this.$f7.dialog.alert(err.message, 'Ops, ha ocurrido un error');
                                });
                            }
                        }
                        this.resultsTypes.push(resultInfo);
                    })
                }
                else{
                    let resultInfo = {
                        examCode: resp.key,
                        examName,
                        resultType: 'freetext',
                    }
                    this.resultsTypes.push(resultInfo);
                }
            })
        },

        getResults(){
            this.loadingExamsResults = true;
            this.$firebase.database().ref(`exams_results/${this.focused_orden.examen.id}/`).once('value')
                .then((resp) => {
                    const examResult = resp.val();
                    if(examResult && examResult.result_type === 'structured'){
                        this.resultsDetails = {
                            method: examResult.structured_result.method || null,
                            method_id: examResult.structured_result.method_id || null,
                            obs: examResult.structured_result.obs || null,
                            report_type: examResult.structured_result.report_type,
                        }
                        this.structured_result = examResult.structured_result;
                        this.results[this.focused_orden.examen.externalId] = {
                            value: examResult.structured_result.result,
                            numericalValue: examResult.structured_result.numericalResult || null,
                            unit: examResult.structured_result.unit || null,
                        }
                    }
                    else if(examResult && examResult.result_type === 'collection'){
                        this.resultsDetails = {
                            method: examResult.method,
                            method_id: examResult.method_id,
                            obs: examResult.obs || null,
                            report_type: examResult.report_type,
                        }
                        this.collection_results = examResult.collection_results;
                        this.results = {...examResult.collection_results};
                    }
                    this.loadingExamsResults = false;
                }).catch((err) => {
                    this.loadingExamsResults = false;
                    this.$f7.dialog.alert(err.message, 'Ops, ha ocurrido un error');
                });
        },

        getExamInfo(){
            this.loadingExamInfo = true;
            this.$firebase.auth().currentUser.getIdToken().then(idToken => {
                return this.getAPIInfo({url: 'exams/' + this.focused_orden.examen.externalId, userToken: idToken});
            }).then((resp) => {
                this.examInfo = resp.data.data;
                let resultsPromises = [];
                if(this.examInfo.associations.length > 0){
                    this.examInfo.associations.forEach((association) => {
                        resultsPromises.push(
                            this.$firebase.database().ref('results_types/' + association.associated_exam.code).once('value')
                        );
                    })
                }else{
                    resultsPromises.push(
                        this.$firebase.database().ref('results_types/' + this.examInfo.code).once('value')
                    );
                }
                Promise.all(resultsPromises).then((responses) => {
                    this.addResultTypes(responses);
                    if(this.focused_orden.examen.ingresado){
                        this.getResults();
                    }
                    this.loadingExamInfo = false;
                }).catch((err) => {
                    this.loadingExamInfo = false;
                    this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error');
                });
            }).catch((err) => {
                this.loadingExamInfo = false;
                this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error');
            });
        },

        addResult(event, resultInfo){
            const value = event.target.value;
            if(this.examInfo.associations.length > 0) {
                !this.collection_results[resultInfo.examCode] && (this.collection_results[resultInfo.examCode] = {});
                if(resultInfo.resultType === 'cuantitativo') {
                    this.collection_results[resultInfo.examCode].numericalValue = value;
                }else{
                    this.collection_results[resultInfo.examCode].value = value;
                }
                this.results = {...this.collection_results};
                this.invalidResults = '';
                return
            }
            if(resultInfo.resultType === 'cuantitativo'){
                this.structured_result.numericalResult = value;
                (!this.results[this.examInfo.code]) && (this.results[this.examInfo.code] = {});
                this.results[this.examInfo.code].numericalValue = value;
                this.results = {...this.results};
            }else{
                this.structured_result.result = value;
                (!this.results[this.examInfo.code]) && (this.results[this.examInfo.code] = {});
                this.results[this.examInfo.code].value = value;
            }
            this.invalidResults = '';
        },

        selectUnit(event, resultInfo){
            const selectedUnit = event.target.value;
            selectedUnit && (this.invalidUnit = '');
            if(this.examInfo.associations.length > 0) {
                !this.collection_results[resultInfo.examCode] && (this.collection_results[resultInfo.examCode] = {});
                this.collection_results[resultInfo.examCode].unit = selectedUnit;
                this.results = this.collection_results;
                return
            }
            this.structured_result.unit = selectedUnit;
            this.results[this.examInfo.code].unit = selectedUnit;
            this.results = {...this.results};
        },

        selectProcessingMethod(event){
            const selectedMethodId = event.target.value;
            this.resultsDetails.method_id = selectedMethodId ? parseInt(selectedMethodId) : selectedMethodId;
            if(!selectedMethodId){
                this.resultsDetails.method = null;
                return
            }
            const processingMethod = this.examInfo.exam_processing_methods.find((method) => {
                return method.id == selectedMethodId;
            })
            this.resultsDetails.method = processingMethod.name;
            this.invalidMethod = false;
        },

        selectReportType(event){
            this.resultsDetails.report_type = event.target.value;
            this.invalidReportType = false;
        },

        verifyCollectionResults(examResult){
            for(let i = 0; i < this.resultsTypes.length; i++){
                const result = this.resultsTypes[i];
                if(!this.collection_results[result.examCode]){
                    this.invalidResults = result.examCode;
                    return
                }
                else if(result.resultType !== 'cuantitativo' && !this.collection_results[result.examCode].value){
                    this.invalidResults = result.examCode;
                    return
                }
                else if((result.resultType === 'cuantitativo') && 
                    (!this.collection_results[result.examCode].numericalValue || 
                    !this.collection_results[result.examCode].unit)){
                        (!this.collection_results[result.examCode].numericalValue) && (this.invalidResults = result.examCode);
                        (!this.collection_results[result.examCode].unit) && (this.invalidUnit = result.examCode);
                        return
                }
            }
            const collectionResults = {
                result_type: 'collection',
                collection_results: this.collection_results,
                ...this.resultsDetails,
            }
            examResult = {...examResult, ...collectionResults}
            this.sendResults(examResult);
        },

        verifyStructuredResults(examResult){
            for(let i = 0; i < this.resultsTypes.length; i++){
                const result = this.resultsTypes[i];
                if((result.resultType !== 'cuantitativo') && !this.structured_result.result){
                    this.invalidResults = 'cualitativo';
                    return
                }
                else if(result.resultType === 'cuantitativo' && !this.structured_result.numericalResult){
                    this.invalidResults = 'cuantitativo';
                    return
                }
                else if(result.resultType === 'cuantitativo' && !this.structured_result.unit){
                    this.invalidUnit = result.examCode;
                    return
                }
            }
            examResult.result_type = 'structured';
            examResult.structured_result = {...this.structured_result, ...this.resultsDetails};
            this.sendResults(examResult);
        },

        saveResults(){
            if(!this.resultsDetails.method && this.examInfo.exam_processing_methods.length > 0){
                this.invalidMethod = true;
                return
            }
            else if(!this.resultsDetails.report_type){
                this.invalidReportType = true;
                return
            }
            const exam = this.focused_orden.examen;
            let examResult = {
                admissionId: this.focused_orden.admisionid,
                section_id: exam.section_id,
            }
            !this.resultsDetails.obs && (this.resultsDetails.obs = null);
            if(this.examInfo.associations.length > 0) {
                this.verifyCollectionResults(examResult);
            }else{
                this.verifyStructuredResults(examResult);
            }
        }

    },

    mounted() {
        this.getExamInfo();
    }
}
</script>

<style scoped>

.form-background{
    background-color: white;
    border-radius: 10px;
}

</style>