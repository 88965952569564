<template>
  <f7-page class="svgris" name="scanner">
    <f7-navbar  >
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title>Scanner de Órdenes</f7-nav-title>
    </f7-navbar>

<qrcode-stream v-if="!processing" @init="onInit" @decode="onDecode"></qrcode-stream>
<f7-card v-if="selectedAdmission">
  <f7-card-header>
    <f7-list class="width-100" media-list>
      <f7-list-item
      :title="selectedAdmission.fullnam"
      :subtitle="selectedAdmission.patient?selectedAdmission.patient.identificador:'Sin identificador definido aun'"
      >
      </f7-list-item>
    </f7-list>
  </f7-card-header>
  <f7-card-footer>
    <f7-link></f7-link>
    <f7-button fill @click="subirOcargar()">Cargar Imagen o PDF</f7-button>
  </f7-card-footer>
</f7-card>
    <input type="file" id="scanner-input" @change="filesChange($event)" accept="image/*,application/pdf" />

  </f7-page>
</template>

<script>
import { QrcodeStream, QrcodeCapture } from 'vue-qrcode-reader'
import {scannmixin} from '../mixins/scannmixin';
import {mapState, mapGetters} from 'vuex';

export default {
 mixins: [scannmixin],
   components: {
    QrcodeStream,
    QrcodeCapture
  },
  data() {
    return {
      selectedAdmission:null,
      processing: false,
      notaPerso:''
    }
  },
  computed:{
    // ...mapState(['defaultAvatar','persona']),
    // ...mapGetters(['focusedEvent','myName','Myeventos']),
  
  },
  methods:{
    subirOcargar: function () {
            this.$$('#scanner-input').click();
      },
      abrirLoadinNoti(){
        return this.$f7.dialog.alert('Archivo cargado exitosamente. Revise la lista de archivos en el menu de órdenes de la admisioón en cuestión','Carga exitosa');
      },

    onDecode (decodedString) {
       this.processing=true;
     console.log(decodedString);
     let procesada=decodedString.split('@');
     console.log('procesada',procesada);


    if(procesada[0]=='scan-order'){
      //es un escaneo de orden para una admision hecha en inmunocel Portal
      //1.- Ver si admision existe
      //lamar a la Base de datos
      this.$f7.dialog.preloader('Validando código...');
       return this.$firebase.database().ref('admissions/'+procesada[1]).once('value').then((eldatum)=> {
         if(!eldatum.exists()){
           throw new Error('Target '+procesada[1]+' no existe.');
         }
          this.$f7.dialog.close();
          let laselectedAdmission=eldatum.val();
          //inyecatr full nombre paciente IF ANY
          laselectedAdmission.fullnam=laselectedAdmission.patient?(laselectedAdmission.patient.nombres+' '+laselectedAdmission.patient.primerApellido+' '+laselectedAdmission.patient.segundoApellido):'Sin nombre definido aun';
            this.selectedAdmission=laselectedAdmission;
            console.log(this.selectedAdmission);
        }).catch((error)=>{
          console.log('error en fx',error);
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,error.code || 'Error en decode');
        });

    }
    else{
       return this.$f7.dialog.alert('Método '+procesada[0]+' no permitido', 'Error',()=>{
         console.log('TODO: reinit el scanner');
      });
     }
   },
   onInit (promise) {
    // show loading indicator
    this.$f7.dialog.preloader('Iniciando scanner...');
    return promise.then(()=>{
       console.log('Inicializado OK');
       this.$f7.dialog.close();
    }).catch(error=>{
      this.$f7.dialog.close();
      let errorreaseon='';
         if (error.name === 'NotAllowedError') {
        errorreaseon='user denied camera access permisson';
      } else if (error.name === 'NotFoundError') {
         errorreaseon=' no suitable camera device installed';
      } else if (error.name === 'NotSupportedError') {
         errorreaseon=' page is not served over HTTPS (or localhost)';
      } else if (error.name === 'NotReadableError') {
         errorreaseon=' maybe camera is already in use';
      } else if (error.name === 'OverconstrainedError') {
         errorreaseon=' did you requested the front camera although there is none?';
      } else if (error.name === 'StreamApiNotSupportedError') {
         errorreaseon=' browser seems to be lacking features';
      }
      this.$f7.dialog.alert(errorreaseon, error.name,()=>{
         this.$f7.views.main.router.back();
      });
    });
  }
  }
}
</script>

<style>
#scanner-input{
  display: none!important;
}
</style>