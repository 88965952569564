<template>
    <f7-page>
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title  
                :title="focused_section.name ? 'Traslado de muestras' : 'Eliminación de muestras'" 
                :subtitle="focused_section.name">
            </f7-nav-title>
        </f7-navbar>
        <f7-row no-gap>
            <f7-col width="100" large="65" v-if="noScanner === true && isVisible">
                <f7-block-title>Ingreso manual de muestra</f7-block-title>
                <f7-searchbar
                    :disable-button="true"
                    placeholder="Ingrese ID de muestra"
                    custom-search
                    :backdrop="false"
                    @searchbar:search="searchId">
                </f7-searchbar>
                <p class="margin-left" v-if="invalidId">Ingrese un Id de muestra válido</p>
            </f7-col>
            <f7-col width="100" large="65" v-else-if="noScanner === false && isVisible"> 
                <sample-scanner 
                    v-if="focused_section.name"
                    v-on:scanCode="changeLocation($event)" 
                    v-on:noScanner="noScanner = true"
                    scannerType= "many">
                </sample-scanner>
                <sample-scanner
                    v-else
                    v-on:scanCode="verifySampleDeletion($event)"
                    v-on:noScanner="noScanner = true" 
                    scannerType= "many">
                </sample-scanner>
            </f7-col>
            <f7-col width="100" large="35" v-if="isVisible">
                <f7-list media-list class="margin-horizontal">
                    <f7-list-item divider :title="noScanner ? 'Muestras ingresadas' : 'Muestras escaneadas'"></f7-list-item>
                    <f7-list-item v-for="(value, key) in scannedSamples" :key="key"
                        :title="key"
                        :subtitle="(value.admissionInfo && value.admissionInfo.samples[key]) && (value.admissionInfo.samples[key].name)"
                        :text="value.admissionInfo && lastNamesAndNames(value.admissionInfo.patient)"
                        :footer="value.reason">
                        <f7-icon 
                            v-if="value.isRejected" 
                            slot="media" 
                            material="cancel" 
                            class="rejected" 
                            :tooltip="value.reason">
                        </f7-icon>
                        <f7-icon 
                            v-else
                            slot="media" 
                            material="check_circle" 
                            class="accepted"
                            :tooltip="focused_section.name ? 'Traslado exitoso' : 'Eliminación exitosa'">
                        </f7-icon>
                        <f7-link v-if="!value.isRejected && focused_section.name" icon-material="undo" slot="after" tooltip="Deshacer movimiento" @click="undoMovement(value)">
                        </f7-link>
                    </f7-list-item>
                </f7-list>
            </f7-col>
        </f7-row>
    </f7-page>
</template>

<script>
import { mapState } from 'vuex'
import { paxhelpersmixin } from '../mixins/paxhelpersmixin';
import SampleScanner from '../components/sample-scanner.vue'

export default {
    data(){
        return {
            scannedSamples: {},
            allowedSections: [],
            noScanner: '',
            isVisible: false,
            invalidId: false
        }
    },

    components: {
        SampleScanner
    },

    computed: {
        ...mapState(['focused_section', 'profile_userInfo', 'misprivx'])
    },

    mixins:[
        paxhelpersmixin,
    ],

    methods: {
        changeLocation(sampleInfo){
            let samples = {...this.scannedSamples};
            let sample = {...sampleInfo};
            this.$firebase.database().ref('admissions/' + sample.sampleState.admissionId).once('value')
            .then((resp) => {
                sample.admissionInfo = resp.val();
                if(!sample.isRejected){
                    if(!sample.sampleState.sections || sample.sampleState.sections[this.focused_section.id] == undefined){
                        sample.isRejected = true;
                        sample.reason = 'Muestra no corresponde a ' + this.focused_section.name;
                    }
                    else if(sample.sampleState.where === this.focused_section.id){
                        sample.isRejected = true;
                        sample.reason = 'La muestra ya está en ' + this.focused_section.name;
                    }
                    else{
                        sample.previousSection = sample.sampleState.where;
                        sample.sampleState.where = this.focused_section.id;
                        sample.sampleState.from = this.$moment().unix();
                        sample.sampleState.sections[this.focused_section.id] = true;
                        this.$firebase.database().ref('admittedSamples/' + sample.barCode).set(sample.sampleState)
                        .then(() => {
                            sample.isRejected = false
                            const logInfo = {
                                who: this.profile_userInfo.uid,
                                whn: this.$moment().unix(),  
                                how: 'manual',
                                wht: 'recepcion en ' + this.focused_section.name
                            }
                            const logId =  this.$firebase.database().ref('samples_log/' + sample.barCode).push().key;
                            this.$firebase.database().ref(`samples_log/${sample.barCode}/${logId}`).set(logInfo)
                            .catch((error) => this.$f7.dialog.alert(error.message, 'Ops, ha ocurrido un error'));
                        })
                        .catch(() => sample.isRejected = true);
                    }
                }
                samples[sample.barCode] = sample;
                this.scannedSamples = samples;
            })
             .catch((error) => this.$f7.dialog.alert(error.message, 'Ops, ha ocurrido un error'));
        },

        deleteSample(sample){
            let samples = { ...this.scannedSamples }
            let deletedSample = { ...sample }
            deletedSample.reason = 'Muestra eliminada';
            samples[sample.barCode] = sample;
            this.scannedSamples = samples;
            deletedSample.sampleState.discarded = true;
            this.$firebase.database().ref('admittedSamples/' + sample.barCode).set(sample.sampleState)
            .then(() => {
                sample.isRejected = false
                const logInfo = {
                    who: this.profile_userInfo.uid,
                    whn: this.$moment().unix(),  
                    how: 'manual',
                    wht: 'muestra eliminada'
                }
                const logId =  this.$firebase.database().ref('samples_log/' + sample.barCode).push().key;
                this.$firebase.database().ref(`samples_log/${sample.barCode}/${logId}`).set(logInfo)
                .catch((error) => this.$f7.dialog.alert(error.message, 'Ops, ha ocurrido un error'));
            })
            .catch(() => sample.isRejected = true);
        },

        verifySampleDeletion(sampleInfo){
            let samples = {...this.scannedSamples};
            let sample = {...sampleInfo};
            this.$firebase.database().ref('admissions/' + sample.sampleState.admissionId).once('value')
            .then((resp) => {
                sample.admissionInfo = resp.val();
                if(sample.isRejected){
                    samples[sample.barCode] = sample;
                    this.scannedSamples = samples;
                    return
                }
                if(!sample.sampleState.sections){
                    this.deleteSample(sample);
                    return
                }
                const sections = Object.keys(sample.sampleState.sections).filter((sectionId) => {
                    return this.allowedSections.includes(sectionId)
                })
                if(sections.length === 0){
                    sample.isRejected = true;
                    sample.reason = 'No tienes permisos para eliminar esta muestra';
                    samples[sample.barCode] = sample;
                    this.scannedSamples = samples;
                    return
                }
                if(!sample.admissionInfo.samples[sample.barCode]){
                    this.deleteSample(sample);
                    return
                }
                if(!sample.admissionInfo.samples[sample.barCode].isRejected){
                    if(!sample.sampleState.exams){
                        this.deleteSample(sample);
                        return
                    }
                    else if(Object.values(sample.sampleState.exams).includes(false)){
                        sample.isRejected = true;
                        sample.reason = 'La muestra tiene exámenes sin firmar';
                        samples[sample.barCode] = sample;
                        this.scannedSamples = samples;
                        return
                    }
                }
                this.deleteSample(sample);
            })
             .catch((error) => this.$f7.dialog.alert(error.message, 'Ops, ha ocurrido un error'));
        },

        searchId(searchbar, query, previousQuery){
            const code = query;
            let sampleInfo = {
                barCode: '',
                sampleState: {}
            };
            const numbers = /^[0-9]+$/;
            if(code.length !== 10 || !code.match(numbers)){
                this.invalidId = true;
                return
            }
            sampleInfo.barCode = code;
            this.$firebase.database().ref('admittedSamples/' + sampleInfo.barCode).once('value')
            .then((data) => {
                this.invalidId = false;
                if(data.val()){
                    sampleInfo.sampleState = data.val();
                }
                else{
                    sampleInfo.isRejected = true;
                    sampleInfo.reason = 'ID no encontrado'
                }
                if(this.focused_section.name){
                    this.changeLocation(sampleInfo)
                }
                else{
                    this.verifySampleDeletion(sampleInfo)
                }
            })
            .catch((error) => {
                this.$f7.dialog.alert(error.message, 'Ops, ha ocurrido un error')
            })
        },

        undoMovement(sample){
            this.$f7.dialog.preloader('Guardando');
            sample.sampleState.sections[this.focused_section.id] = false;
            sample.sampleState.where = sample.previousSection ? sample.previousSection: null
            this.$firebase.database().ref('admittedSamples/' + sample.barCode).set(sample.sampleState)
            .then(() => {
                let samples = {...this.scannedSamples};
                delete samples[sample.barCode];
                this.scannedSamples = samples;
                this.$f7.dialog.close();
                this.$f7.toast.create({
                    text: 'Movimiento revertido', 
                    closeTimeout: 2000,
                    position: "center"
                    }).open();
                const logInfo = {
                    who: this.profile_userInfo.uid,
                    whn: this.$moment().unix(),  
                    how: 'manual',
                    wht: 'revierte traslado de muestra'
                }
                const logId =  this.$firebase.database().ref('samples_log/' + sample.barCode).push().key;
                this.$firebase.database().ref(`samples_log/${sample.barCode}/${logId}`).set(logInfo)
                .catch((error) => this.$f7.dialog.alert(error.message, 'Ops, ha ocurrido un error'));
            })
            .catch((error) => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, 'Ops, ha ocurrido un error')
            });
        },

    },

    mounted(){
        if(!this.focused_section.name){
            for(let section in this.misprivx.vercolas){
                this.misprivx.vercolas[section].fir && this.allowedSections.push(section)
            }
        }
        this.$f7.dialog.create({
            title: 'Traslado de muestras',
            buttons: [
                {
                    text: 'Activar escaneo de muestras',
                    cssClass: 'text-align-center',
                    onClick: (() => {
                        this.noScanner = false;
                        this.isVisible = true;
                    })
                },
                {
                    text: 'Ingreso manual de muestras',
                    cssClass: 'text-align-center',
                    onClick: (() => {
                        this.noScanner = true;
                        this.isVisible = true;
                    })
                },
                {
                    text: 'Cerrar',
                    close: true
                }
            ],
            verticalButtons: true,
        }).open();
    }
}
</script>

<style scoped>

.accepted{
    color: #0cb754;
}

.rejected{
    color: #fa5959;
}

</style>