import { mapState  } from 'vuex';

export const scannmixin = {
    data: function(){
        return {
            
            }
    },
    computed: {
        ...mapState(['selectedAdmission']),
    },
    watch: {
	
    },
    mounted(){

    },
    methods: {
      filesChange: function (e) {
        let file = e.target.files[0];
        if (file) {
            console.log(file);
            this.subirfoto(file, file.name);
        }
    },
    subirfoto: function (filereceive, filename) {
        this.progressdialog = this.$f7.dialog.progress('Cargando documento', 0);
        this.progressdialog.setText('0%');
        let ruta='ordenes/' + this.selectedAdmission.id + '/' + filename;
         this.task = this.$firebase.storage().ref(ruta).put(filereceive);
         this.task.on('state_changed',(snapshot)=>{
                let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                let nentero = Math.round(percentage);
                if (!isNaN(nentero)) {
                    this.progressdialog.setProgress(nentero);
                    this.progressdialog.setText(nentero + '%');
                }
            }, (error)=> {
            // Handle unsuccessful uploads
              this.progressdialog.close();
                 this.$f7.dialog.alert(error.code,'upload error');
            }, ()=> {
                console.log('termino, observable debiese ya haberlo identificado');
                  this.progressdialog.close();
                  this.abrirLoadinNoti();
                  if(filereceive.type=='application/pdf'){
                      this.task.snapshot.ref.getDownloadURL().then((downloadURL) =>{
                            console.log('File available at', downloadURL);
                            let tosave={
                                fileUrl:downloadURL,
                                fileName:filereceive.name,
                                contentType:filereceive.type
                            };
                            this.$firebase.database().ref('admissions/'+this.selectedAdmission.id+'/ordenes').push(tosave);
                        });
                  }
            }
        );
    },
    }
}