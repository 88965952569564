<template>
    <f7-page>
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title>Exportar datos</f7-nav-title>
        </f7-navbar>
        <f7-block>
            <f7-block-title
                >Seleccione las secciones de las que desea exportar los exámenes
                firmados</f7-block-title
            >
            <f7-list no-hairlines class="no-margin-bottom margin-top-half">
                <f7-list-item
                    v-for="section in sections"
                    :key="section.id"
                    checkbox
                    :title="section.name"
                    :value="section.id"
                    @change="selectSection($event)"
                >
                </f7-list-item>
            </f7-list>
            <f7-row class="margin-bottom">
                <f7-col width="10" large="33"> </f7-col>
                <f7-col width="80" large="33">
                    <f7-button fill class="margin-top" @click="generateExcel()">
                        Generar archivo
                    </f7-button>
                </f7-col>
                <f7-col width="10" large="33"> </f7-col>
            </f7-row>

            <f7-card class="margin-top">
                <f7-card-header class="no-border">
                    <f7-block-title medium>
                        Informes COVID-19
                    </f7-block-title>
                </f7-card-header>
                <f7-card-content>
                    <f7-row>
                        <f7-col width="0" large="30"> </f7-col>
                        <f7-col width="100" large="40">
                            <f7-list no-hairlines-md>
                                <f7-list-input
                                    label="Seleccione una fecha"
                                    type="datepicker"
                                    placeholder="Fecha de informe"
                                    readonly
                                    clear-button
                                    @change="selectDate($event)"
                                    :calendar-params="{
                                        closeOnSelect: true,
                                        maxDate: currentDate,
                                        value: defaultDate,
                                        dateFormat: 'dd-mm-yyyy',
                                    }"
                                    error-message="Debe indicar una fecha"
                                    :error-message-force="invalidDate"
                                ></f7-list-input>
                            </f7-list>
                        </f7-col>
                        <f7-col width="0" large="30"> </f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col width="0" large="30"> </f7-col>
                        <f7-col width="100" large="40">
                            <f7-button
                                fill
                                class="margin-top"
                                @click="generateStatisticalReport()"
                            >
                                Generar informe estadístico
                            </f7-button>
                        </f7-col>
                        <f7-col width="0" large="30"> </f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col width="0" large="30"> </f7-col>
                        <f7-col width="100" large="40">
                            <f7-button
                                fill
                                class="margin-top"
                                @click="generatePatientReport()"
                            >
                                Generar informe pacientes
                            </f7-button>
                        </f7-col>
                        <f7-col width="0" large="30"> </f7-col>
                    </f7-row>
                    <f7-block-title
                        >Exporte PDF unico por tipo resultado COVID para
                        CAS</f7-block-title
                    >
                    <f7-row>
                        <f7-col>
                            <f7-list>
                                <f7-list-input
                                    label="Desde cuando"
                                    type="datepicker"
                                    placeholder="Seleccione inicio"
                                    readonly
                                    @change="cascovidFrom = $event.target.value"
                                    :calendar-params="{
                                        dateFormat: 'dd-mm-yyyy HH::mm',
                                        timePicker: true,
                                    }"
                                ></f7-list-input>
                            </f7-list>
                        </f7-col>
                        <f7-col>
                            <f7-list>
                                <f7-list-input
                                    label="Hasta cuando"
                                    type="datepicker"
                                    placeholder="Seleccione fin"
                                    readonly
                                    @change="cascovidTo = $event.target.value"
                                    :calendar-params="{
                                        dateFormat: 'dd-mm-yyyy HH::mm',
                                        timePicker: true,
                                    }"
                                ></f7-list-input>
                            </f7-list>
                        </f7-col>
                        <f7-col>
                            <f7-button
                                fill
                                class="margin-top"
                                @click="generarCasCovid()"
                                >Generar PDF examenes COVID CAS</f7-button
                            >
                        </f7-col>
                    </f7-row>
                </f7-card-content>
            </f7-card>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";
const axios = require("axios");

export default {
    data() {
        return {
            sectionsId: [],
            currentDate: new Date(),
            queryDate: "",
            defaultDate: [this.$moment()],
            invalidDate: false,
            cascovidFrom: null,
            cascovidTo: null,
        };
    },
    computed: {
        ...mapState(["sections", "env", "profile_userInfo"]),
    },
    methods: {
        generarCasCovid(forcedownload = true) {
            console.log(this.cascovidFrom, this.cascovidTo);
            if (!this.cascovidFrom || !this.cascovidTo) {
                return this.$f7.dialog.alert("Debe indicar desde y hasta");
            }
            this.$f7.dialog.preloader("Generando PDF");
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    //http://localhost:5000/inmunocel-portal/us-central1/secapi/printer/
                    //https://us-central1-inmunocel-portal.cloudfunctions.net/secapi/printer/
                    let fechas = {
                        datefrom: this.cascovidFrom,
                        dateto: this.cascovidTo,
                        prod: true,
                        forcedownload: forcedownload,
                    };
                    return axios.post(
                        "https://us-central1-inmunocel-portal.cloudfunctions.net/secapi/customrep/covidcas",
                        fechas,
                        {
                            headers: {
                                Authorization: "Bearer " + idToken,
                                "Content-Type": "application/json",
                            },
                            responseType: "blob",
                        }
                    );
                })
                .then((response) => {
                    this.$f7.dialog.close();
                    console.log(response);
                    let url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    if (forcedownload) {
                        //var blob = new Blob([response.data], {type: 'application/pdf'});
                        let a = document.createElement("a");
                        a.style = "display: none";
                        document.body.appendChild(a);
                        // let url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.classList.add("external");
                        a.download = "cascovid.pdf";
                        a.click();
                        window.URL.revokeObjectURL(url);
                    } else {
                        this.$store.commit("setWhatTo", {
                            what: "focusedPDF",
                            to: url,
                        });
                        this.$f7.views.main.router.navigate("/pdfviewer/");
                    }
                })
                .catch((error) => {
                    this.$f7.dialog.close();
                    console.log(error);
                });
        },
        selectSection(event) {
            const id = event.target.value;
            if (event.target.checked) {
                this.sectionsId.push(id);
            } else {
                this.sectionsId.splice(this.sectionsId.indexOf(id), 1);
            }
        },

        selectDate(event) {
            this.queryDate = event.target.value;
            this.invalidDate = false;
        },

        generateExcel() {
            if (this.sectionsId.length === 0) {
                this.$f7.dialog.alert(
                    "Debe seleccionar al menos una sección",
                    "Error"
                );
                return;
            }
            this.$f7.dialog.preloader("Generando archivo");
            let query = "";
            this.sectionsId.forEach((id) => {
                query += "sections[]=" + id + "&";
            });
            const url =
                "https://us-central1-inmuno-export.cloudfunctions.net/exportExamsInfo/exams.xlsx?" +
                query +
                "env=" +
                this.env;
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return axios.get(url, {
                        headers: { Authorization: "Bearer " + idToken },
                        responseType: "blob",
                    });
                })
                .then((response) => {
                    this.$f7.dialog.close();
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: "application/vnd.openxmlformats",
                        })
                    );
                    let fileLink = document.createElement("a");
                    document.body.appendChild(fileLink);
                    fileLink.href = fileURL;
                    this.currentDate = this.$moment().format(
                        "DD-MM-YYYY HH:mm"
                    );
                    fileLink.setAttribute(
                        "download",
                        "inmunocel " + this.currentDate + ".xlsx"
                    );
                    fileLink.classList.add("external");
                    fileLink.click();
                    window.URL.revokeObjectURL(fileURL);
                    this.$f7.toast
                        .create({
                            text: "Archivo generado con éxito",
                            closeTimeout: 2500,
                            position: "center",
                        })
                        .open();

                    //Se guarda información de edición en nodo log
                    let logInfo = {
                        who: this.profile_userInfo.uid,
                        whn: this.$moment().unix(),
                        wht: "Exámenes firmados",
                        dtls: {
                            Codigo: true,
                            Examen: true,
                            Seccion: true,
                            "Fecha admision": true,
                            Resultado: true,
                            "Metodo procesamiento": true,
                            "Fecha confirmacion": true,
                            "Confirmado por": true,
                            "Fecha firma": true,
                            "Firmado por": true,
                        },
                    };
                    let logId = this.$firebase
                        .database()
                        .ref("export_log/")
                        .push().key;
                    this.$firebase
                        .database()
                        .ref("export_log/" + logId)
                        .set(logInfo)
                        .catch((err) => {
                            this.$f7.dialog.alert(err.message, err.code);
                        });
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    const blob = new Blob([err.response.data], {
                        type: "text/html",
                    });
                    blob.text().then((text) =>
                        this.$f7.dialog.alert(text, "Ops, ha ocurrido un error")
                    );
                });
        },

        generateStatisticalReport() {
            if (!this.queryDate) {
                this.invalidDate = true;
                return;
            }
            this.$f7.dialog.preloader("Generando archivo");
            let query = "";
            const url =
                "https://us-central1-inmuno-export.cloudfunctions.net/exportExamsInfo/reporte-estadistico.xlsx?date=" +
                this.queryDate +
                "&env=" +
                this.env;
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return axios.get(url, {
                        headers: { Authorization: "Bearer " + idToken },
                        responseType: "blob",
                    });
                })
                .then((response) => {
                    this.$f7.dialog.close();
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: "application/vnd.openxmlformats",
                        })
                    );
                    let fileLink = document.createElement("a");
                    document.body.appendChild(fileLink);
                    fileLink.href = fileURL;
                    const formatedDate = this.$moment(
                        this.queryDate,
                        "DD-MM-YYYY"
                    ).format("DDMMYYYY");
                    fileLink.setAttribute(
                        "download",
                        formatedDate + " lab INMUNOCEL.xlsx"
                    );
                    fileLink.classList.add("external");
                    fileLink.click();
                    window.URL.revokeObjectURL(fileURL);
                    this.$f7.toast
                        .create({
                            text: "Archivo generado con éxito",
                            closeTimeout: 2500,
                            position: "center",
                        })
                        .open();

                    //Se guarda información de edición en nodo log
                    let logInfo = {
                        who: this.profile_userInfo.uid,
                        whn: this.$moment().unix(),
                        wht: "Informe estadístico COVID-19",
                        startDate:
                            this.$moment(this.queryDate, "DD-MM-YYYY")
                                .subtract(1, "days")
                                .format("DD/MM/YYYY") + " 21:01",
                        endsDate:
                            this.$moment(this.queryDate, "DD-MM-YYYY").format(
                                "DD/MM/YYYY"
                            ) + " 21:00",
                    };
                    let logId = this.$firebase
                        .database()
                        .ref("export_log/")
                        .push().key;
                    this.$firebase
                        .database()
                        .ref("export_log/" + logId)
                        .set(logInfo)
                        .catch((err) => {
                            this.$f7.dialog.alert(err.message, err.code);
                        });
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    const blob = new Blob([err.response.data], {
                        type: "text/html",
                    });
                    blob.text().then((text) =>
                        this.$f7.dialog.alert(text, "Ops, ha ocurrido un error")
                    );
                });
        },

        generatePatientReport() {
            if (!this.queryDate) {
                this.invalidDate = true;
                return;
            }
            this.$f7.dialog.preloader("Generando archivo");
            let query = "";
            const url =
                "https://us-central1-inmuno-export.cloudfunctions.net/exportExamsInfo/pacientes.xlsx?date=" +
                this.queryDate +
                "&env=" +
                this.env;
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    return axios.get(url, {
                        headers: { Authorization: "Bearer " + idToken },
                        responseType: "blob",
                    });
                })
                .then((response) => {
                    this.$f7.dialog.close();
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: "application/vnd.openxmlformats",
                        })
                    );
                    let fileLink = document.createElement("a");
                    document.body.appendChild(fileLink);
                    fileLink.href = fileURL;
                    const formatedDate = this.$moment(
                        this.queryDate,
                        "DD-MM-YYYY"
                    ).format("DDMMYYYY");
                    fileLink.setAttribute(
                        "download",
                        "INMUNOCEL_" + formatedDate + ".xlsx"
                    );
                    fileLink.classList.add("external");
                    fileLink.click();
                    window.URL.revokeObjectURL(fileURL);
                    this.$f7.toast
                        .create({
                            text: "Archivo generado con éxito",
                            closeTimeout: 2500,
                            position: "center",
                        })
                        .open();

                    //Se guarda información de edición en nodo log
                    let logInfo = {
                        who: this.profile_userInfo.uid,
                        whn: this.$moment().unix(),
                        wht: "Informe pacientes COVID-19",
                        startDate:
                            this.$moment(this.queryDate, "DD-MM-YYYY")
                                .subtract(1, "days")
                                .format("DD/MM/YYYY") + " 21:01",
                        endsDate:
                            this.$moment(this.queryDate, "DD-MM-YYYY").format(
                                "DD/MM/YYYY"
                            ) + " 21:00",
                    };
                    let logId = this.$firebase
                        .database()
                        .ref("export_log/")
                        .push().key;
                    this.$firebase
                        .database()
                        .ref("export_log/" + logId)
                        .set(logInfo)
                        .catch((err) => {
                            console.log(err.message);
                        });
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    const blob = new Blob([err.response.data], {
                        type: "text/html",
                    });
                    blob.text().then((text) =>
                        this.$f7.dialog.alert(text, "Ops, ha ocurrido un error")
                    );
                });
        },
    },
};
</script>
