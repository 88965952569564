<template>
    <f7-page @page:beforein="getOpenedAdmissions()">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title title="Admisión de muestras"></f7-nav-title>
        </f7-navbar>
        <f7-block-title>Admisiones incompletas</f7-block-title>
        <f7-list v-if="!openedAdmissions">
            <f7-list-item title="No hay admisiones incompletas"> </f7-list-item>
        </f7-list>
        <template v-else>
            <!-- <div
                class="display-flex justify-content-space-between margin-horizontal"
            >
                <div>
                    <label for="">Filtrar por institucion</label>
                    <f7-input
                        class="margin-top-half"
                        type="select"
                        @change="selectedInstitution = $event.target.value"
                    >
                        <option value="">Todas las instituciones</option>
                        <option
                            v-for="institution in institutions"
                            :key="institution.id"
                            :value="institution.id"
                            >{{ institution.name }}</option
                        >
                    </f7-input>
                </div>

                <f7-button fill @click="printLabels"
                    >Procesar admisiones</f7-button
                >
            </div> -->
            <f7-list media-list>
                <!-- <f7-list-item
                    title="Seleccionar todas"
                >
                    <div slot="media">
                        <f7-checkbox
                            :checked="selectAll"
                            @change="selectAllAdmissions($event.target.checked)"
                        ></f7-checkbox>
                    </div>
                    <span slot="after" class="">{{ howManySelectedAdmissionsText }}</span>
                </f7-list-item> -->
                <f7-list-item
                    v-for="(value, key) in filteredAdmissions"
                    :key="key"
                    :title="lastNamesAndNames(value.patient)"
                    :subtitle="
                        value.institution
                            ? value.institution
                            : 'Institución no seleccionada'
                    "
                    :text="`Autor: ${value.username}`"
                    :header="calculateTime(value.creationDate)"
                >
                    <!-- <div slot="media">
                        <f7-checkbox
                            :checked="checkboxChecked(value)"
                            :disabled="checkboxDisabled(value)"
                            @change="admissionSelected($event.target.checked, value)"
                        ></f7-checkbox>
                    </div> -->
                    <div slot="after">
                        <f7-button fill small @click="selectAdmission(value)"
                            >Ver</f7-button
                        >
                    </div>
                </f7-list-item>
            </f7-list>
        </template>
        <f7-fab
            @click="createNewAdmission()"
            position="right-bottom"
            slot="fixed"
            color="primary"
        >
            <f7-icon material="add"></f7-icon>
        </f7-fab>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";
import * as moment from "moment";
import { paxhelpersmixin } from "../../mixins/paxhelpersmixin";
import { printermixin } from "../../mixins/printermixin";

export default {
    mixins: [paxhelpersmixin, printermixin],
    data() {
        return {
            institutions: {},
            selectedInstitution: "",
            selectedAdmissions: {},

            selectAll: false,
        };
    },

    computed: {
        ...mapState(["openedAdmissions", "profile_userInfo"]),

        filteredAdmissions() {
            if (this.selectedInstitution === "") {
                return this.openedAdmissions;
            }

            const filtered = Object.values(this.openedAdmissions).filter(
                (admission) =>
                    admission.institution_id === this.selectedInstitution
            );

            return filtered;
        },

        howManySelectedAdmissionsText() {
            const selectedAdmissionsCount = Object.keys(this.selectedAdmissions).length;

            if (selectedAdmissionsCount === 1) {
                return "1 admisión seleccionada";
            } else if (selectedAdmissionsCount > 1) {
                return `${selectedAdmissionsCount} admisiones seleccionadas`;
            }

            return "";
        }
    },

    created() {
        this.getInstitutions();
    },

    watch: {
        selectedInstitution() {
            this.selectedAdmissions = {};
            this.selectAll = false;
        }
    },

    methods: {
        getInstitutions() {
            this.$firebase
                .database()
                .ref("/institutions")
                .orderByChild("enabled")
                .equalTo(true)
                .once("value")
                .then((snapshot) => {
                    this.institutions = snapshot.val() || {};
                })
                .catch((err) => {
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },

        printLabels() {
            if (Object.keys(this.selectedAdmissions).length === 0) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(
                    "Debe seleccionar por lo menos una admisión"
                );
                return;
            }

            this.$f7.dialog.confirm("¿Está seguro?", () => {
                this.$f7.dialog.preloader("Imprimiendo etiquetas...");

                // wtf: ocurria esto primero y luego salia el preloader, por eso el setTimeout
                setTimeout(() => {
                    Object.values(this.selectedAdmissions).forEach(
                        (admission) => {
                            const patient = admission.patient;
                            const fullName = this.lastNamesAndNames(patient);

                            Object.values(admission.samples).forEach(
                                (sample) => {
                                    console.log(
                                        "printing label sample",
                                        sample.id
                                    );
                                    this.makelabel(
                                        fullName,
                                        this.formatId(patient),
                                        patient.fechaNacimiento,
                                        this.suage(patient.fechaNacimiento),
                                        sample.id,
                                        sample.name,
                                        sample.dateTime
                                    );
                                }
                            );
                        }
                    );

                    this.$f7.dialog.close();

                    this.selectedAdmissions = {};
                }, 0);
            });
        },
        admissionSelected(checked, admission) {
            if (checked) {
                this.$set(this.selectedAdmissions, admission.id, admission);
            } else {
                this.$delete(this.selectedAdmissions, admission.id);
            }
        },
        selectAllAdmissions(checked) {
            Object.keys(this.filteredAdmissions).forEach(admissionId => {
                const admission = this.filteredAdmissions[admissionId];

                if (!this.checkboxDisabled(admission)) {
                    this.admissionSelected(checked, admission);
                }
            });

            this.selectAll = checked;
        },
        checkboxDisabled(admission) {
            if (
                !admission.samples ||
                Object.keys(admission.samples || {}).length === 0
            ) {
                return true;
            }

            if (
                !admission.patient ||
                Object.keys(admission.patient || {}).length === 0
            ) {
                return true;
            }

            return false;
        },
        checkboxChecked(admission) {
            if (this.selectedAdmissions[admission.id]) {
                return true;
            }

            return false;
        },

        getOpenedAdmissions() {
            const preloader = this.$f7.dialog.preloader("Cargando");
            this.$firebase
                .database()
                .ref("admissions/")
                .orderByChild("opened")
                .equalTo(true)
                .once("value")
                .then((snapshot) => {
                    this.$store.commit("setWhatTo", {
                        what: "openedAdmissions",
                        to: snapshot.val(),
                    });
                    preloader.close();
                })
                .catch((err) => {
                    preloader.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },

        calculateTime(date) {
            return "Hace " + this.$moment(date).toNow(true);
        },

        createNewAdmission() {
            this.$f7.dialog.preloader("Generando nueva admisión...");
            const id = this.$firebase
                .database()
                .ref("admissions/")
                .push().key;
            let newAdmission = {
                id,
                patient: "",
                institution: null,
                diagnosticObs: null,
                background: null,
                samples: null,
                exams: null,
                creationDate: new Date().getTime(),
                opened: true,
                username: this.profile_userInfo.displayName,
            };
            this.$firebase
                .database()
                .ref("admissions/" + id)
                .set(newAdmission)
                .then(() => {
                    this.$f7.dialog.close();
                    this.$store.commit("setObjTo", {
                        what: "selectedAdmission",
                        to: newAdmission,
                    });
                    this.$store.commit("setObjTo", {
                        what: "previousDoctor",
                        to: {},
                    });
                    this.$store.commit("setObjTo", {
                        what: "previousResponsible",
                        to: {},
                    });
                    this.$f7.views.main.router.navigate("/admission/");
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },

        selectAdmission(admission) {
            this.$store.commit("setObjTo", {
                what: "selectedAdmission",
                to: admission,
            });
            this.$store.commit("setObjTo", { what: "previousDoctor", to: {} });
            this.$store.commit("setObjTo", {
                what: "previousResponsible",
                to: {},
            });
            this.$f7.views.main.router.navigate("/admission/");
        },
    },
};
</script>
