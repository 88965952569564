<template>
	<f7-page name="pacientes">
    	<f7-navbar  >
      		<f7-nav-left>
        		<f7-link icon-material="menu" panel-open="left"></f7-link>
      		</f7-nav-left>
      		<f7-nav-title >Pacientes</f7-nav-title>
      		<f7-nav-right>
        		<f7-preloader v-if="cargandoDataSearch"></f7-preloader>
      		</f7-nav-right>
        	<f7-subnavbar :inner="false">
				<f7-searchbar
					:disable-button="true"
					remove-diacritics
					placeholder="Busque pacientes"
					custom-search
					@searchbar:search="buscame"
				></f7-searchbar>
    		</f7-subnavbar>
    	</f7-navbar>

		<f7-block v-if="!cargandoDataSearch && typing">{{ typing }}</f7-block>
		<f7-block v-else-if="cargandoDataSearch"> Cargando...</f7-block>
		<f7-list class="no-margin-top" v-else-if="message && resultsBusqueda.length>0" media-list>
			<f7-list-item
				v-for="(unpax,idx) in resultsBusqueda"
				:key="'r'+idx"
				:title="(unpax.primerApellido ||' ' ) + (unpax.segundoApellido?(','+unpax.segundoApellido):'')"
				:subtitle="unpax.nombres"
				:text="unpax.identificador"
				link="#"
				@click="navegaAPaciente(unpax)"
			></f7-list-item>
		</f7-list>
		<f7-block v-else-if="message && !resultsBusqueda.length"> Sin resultados para {{message}}</f7-block>

		<f7-fab position="right-bottom" slot="fixed" color="teal">
			<f7-icon material="add"></f7-icon>
			<f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
			<f7-fab-buttons position="top">
				<f7-fab-button fab-close @click="gotoNeopax()" label="Crear nuevo paciente">
					<f7-icon material="person_add"></f7-icon>
				</f7-fab-button>
			</f7-fab-buttons>
		</f7-fab>
  	</f7-page>
</template>

<script>
import { mapState,mapGetters } from 'vuex';


export default {

	data:function(){
    	return {
      		message: null,
			typing: null,
			debounce: null,
			cargandoDataSearch: false,
			resultsBusqueda:[]
    	}
	  },

   	computed: {
    	...mapState(['focused_member','profile_userInfo'])
  	},

  	methods: {
		navegaAPaciente(pacienteMeta){
			this.$store.commit('setWhatTo',{what: 'pacienteSeleccionado', to: pacienteMeta});
			this.$f7.views.main.router.navigate('/paciente/');
		},

		buscame(searchbar, query, previousQuery){
			this.debounceSearch(query);
		},

		debounceSearch(query) {
			this.message = null
			this.typing = 'Ingresando búsqueda'
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.typing = null;
				this.message = query;
				this.serverSearh(query);
			}, 600);
		},
		serverSearh(query){
			let sinspace=query.trim();
			if(sinspace){
				this.cargandoDataSearch = true;
				let searchPatient = this.$firebase.functions().httpsCallable('searchPatient');
				searchPatient({busqueda: sinspace ,prod: (this.$store.state.env=== 'production'?true:false)})
					.then(reslts=>{
						this.cargandoDataSearch = false;
						this.resultsBusqueda = reslts.data.searchResults || [];

					}).catch(error=>{
						this.cargandoDataSearch = false;
						this.$f7.dialog.alert(error.message,error.code);
					})
			}
		},

		gotoNeopax(){
			this.$store.commit('setWhatTo', {what: 'pacienteSeleccionado', to: null})
			if(this.$f7.views.main.router.currentRoute.url === '/pacientes/'){
				this.$f7.views.main.router.navigate('/neopaciente/');
				}
			else{
				this.$f7.views.main.router.navigate('/neopaciente/', {reloadCurrent: true});
			}
		}
	},

	beforeDestroy() {
		this.$store.commit('setWhatTo', { what: 'pacienteSeleccionado', to: null });
	}
}
</script>

<style>

.lisel{
  	border-left: 7px solid #36b5aa;
}
.member img{
  	max-width: 40px;
}

</style>