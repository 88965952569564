<template>
    <f7-page name="sample-info">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title >Escáner de muestra</f7-nav-title>
        </f7-navbar>
        <f7-row no-gap>
            <f7-col width="0" large="20">
            </f7-col>
            <f7-col width="100" large="60" v-if="noScanner === true">
                <f7-block-title>Ingreso manual de muestra</f7-block-title>
                <f7-searchbar
                    :disable-button="true"
                    placeholder="Ingrese ID de muestra"
                    custom-search
                    :backdrop="false"
                    @searchbar:search="searchId">
                </f7-searchbar>
                <p class="margin-left" v-if="invalidId">Ingrese un Id de muestra válido</p>
            </f7-col>
            <f7-col width="100" large="60" v-else-if="noScanner === false">
                <sample-scanner
                    :class="{'display-none': isHidden}"
                    v-on:scanCode="getSampleInfo($event)"
                    v-on:noScanner="noScanner = true" 
                    scannerType= "one">
                </sample-scanner>
            </f7-col>
            <f7-col width="0" large="20">
            </f7-col>
        </f7-row>

        <f7-popup class="sample-summary" @popup:close="closePopup">
            <f7-page>
                <f7-navbar title="Información de muestra">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block class="margin-left">
                    <f7-row>
                        <f7-col width="30">
                            <p class="label">ID muestra:</p>
                        </f7-col>
                        <f7-col width="70">
                            <p>{{sample.code}}</p>
                        </f7-col>
                    </f7-row>
                    <template v-if="admissionInfo.samples && !admissionInfo.samples[sample.code]">
                         <f7-row>
                            <f7-col width="30">
                                <p class="label">Datos de admisión</p>
                            </f7-col>
                            <f7-col width="70">
                                <p>No hay admisiones asociadas a esta muestra</p>
                            </f7-col>
                        </f7-row>
                    </template>
                    <template v-else>
                        <f7-row>
                            <f7-col width="30">
                                <p class="label">Tipo de muestra:</p>
                            </f7-col>
                            <f7-col width="70">
                                <p>
                                    {{admissionInfo.samples && admissionInfo.samples[sample.code].name}}
                                </p>
                            </f7-col>
                        </f7-row>
                        <f7-row>
                            <f7-col width="30">
                                <p class="label">Fecha toma de muestra:</p>
                            </f7-col>
                            <f7-col width="70">
                                <p>
                                    {{admissionInfo.samples && admissionInfo.samples[sample.code].dateTime}}
                                </p>
                            </f7-col>
                        </f7-row>
                    </template>
                    <f7-row>
                        <f7-col width="30">
                            <p class="label">Ubicación:</p>
                        </f7-col>
                        <f7-col width="70">
                            <p>{{sample.location}}</p>
                        </f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col width="30">
                            <p class="label">Exámenes a realizar:</p>
                        </f7-col>
                        <f7-col width="70" v-if="sample.exams.length > 0">
                            <p v-for="exam in sample.exams" :key="exam.id">{{exam.name}}</p>
                        </f7-col>
                        <f7-col width="70" v-else>
                            <p>No hay exámenes vinculados a la muestra</p>
                        </f7-col>

                    </f7-row>
                    <f7-block-title class="no-margin-left">DATOS PACIENTE</f7-block-title>
                    <f7-row>
                        <f7-col width="30">
                            <p class="label">Nombre:</p>
                        </f7-col>
                        <f7-col width="70">
                            <p>
                                {{admissionInfo.patient && lastNamesAndNames(admissionInfo.patient)}}
                            </p>
                        </f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col width="30">
                            <p class="label">
                                {{admissionInfo.patient && admissionInfo.patient.tipoIdentificador}}:
                            </p>
                        </f7-col>
                        <f7-col width="70">
                            <p>
                                {{admissionInfo.patient && formatId(admissionInfo.patient)}}
                            </p>
                        </f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col width="30">
                            <p class="label">Fecha de nacimiento:</p>
                        </f7-col>
                        <f7-col width="70">
                            <p>{{admissionInfo.patient && admissionInfo.patient.fechaNacimiento}}</p>
                        </f7-col>
                    </f7-row>
                </f7-block>
            </f7-page>
        </f7-popup>

    </f7-page>
</template>

<script>
import { mapState } from 'vuex'
import { format } from 'rut.js';
import { paxhelpersmixin } from '../mixins/paxhelpersmixin';
import SampleScanner from '../components/sample-scanner'

export default {
    components: {
        SampleScanner
    },
    
    data(){
        return {
            sample: {
                sections: [],
                exams: []
            },
            admissionInfo: {},
            isHidden: false,
            noScanner: '',
            invalidId: false,
        }
    },

    computed: {
        ...mapState(['sections'])
    },

    mixins:[
        paxhelpersmixin,
    ],

    methods: {
        // formatRut(){
        //     if(this.admissionInfo.patient.tipoIdentificador === 'RUT'){
        //         this.admissionInfo.patient.identificador = format(this.admissionInfo.patient.identificador)
        //     }
        // },

        getSampleInfo(sampleInfo){
            this.$f7.dialog.preloader('Cargando datos');
            this.sample.code = sampleInfo.barCode;
            if(sampleInfo.sampleState.where){
                const section = this.sections.find((section) => section.id == sampleInfo.sampleState.where)
                this.sample.location = 'En ' + section.name 
            }
            else{
                this.sample.location = 'En admisión'
            }
            this.$firebase.database().ref('admissions/' + sampleInfo.sampleState.admissionId).once('value')
            .then((admission) => {
                this.admissionInfo = admission.val();
                // this.formatRut();
                this.sample.exams = Object.values(this.admissionInfo.exams).filter((exam) => {
                    return exam.samples[this.sample.code]
                })
                this.isHidden = true;
                this.$f7.dialog.close();
                this.$f7.popup.get('.sample-summary').open();
            })
            .catch((err) => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.message, 'Ops, ha ocurrido un error')
            })
        },

        searchId(searchbar, query, previousQuery){
            const code = query;
            let sampleInfo = {
                barCode: '',
                sampleState: {}
            };
            const numbers = /^[0-9]+$/;
            if(code.length !== 10 || !code.match(numbers)){
                this.invalidId = true;
                return
            }
            sampleInfo.barCode = code;
            this.$firebase.database().ref('admittedSamples/' + sampleInfo.barCode).once('value')
            .then((data) => {
                if(data.val()){
                    sampleInfo.sampleState = data.val();
                    this.getSampleInfo(sampleInfo)
                }
                else{
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(`La muestra con ID ${sampleInfo.barCode} no ha sido encontrada`, 
                    'Ops, ha ocurrido un error');
                }
            })
            .catch(() => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.message, 'Ops, ha ocurrido un error')
            })
        },

        formatName(patient){
            let patientName = patient.nombres +  ' ' + patient.primerApellido
            patient.segundoApellido && (patientName += ' ' + patient.segundoApellido)
            return patientName
        },
        
        closePopup(){
            if(!this.noScanner){
                this.isHidden = false;
                this.$f7.views.main.router.navigate('/sample-move/', {reloadCurrent: true});
            }
        },

    },

    mounted(){
        this.$f7.dialog.create({
            title: 'Traslado de muestras',
            buttons: [
                {
                    text: 'Activar escaneo de muestras',
                    cssClass: 'text-align-center',
                    onClick: (() => this.noScanner = false)
                },
                {
                    text: 'Ingreso manual de muestras',
                    cssClass: 'text-align-center',
                    onClick: (() => {this.noScanner = true})
                },
                {
                    text: 'Cerrar',
                    close: true
                }
            ],
            verticalButtons: true,
        }).open();
    }

}
</script>

<style scoped>

.label{
    color: #8e8e93;
}

</style>