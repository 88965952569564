<template>
	<f7-page name="paxs" @page:beforein="getPaxUsers(false)">
    	<f7-navbar  >
      		<f7-nav-left>
        		<f7-link icon-material="menu" panel-open="left"></f7-link>
      		</f7-nav-left>
      		<f7-nav-title subtitle="pax.inmunocel.com" >Usuarios de PAX</f7-nav-title>
      		<f7-nav-right>
						<f7-link @click="getPaxUsers(true)" icon-material="refresh"></f7-link>
      		</f7-nav-right>
        	
    	</f7-navbar>
<f7-progressbar v-if="cargandoDataSearch" infinite> </f7-progressbar>
		<f7-list class="no-margin-top paxlistx" media-list>
			<f7-list-item
				v-for="(unpax,idx) in allPaxsUsersHolder"
				:key="'pax'+idx"
				:title="unpax.email"
				:subtitle="unpax.displayName"
				link="#"
				@click="navegaAPax(unpax)"
			><img :src="unpax.photoURL || '../../static/img/default_original.png'" slot="media" /></f7-list-item>
		</f7-list>


  	</f7-page>
</template>

<script>
import { mapState,mapGetters } from 'vuex';


export default {

	data:function(){
    	return {
			cargandoDataSearch: false,
			resultsBusqueda:[]
    	}
	  },
	  
   	computed: {
    	...mapState(['allPaxsUsersHolder'])
  	},

  	methods: {
			  getPaxUsers(force){
				 if(this.allPaxsUsersHolder.length==0 || force){
					 if(this.allPaxsUsersHolder.length==0){
						this.$f7.dialog.preloader('Cargando...');
					 }
					 else{
						 this.cargandoDataSearch=true;
					 }
							 let paxusers = this.$firebase.functions().httpsCallable('paxusers');
								 return paxusers({prod: (this.$store.state.env=== 'production'?true:false)}).then(allpaxUsersInfo=>{
											 console.log(allpaxUsersInfo);
											 let linfo= allpaxUsersInfo.data || {};
								  let allPaxsusers = linfo.paxusers || [];
									this.$store.commit('setWhatTo',{what:'allPaxsUsersHolder',to:allPaxsusers});
									this.$f7.dialog.close();
									 this.cargandoDataSearch=false;
								 }).catch(error=>{
										this.$f7.dialog.close();
										 this.cargandoDataSearch=false;
										this.$f7.dialog.alert(error.message,error.code);
								 });
				 }
      },
		navegaAPax(paxmeta){
			this.$store.commit('setObjTo',{what: 'focusedPax', to: paxmeta});
			this.$f7.views.main.router.navigate('/onepax/');
		},
    },
}
</script>

<style>

.paxlistx img{
	width: 40px;
}

</style>