<template>
  <f7-page name="privilegios">
    <!-- Top Navbar -->
    <f7-navbar  >
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title >Privilegios</f7-nav-title>
    </f7-navbar>

<f7-list media-list class="fixicons no-margin-top">
  <f7-list-item divider title="Privilegios GLOBALES"></f7-list-item>
  <f7-list-item 
  v-for="unprivx in defsPrivx.globales"
  :key="unprivx.i"
  :title="unprivx.n"
  :text="unprivx.f"
  :after="unprivx.i"
  >
<f7-icon slot="media" :material="unprivx.k"></f7-icon>
  </f7-list-item>

   <f7-list-item divider title="Privilegios por Sección"></f7-list-item>
    <f7-list-item 
  v-for="unprivx in defsPrivx.bysection"
  :key="unprivx.i"
  :title="unprivx.n"
  :text="unprivx.f"
  :after="unprivx.i"
  >
<f7-icon slot="media" :material="unprivx.k"></f7-icon>
  </f7-list-item>
</f7-list>


  </f7-page>
</template>

<script>
import { mapState,mapGetters } from 'vuex';
export default {
computed:{
  ...mapState(['defsPrivx'])
}  ,
methods:{

}
}
</script>
<style>

</style>