<template>
  <f7-page name="zebra">
    <!-- Top Navbar -->
    <f7-navbar  >
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title  title="Zebra Printer" subtitle="Configuracion Impresora"></f7-nav-title>
    </f7-navbar>
<f7-block>
<span style="padding-right:50px; font-size:200%">Zebra Browser Print Test Page</span><br/>
<span style="font-size:75%">This page must be loaded from a web server to function properly.</span><br><br>

<f7-list>
  <f7-list-input
    label="Selected Device:"
    type="select"
    placeholder="Please choose..."
    :value="selected_device"
    @input="onDeviceSelected($event.target.value)"
  >
    <option v-for="(device,idx) in devices" :key="'d'+idx" :value="device.uid">{{device.name}}</option>
  </f7-list-input>
</f7-list>
<f7-button @click="makelabel(nameSrting,rut,nacimiento,edad,codigo,muestra,fecha,seccion)">Imprimir etiqueta de prueba</f7-button>
<input type="button" value="Get Application Configuration" onclick="getConfig()"><br/><br/>
<input type="button" value="Send Config Label" onclick="writeToSelectedPrinter('~wc')"><br/><br/>
<input type="button" value="Send ZPL Label" onclick="writeToSelectedPrinter('^XA^FO200,200^A0N36,36^FDTest Label^FS^XZ')"><br/><br/>
<input type="button" value="Get Status" onclick="writeToSelectedPrinter('~hs'); readFromSelectedPrinter()"><br/><br/>
<input type="button" value="Get Local Devices" onclick="BrowserPrint.getLocalDevices(getDeviceCallback, errorCallback);"><br/><br/>
<input type="text" name="write_text" id="write_text"><input type="button" value="Write" onclick="writeToSelectedPrinter(document.getElementById('write_text').value)"><br/><br/>
<input type="text" placeholder="nombre1" name="escribe" id="nombre1">
<input type="text" placeholder="nombre2" name="escribe" id="nombre2">
<input type="text" placeholder="apellido1" name="escribe" id="apellido1">
<input type="text" placeholder="apellido2" name="escribe" id="apellido2">
<input type="text" placeholder="rut" name="escribe" id="rut">
<input type="text" placeholder="nacimiento" name="escribe" id="nacimiento">
<input type="text" placeholder="edad" name="escribe" id="edad">
<input type="text" placeholder="codigo" name="escribe" id="codigo">
<input type="text" placeholder="muestra" name="escribe" id="muestra">
<input type="text" placeholder="seccion" name="escribe" id="seccion">
<input type="text" placeholder="fecha" name="escribe" id="fecha">
<input type="button" value="Write" onclick="escribir(
	document.getElementById('nombre1').value,
document.getElementById('nombre2').value,
document.getElementById('apellido1').value,
document.getElementById('apellido2').value,
document.getElementById('rut').value,
document.getElementById('nacimiento').value,
document.getElementById('edad').value,
document.getElementById('codigo').value,
document.getElementById('muestra').value,
document.getElementById('seccion').value,
document.getElementById('fecha').value)"><br/><br/>

<input type="button" value="Read" onclick="readFromSelectedPrinter()"><br/><br/>
<input type="button" value="Send BMP" onclick="sendImage('Zebra_logobox.bmp');"><br/><br/>
<input type="button" value="Send JPG" onclick="sendImage('ZebraGray.jpg');"><br/><br/>

</f7-block>
  </f7-page>
</template>

<script>
import {printermixin} from '../mixins/printermixin';

import { mapState } from 'vuex';
export default {
   mixins: [printermixin],
  data(){
    return{
      nameSrting:'Lopetegui Lazo, Marcelo Ariel',
      rut:'16.047.654-0',
      nacimiento:'30/08/85',
      edad:'34a',
      codigo:'2003742342',
      muestra:'Liquido Cefaloraquideo',
      seccion:'CF',
      fecha:'23/12/2020 12:30',
    }
  },
computed:{
 // ...mapState(['defsPrivx'])
},

methods:{


}
}
</script>
<style>

</style>