<template>
    <f7-page name="exams-filter">
        <f7-navbar title="Filtro de exámenes firmados" :subtitle="focused_section.name" back-link/>
        <f7-block>
            <f7-row no-gap>
                <f7-col width="90" medium="15">
                </f7-col>
                <f7-col width="0" medium="70">
                    <f7-card class="padding">
                        <f7-list no-hairlines-md class="no-margin-top">
                            <f7-list-input
                                label="Seleccione la fecha de admisión"
                                type="datepicker"
                                placeholder="Fecha de informe"
                                readonly
                                clear-button
                                @change="selectDate($event)"
                                :calendar-params="{
                                    closeOnSelect: true, 
                                    maxDate: currentDate, 
                                    dateFormat: 'dd-mm-yyyy'
                                }"
                                error-message="Debe indicar una fecha"
                                :error-message-force="invalidDate"
                            >
                                <f7-icon class="calendar-icon" material="today" slot="media"/>
                            </f7-list-input>
                        </f7-list>
                    </f7-card>
                </f7-col>
                <f7-col width="100" medium="15">
                </f7-col>
            </f7-row>
               
            <f7-row class="margin-top" v-if="signedExams.length > 0">
                <f7-col width="100" medium="20">
                    <f7-block-title class="margin-top-half no-margin-bottom">
                        Examenes encontrados: {{exams.length}}
                    </f7-block-title>
                </f7-col>
                <f7-col width="0" medium="60">
                </f7-col>
                <f7-col width="100" medium="20">
                    <f7-button
                        class="institution-button"
                        raised 
                        outline
                        small
                        round
                        icon-material="sort"
                        text="Filtrar por institución" 
                        @click="showInstitutionFilter()"
                    />
                </f7-col>
            </f7-row> 
            <f7-list v-if="noExams">
                <f7-list-item
                    title="No hay examenes firmados en la fecha seleccionada"
                >
                </f7-list-item>
            </f7-list>
            <f7-list media-list v-else>
                <f7-list-item
                    v-for="exam in exams"
                    :key="exam.id"
		            :subtitle="lastNamesAndNames(exam.admission.patient) + ' | ' 
                        + formatId(exam.admission.patient)"
    	            :title="exam.name"
    	            :header="'Admitido el '+ formatDate(exam.admission.creationDate) 
                        + ' @' + exam.admission.institution"
  	            >
                    <div slot="media" class="text-align-center"> 
                        <img class="avatar" :src="(exam.admission.patient.sexo ==='Masculino') ? 
                            ('../static/img/masc.jpg') : ('../static/img/feme.jpg')"
                        />
                        <br/>
                        <span>
                            {{suage(exam.admission.patient.fechaNacimiento)}}
                        </span>
                    </div>
                    <div slot="text">
                        <div v-for="sample in exam.admission.samples" :key="sample.id">
                            <template v-if="exam.samples[sample.id]">
                                <f7-icon material="opacity"></f7-icon> 
                                {{sample.name}} (<b>#{{sample.id}}</b>)
                            </template>
                        </div>
                    </div>
                    <span slot="after"> 
                        <f7-link 
                            @click="printReport(true, exam)" 
                            icon-material="assignment_returned" 
                            class="margin-right"
                            tooltip="Descargar resultados"
                        ></f7-link> 
                        <f7-link 
                            @click="printReport(false, exam)" 
                            icon-material="printer" class="margin-right"
                            tooltip="Ver resultados"
                        ></f7-link> 
                        <f7-link 
                            @click="showOrderDetails(exam)" 
                            icon-material="pageview"
                            tooltip="Ver detalles orden de trabajo"
                        ></f7-link>
                    </span>
                </f7-list-item>
            </f7-list>
        </f7-block>

        <f7-popover class="institutions-filter">
                <f7-list class="institution-list margin-vertical-half">
                    <f7-list-item
                        v-for="institution in filteredInstitutions" 
                        :key="institution.name"
                        checkbox  
                        :title="institution.name"
                        @change="selectInstitution(institution, $event)"
                    >
                    </f7-list-item>
                </f7-list>
        </f7-popover>
    </f7-page>
</template>

<script>
import { mapState } from 'vuex'
import { paxhelpersmixin } from '../mixins/paxhelpersmixin';
import { pdfermixin } from '../mixins/pdfermixin'

export default {
    data() {
        return {
            noExams: false,
            queryDate: '',
            currentDate: new Date(),
            invalidDate: false,
            admissions: [],
            signedExams: [],
            filteredExams: [],
            allInstitutions: {},
            filteredInstitutions: {},
        }
    },

    computed: {
        ...mapState([ 'focused_section', 'sections' ]),

        exams() {
            if(this.filteredExams.length === 0){
                return this.signedExams
            }
            return this.filteredExams
        }
    },

    mixins: [
        paxhelpersmixin,
        pdfermixin
    ],

    methods: {
        selectDate(event) {
            this.queryDate = event.target.value;
            this.invalidDate = false;
            this.noExams = false;
            this.getSignedExams();
        },

        formatDate(date) {
            return this.$moment(date).format('llll');
        },

        getInstitutions() {
            this.$f7.dialog.preloader('Cargando...')
            this.$firebase.database().ref('institutions/').once('value').then((snapshot) => {
                this.$f7.dialog.close();
                this.allInstitutions = snapshot.val();
            }).catch((error) => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code)
            });
        },

        getAdmissions() {
            const startDate = this.$moment(this.queryDate + ' 00:00:00.000', 'DD-MM-YYYY HH:mm:ss.SSS').valueOf();
            const endDate = this.$moment(this.queryDate + ' 23:59:59.999', 'DD-MM-YYYY HH:mm:ss.SSS').valueOf();
            return this.$firebase.database().ref('admissions/').orderByChild('creationDate')
                .startAt(startDate).endAt(endDate).once('value')
        },

        getAdmittedExamInfo(examId){
            return this.$firebase.database().ref('admittedExams/' + this.focused_section.id + '/' + examId)
                .once('value')
        },

        buildFilterInstitutions(admission){
            if(admission.institution_id){
                const institutionName = this.allInstitutions[admission.institution_id].name;
                this.filteredInstitutions[institutionName] = {
                    id: admission.institution_id,
                    name: institutionName,
                }
            }
            else{
                if(!this.filteredInstitutions[admission.institution]){
                    this.filteredInstitutions[admission.institution] = {
                        name: admission.institution,
                    }
                }
            }
        },

        getSignedExams() {
            if(!this.queryDate){
                this.invalidDate = true;
                return
            };
            this.$f7.dialog.preloader('Buscando exámenes');
            let examPromises = [];
            this.signedExams = [];
            this.filteredExams = [];
            this.filteredInstitutions = {};
            this.noExams = false;
            this.getAdmissions().then((response) => {
                if(!response.val()){
                    this.noExams = true;
                    this.$f7.dialog.close();
                    return
                }
                this.admissions = Object.values(response.val()).filter((admission) => {
                    return !admission.opened
                })
                this.admissions.forEach((admission) => {
                    if(admission.exams){
                        Object.values(admission.exams).forEach((exam) => {
                            if(exam.section_id == this.focused_section.id){
                                examPromises.push(this.getAdmittedExamInfo(exam.id));
                            }
                        })
                    }
                })
                Promise.all(examPromises).then((responses) => {
                    responses.forEach((response) => {
                        const exam = response.val();
                        if(exam.firmado){
                            exam.admission = this.admissions.find((admission) => admission.id === exam.admissionId);
                            this.buildFilterInstitutions(exam.admission);
                            this.signedExams.push(exam);
                        }
                    })
                    if(this.signedExams.length === 0){
                        this.noExams = true
                    }
                    this.$f7.dialog.close();
                }).catch((error) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, error.code)
                });
            }).catch((error) => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code)
            });
        },

        showInstitutionFilter() {
            this.$f7.popover.open('.institutions-filter', '.institution-button');
        },

        selectInstitution(institution, event) {
            if(event.target.checked){
                for(let i = 0; i < this.signedExams.length; i++){
                    const exam = this.signedExams[i];
                    if(institution.id){
                        if(exam.admission.institution_id === institution.id) {
                            this.filteredExams.push(exam);
                            continue
                        }
                    }
                    (exam.admission.institution === institution.name) && this.filteredExams.push(exam);
                }
            }
            else{
                this.filteredExams = this.filteredExams.filter((exam) => {
                    if(institution.id && exam.admission.institution_id){
                        return (exam.admission.institution_id !== institution.id)
                    }
                    return (exam.admission.institution !== institution.name)
                })
            }
        },

        printReport(download, exam){
            this.ViewAndPrint(download, exam.admission, [exam.id], exam.admission.patient.id);
        },

        showOrderDetails(exam){
            this.$f7.dialog.preloader('Cargando datos...')
            let samplePromises = Object.keys(exam.admission.samples).map((sampleId) => {
                return this.$firebase.database().ref('admittedSamples/' + sampleId)
                    .once('value')
            })
            Promise.all(samplePromises).then((values) => {
                values.forEach((value) => {
                    const sampleLocationId = value.val().where;
                    if(!sampleLocationId){
                        exam.admission.samples[value.key].where = 'En Admisión';
                    }
                    else{
                        const location = this.sections.find((section) => {
                            return section.id == sampleLocationId
                        });
			            exam.admission.samples[value.key].where = 'En ' + location.name;
                    }
                })
                const selectedOrder = {
                    admisionid: exam.admissionId,
                    examen: exam,
                    patinfo: exam.admission.patient,
                    samplinfo: exam.admission.samples,
                };
                this.$store.commit('setObjTo', {what: 'focused_orden', to: selectedOrder});
                this.$f7.dialog.close();
                this.$f7.views.main.router.navigate('/ordendetrabajo/');
            }).catch((error) => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code)
            });
		},
    },

    mounted() {
        this.getInstitutions();
    }
}
</script>

<style scoped>

.avatar{
	width: 50px;
}

.calendar-icon{
    font-size: 50px;
    color: gray;
}

.institution-list{
    font-size: 14px;
}

</style>