

import axios from'axios';
export const pdfermixin = {
    methods: {
        ViewAndPrint(forcedownload, admision, examsToPrint, patientid) {
            let preParePrinter = {
                admision: admision,
                examsToPrint: examsToPrint,
            };
            console.log(preParePrinter);
            let patinfox = admision.patient || this.patinfo || {};
            let filname =
                (patinfox.primerApellido || " ") +
                (patinfox.segundoApellido ? "_" + patinfox.segundoApellido : "") +
                patinfox.nombres;
            if (forcedownload) {
                preParePrinter.forceDownload = true;
            }
            preParePrinter.prod = this.$store.state.env === "production" ? true : false;
            console.log(preParePrinter);
            //return false;
            this.$f7.dialog.preloader("Generando PDF");
            this.$firebase
                .auth()
                .currentUser.getIdToken()
                .then((idToken) => {
                    // http://localhost:5001/inmunocel-portal/us-central1/secapi/printer/
                    // https://us-central1-inmunocel-portal.cloudfunctions.net/secapi/printer/

                    return axios.post("https://us-central1-inmunocel-portal.cloudfunctions.net/secapi/printer/" + patientid, preParePrinter, {
                        headers: {
                            Authorization: "Bearer " + idToken,
                            "Content-Type": "application/json",
                        },
                        responseType: "blob",
                    });
                })
                .then((response) => {
                    this.$f7.dialog.close();
                    console.log(response);
                    let url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    if (forcedownload) {
                        //var blob = new Blob([response.data], {type: 'application/pdf'});
                        let a = document.createElement("a");
                        a.style = "display: none";
                        document.body.appendChild(a);
                        // let url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.classList.add("external");
                        a.download = filname + ".pdf";
                        a.click();
                        window.URL.revokeObjectURL(url);
                    } else {
                        this.$store.commit("setWhatTo", { what: "focusedPDF", to: url });
                        this.$f7.views.main.router.navigate("/pdfviewer/");
                    }
                })
                .catch((error) => {
                    this.$f7.dialog.close();
                    console.log(error);
                });
        },
    },
};
