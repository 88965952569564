<template>
    <div>
        <f7-list no-hairlines class="no-margin-bottom margin-top-half">
            <f7-list-item divider title="Resultados"></f7-list-item>
        </f7-list>
        <f7-row>
            <f7-col width="100" medium="40">
                <f7-block-title class="margin-vertical-half">
                    Seleccione el o los tipos de resultados que debe tener el examen:
                </f7-block-title>
                <f7-list no-hairlines class="no-margin-bottom margin-top-half">
                    <f7-list-item
                        checkbox 
                        title="Cualitativo"
                        value="cualitativo"
                        :checked="resultsTypes.includes('cualitativo')"
                        @change="selectResultType($event)"
                        >
                    </f7-list-item>
                    <f7-list-item
                        checkbox 
                        title="Cuantitativo"
                        value="cuantitativo"
                        :checked="resultsTypes.includes('cuantitativo')"
                        @change="selectResultType($event)"
                        >
                    </f7-list-item>
                </f7-list>
            </f7-col>
            <f7-col width="0" medium="10">
            </f7-col>
            <f7-col width="100" medium="50" v-if="resultsTypes.includes('cualitativo')">
                <f7-block-title class="margin-top-half no-margin-bottom">
                    Ingrese los valores posibles (sólo cualitativos): 
                </f7-block-title>
                <f7-row>
                    <f7-col width="65">
                        <div class="list no-hairlines no-margin-bottom margin-top-half">
                            <ul>
                                <li :class="{
                                        'item-content': true, 
                                        'item-input':true, 
                                        'item-input-outline': true,
                                        'item-input-with-error-message': invalidValue, 
                                        'item-input-invalid': invalidValue 
                                    }"
                                >
                                    <div class="item-inner">
                                        <div class="item-input-wrap">
                                            <input 
                                                type="text" 
                                                placeholder="Ej: Positivo, Negativo"
                                                :value="newValue"
                                                @input="inputNewValue($event)"
                                                @keyup="$event.keyCode === 13 && addValue()"
                                            >
                                            <div v-if="invalidValue" class="item-input-error-message">Debe indicar un valor</div>
                                            <span class="input-clear-button"></span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div> 
                    </f7-col>
                    <f7-col width="35">
                        <f7-button
                            class="margin-top margin-right"
                            outline
                            small
                            @click="addValue()"
                        >
                            Agregar
                        </f7-button>
                    </f7-col>
                </f7-row>
                <f7-list class="no-margin-bottom margin-top-half margin-left">
                    <f7-list-item
                        v-for="(value, index) in qualitativeValues"
                        :key="index"
                        :title="value"
                    >
                        <f7-link 
                            icon-material="cancel" 
                            tooltip="Eliminar" 
                            @click="deleteValue(index)"
                        />
                    </f7-list-item>
                </f7-list>
            </f7-col>
        </f7-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            resultsTypes: [],
            newValue: '',
            qualitativeValues: [],
            invalidValue: false
        }
    },

    computed: {
        ...mapState(['selectedExam', 'examResults'])
    },

    methods: {
        selectResultType(event) {
            if(event.target.checked){
                this.resultsTypes.push(event.target.value);
                this.sendResultsInfo();
            }
            else{
                const index = this.resultsTypes.indexOf(event.target.value);
                this.resultsTypes.splice(index, 1);
                this.sendResultsInfo();
            }
        },

        inputNewValue(event) {
            this.invalidValue = false;
            this.newValue = event.target.value;
        },

        addValue(){
            if(!this.newValue){
                this.invalidValue = true;
                return
            }
            else{
                this.qualitativeValues.push(this.newValue);
                this.sendResultsInfo();
                this.newValue = '';
            }
        },

        addWithEnterKey(){
            if (event.keyCode === 13){
                this.searchRut();
            }
        },

        deleteValue(index){
            this.qualitativeValues.splice(index, 1);
            this.sendResultsInfo();
        },

        sendResultsInfo() {
            const resultsInfo = {
                resultsTypes: this.resultsTypes,
                qualitativeValues: this.qualitativeValues
            };
            this.$emit('addResultsInfo', resultsInfo);
        }
    },

    mounted() {
        if(this.selectedExam){
            this.resultsTypes = this.examResults.map((result) => {
                if(result.name === 'cualitativo'){
                    this.qualitativeValues = Object.keys(result.values)
                }
                return result.name
            });
            this.sendResultsInfo();
        }
    }
}
</script>