<template>
    <tr
        v-if="institution_id && ((covidActiveFilters.length > 0 && covidActiveFilters.includes(institution_id)) || covidActiveFilters.length == 0)"
        class="chelopaddingx"
    >
        <td class="checkbox-cell" :class="{ 'skeleton-text skeleton-effect-blink': !patinfo }">
            <f7-checkbox :value="ordentrabajo.id" :checked="!!existoEnSeleccion" @change="checkFruits"></f7-checkbox>
        </td>
        <td class="label-cell">{{ patinfo ? lastNamesAndNames(patinfo) : "Apellido1 Apellido2, Nombre1 Nombre2" }}</td>
        <td class="label-cell">{{ patinfo ? formatId(patinfo) : "11.111.111-1" }} {{ patinfo ? patinfo.fechaNacimiento : "11/11/1111" }}</td>
        <td class="label-cell">{{ institux }} {{ admissionDate ? $moment(admissionDate).format("llll") : " xxxxx de xxx a las xx:xx" }}</td>
        <td class="label-cell">
            <span v-for="sample in samplinfo" :key="sample.id">
                {{ sample.name }} (
                <b>#{{ sample.id }}</b>
                )
            </span>
        </td>
        <td class="label-cell no-padding">
            <f7-list class="no-margin">
                <f7-list-input
                    type="select"
                    placeholder="Seleccione..."
                    :value="structured_result.method_id"
                    @change="structured_result.method_id = parseInt($event.target.value)"
                >
                    <option v-for="metodo in covidMethods" :key="metodo.id" :value="metodo.id" :selected="metodo.id === 25">{{ metodo.nombre }}</option>
                </f7-list-input>
            </f7-list>
        </td>
        <td class="label-cell no-padding">
            <f7-list class="no-margin">
                <f7-list-input
                    type="select"
                    :value="structured_result.result"
                    @change="structured_result.result = $event.target.value"
                    :class="{
                        nodetected: structured_result.result == 'No detectado' || structured_result.result == 'Negativo',
                        detectado: structured_result.result == 'Detectado' || structured_result.result == 'Positivo',
                        inconclus: structured_result.result == 'No concluyente',
                    }"
                >
                    <option value="Detectado">Detectado</option>
                    <option value="No detectado">No detectado</option>
                    <option value="No concluyente">No concluyente</option>
                    <option value="Positivo">Positivo</option>
                    <option value="Negativo">Negativo</option>
                </f7-list-input>
            </f7-list>
        </td>
        <td class="actions-cell">
            <f7-link @click="firmaMeEsteExamen()" icon-material="rate_review"></f7-link>
        </td>
    </tr>
</template>

<script>
import { mapState } from "vuex";
import { format } from "rut.js";
import { paxhelpersmixin } from "../mixins/paxhelpersmixin";

export default {
    name: "filaCovidBatch",
    props: ["ordentrabajo"],
    mixins: [paxhelpersmixin],

    data() {
        return {
            patinfo: null,
            samplinfo: null,
            admissionDate: null,
            institux: null,
            institution_id: null,
            structured_result: {
                method: null,
                method_id: 25,
                result: "Negativo",
                obs: "",
                report_type: "Informe final",
            },
        };
    },
    computed: {
        ...mapState(["loadingAvatar", "covidMethods", "covidBatchToSign", "covidActiveFilters"]),
        existoEnSeleccion() {
            return this.$store.getters.getFromArrayByProVal(this.ordentrabajo.id, "covidBatchToSign", "id");
        },
    },
    methods: {
        checkFruits(event) {
            let value = event.target.value;
            if (event.target.checked && !this.existoEnSeleccion) {
                this.$store.commit("pushToArray", { array: "covidBatchToSign", value: this.ordentrabajo });
            } else {
                this.$store.commit("removeFromArray", { array: "covidBatchToSign", attr: "id", value: this.ordentrabajo.id });
            }
        },
        getAdmissionInfo() {
            return this.$firebase
                .database()
                .ref("admissions/" + this.ordentrabajo.admissionId)
                .once("value")
                .then((admisnap) => {
                    let admission = admisnap.val();
                    this.patinfo = admission.patient;
                    this.samplinfo = admission.samples;
                    this.admissionDate = admission.creationDate;
                    this.institux = admission.institution;
                    //	this.$slots//instituxToFilter
                    if (!admission.institution_id) {
                        this.institution_id = admission.institution;
                    } else {
                        this.institution_id = admission.institution_id;
                    }
                    //institution_id
                    console.log(admission);
                    //	console.log({institux:this.institux,patinfo:this.patinfo,samplinfo:this.samplinfo,admissionDate:this.admissionDate});
                    this.$store.commit("populateInstituxToFilter", {
                        id: this.institution_id,
                        value: {
                            id: this.institution_id,
                            name: this.institux,
                        },
                    });
                })
                .catch((error) => {
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
        firmaMeEsteExamen() {
            this.$emit("firmaesto", this.ordentrabajo);
            return false;
        },
    },
    mounted() {
        console.log(this.ordentrabajo);
        this.getAdmissionInfo();
    },
    beforeDestroy() {},
};
</script>
<style>
.data-table .chelopaddingx td.label-cell {
    padding: 0px 4px;
}

.data-table .chelopaddingx td.actions-cell {
    padding: 0px 10px 0px 4px;
}

.nodetected {
    background: #c5ffca;
}
.inconclus {
    background: #fbff65;
}
.detectado {
    background: #ffd2d2;
}
</style>
