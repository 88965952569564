<template>
    <f7-page name="samples">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title >Muestras</f7-nav-title>
            <f7-nav-right>
                <f7-link icon-material="refresh" tooltip="Recargar Lista" @click="getSamples"></f7-link>
                <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-material="search"></f7-link>
            </f7-nav-right>
            <f7-searchbar
                class="searchbar-demo"
                expandable
                remove-diacritics
                search-container=".search-list"
                search-in=".item-title"
                :disable-button="!$theme.aurora"
                placeholder="Buscar muestra"
            ></f7-searchbar>
        </f7-navbar>

        <f7-list class="searchbar-not-found">
            <f7-list-item title="No hay coincidencias"></f7-list-item>
        </f7-list>

         <f7-list v-if="noSamples" class="searchbar-not-found">
            <f7-list-item title="No hay muestras guardados"></f7-list-item>
        </f7-list>

        <f7-list class="no-margin-top search-list searchbar-found" media-list>
            <f7-list-item 
                v-for="(sample, index) in $store.getters.sortArray('allSamples')"
                :key="index"
                :title="sample.name"
                link="#"
                :class="{selected:(selectedSample.id === sample.id)}"
                @click="viewSampleDetail(sample)"
            ></f7-list-item>
        </f7-list>

        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close @click="redirectNewSample" label="Crear nueva muestra">
                    <f7-icon material="playlist_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>
    </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    data(){
        return {
            noSamples: false
        }
    },
    computed: {
        ...mapState(['allSamples', 'selectedSample']),
    },
    methods: {
         ...mapActions(['getAPIInfo']),

        getSamples(){
            this.$f7.dialog.preloader('Cargando');
            this.$firebase.auth().currentUser.getIdToken()
            .then(idToken => {
                return this.getAPIInfo({url: 'samples/list', userToken: idToken})
                })
            .then(resp => {
                let enabledSamples = resp.data.data.filter((sample) => sample.enabled === 1)
                this.noSamples = false;
                this.$store.commit('setWhatTo', {what: 'allSamples', to: enabledSamples});
                this.$f7.dialog.close(); 
            })
            .catch((err) => {
                this.$f7.dialog.close();
                if(err.response.status !== 404){
                    this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error');
                }
                else{
                    this.noSamples = true;
                }
            })
        },

        viewSampleDetail(sample){
            this.$store.commit('setWhatTo',{what: 'selectedSample', to: sample});
            if(this.$f7.views.main.router.currentRoute.url === '/samples/'){
                this.$f7.views.main.router.navigate('/sample/');
            }
            else{
                this.$f7.views.main.router.navigate('/sample/', {reloadCurrent: true});
            }
        },
        redirectNewSample(){
            this.$store.commit('setWhatTo',{what:'selectedSample', to:{} });
            if(this.$f7.views.main.router.currentRoute.url === '/samples/'){
                this.$f7.views.main.router.navigate('/new-sample/');
                }
            else{
                this.$f7.views.main.router.navigate('/new-sample/', {reloadCurrent: true});
            }
        },
    },
    mounted(){
        if(!this.allSamples){
            this.getSamples();
        }
    }
}
</script>

<style scoped>
    .selected{
        border-left: 7px solid #36b5aa;
        color: #2d968c;
        background-color: #f3f3f3;
    }
</style> 