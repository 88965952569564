<template>
    <f7-page class="backgris" name="pendientesfirma">
    <f7-navbar title="Exámenes ingresados a" :subtitle="focused_section.name" back-link>
        <f7-nav-right>
      <f7-link class="searchbar-enable" data-searchbar=".searchbar-pendings" icon-material="search"></f7-link>
    </f7-nav-right>
    <f7-searchbar
      class="searchbar-pendings"
      expandable
      placeholder="Búsqueda por paciente,examen o muestra"
     search-container=".unexm"
        search-in=".item-title, .item-subtitle, .item-text"
    ></f7-searchbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
        <f7-list-item title="Sin resultados"></f7-list-item>
    </f7-list>
   	<f7-list class="no-margin unexm" media-list >
           <ul>
          <pend-ingreso v-for="noingressd in oppositeexsConMustrsPendIngrASeccion" :key="noingressd.id" :condetail="true" :ordentrabajo="noingressd"></pend-ingreso>
           </ul>
    </f7-list>
    </f7-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import pendIngreso from '../components/pendIngreso.vue';

export default {
     components: {
            pendIngreso
        },
    data(){
        return{
            allUnsigned:[]
        }
    },

    watch:{
        focused_section(newval){
         //    this.traedata();
        }
    },
      computed: {
        ...mapState(['focused_section']),
        ...mapGetters(['oppositeexsConMustrsPendIngrASeccion'])
    },

    methods: {
       
    },
    mounted(){
     
    }

 

}
</script>
<style>
.unexm, .unexm ul{
	padding:0px!important;
}
.unexm .item-media img{
	width: 50px;
}
.unexm .item-media {
	text-align: center;
	font-size: 14px;
}

</style>