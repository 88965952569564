<template>
    <f7-page name="rejectionReasonDetails">
        <f7-navbar 
            title="Detalles motivo de rechazo" 
            back-link
            back-link-url="/rejections/" 
            back-link-force
        >
        </f7-navbar>
        <f7-block-title v-if="!selectedReason.name">
            Seleccione algún motivo de rechazo
        </f7-block-title>
      
        <f7-block v-else>
        <f7-card class="demo-card-header-pic">
            <f7-card-header>
                <f7-block-title medium> {{selectedReason.name}} </f7-block-title>
            </f7-card-header>
            <f7-card-content>
                <p>{{selectedReason.description}}</p>
            </f7-card-content>
            <f7-card-footer>
                <f7-button 
                    fill 
                    small 
                    round 
                    icon-material="edit" 
                    @click="goToEdit"
                    >Editar
                </f7-button>
                <f7-button 
                    fill 
                    small 
                    round 
                    icon-material="delete" 
                    color="red"
                    @click="deleteReason"
                    >Eliminar
                </f7-button>
            </f7-card-footer>
        </f7-card>
        </f7-block>

    </f7-page>    
</template>
<script>
import { mapState } from 'vuex';

export default {
    data(){
        return {
        }
    },

    computed: {
        ...mapState(['selectedReason', 'profile_userInfo'])
    },

    methods: {
        goToEdit(){
            this.$f7.views.main.router.navigate('/new-rejection/');
        },
        deleteReason(){
            this.$f7.dialog.confirm('¿Está seguro que desea eliminar el motivo de rechazo?', 'Eliminar', 
                () => {
                    this.$store.commit('updateObjectAttribute', 
                        {
                            obj: 'selectedReason', 
                            attr: 'enabled',
                            value: null
                        }
                    );
                    this.$firebase.database().ref('rejections/' + this.selectedReason.id).set(this.selectedReason)
                    .then(()=>{
                        //Se agrega información al nodo log
                        let info = {
                            who: this.profile_userInfo.uid,
                            wht: 'delete-rejection',
                            whn: this.$moment().unix(),    
                            dls: 'Elimina motivo de rechazo',
                            pth: 'rejections/' + this.selectedReason.id,
                        }
                        let id = this.$firebase.database().ref('/log').push().key;
                        this.$firebase.database().ref('log/' + id).set(info)
                            .catch((err)=>{console.log(err.message)})
                            
                        //Se elimina motivo de rechazo del store
                        this.$store.commit('deleteObjectAttribute', {
                            obj: 'allReasons', 
                            attr: this.selectedReason.id
                        });
                        this.$store.commit('setWhatTo', {what: 'selectedReason', to: {} });
                        this.$f7.toast.create({text: 'Motivo de rechazo eliminado', closeTimeout: 2500}).open();
                    })
                    .catch((err)=>{
                        this.$f7.dialog.alert(err.message, err.code);
                    })
                }
            );
        }
    }
}
</script>