<template>
    <f7-page class="backgris" name="resultado_paciente" @page:beforein="loadSusAdmisiones()">
        <f7-navbar v-if="focusedPacienteForResults.id"  :title="lastNamesAndNames(focusedPacienteForResults)" back-link="Back"></f7-navbar>

  <template v-if="focusedPacienteForResults.id">
         <f7-list  class="paxspreresult" media-list inset>
            <f7-list-item
            :title="lastNamesAndNames(focusedPacienteForResults)"
            :subtitle="formatId(focusedPacienteForResults)"
            :text="focusedPacienteForResults.nacionalidad"
            :after="suage(focusedPacienteForResults.fechaNacimiento)"
            ><f7-icon slot="subtitle" :class="{fem: focusedPacienteForResults.sexo=='Femenino'}" material="brightness_1"></f7-icon> 
            </f7-list-item>
        </f7-list>

        <result-admission   v-for="unaadmision in admissions" :key="unaadmision.id" :admision="unaadmision"></result-admission>
  </template>
    </f7-page>
</template>
<script>
import { mapState } from 'vuex';
import {paxhelpersmixin} from '../mixins/paxhelpersmixin';
import resultAdmission from '../components/resultAdmission.vue';

export default {
      mixins: [paxhelpersmixin],
        components: {
            resultAdmission
        },
    data(){
        return {
            admissions:[]
        }
    },
    mounted(){
        console.log('paciente',this.focusedPacienteForResults);
    },
    computed: {
        ...mapState(['focusedPacienteForResults'])
    },
  watch:{
   focusedPacienteForResults(){
     this.loadSusAdmisiones();
   }
  },
  //  beforeDestroy(){
  //       console.log('apagando')
  //       this.$store.commit('setObjTo',{'what':'focusedPacienteForResults',to:{}});
  //   },
    methods: {
        loadSusAdmisiones(){
          if(this.focusedPacienteForResults.id){
            this.admissions=[];
              console.log('CARNGO INFO NUEVO Paciente EN FOCUS');
              this.$f7.dialog.preloader('Cargando admisiones...');
              //this.db.ref('pacientes/').orderByChild('identificador').equalTo(this.paciente.identificador).once('value')
              return this.$firebase.database().ref('admissions').orderByChild('patient/id').equalTo(this.focusedPacienteForResults.id).once('value').then(snaps=>{
                let admissions=snaps.val() || {};
              console.log('admisiones',admissions);
              this.admissions=Object.values(admissions);
                this.$f7.dialog.close();
              }).catch(error=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code);
              })
          }
    }
    }
}
</script>
<style></style>