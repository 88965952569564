<template>
    <f7-page name="institutions" @page:beforeremove="removeSelectedInstitution">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title >Instituciones</f7-nav-title>
            <f7-nav-right>
                <f7-link icon-material="refresh" tooltip="Recargar Lista" @click="getInstitutions"></f7-link>
                <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-material="search"></f7-link>
            </f7-nav-right>
            <f7-searchbar
                class="searchbar-demo"
                expandable
                remove-diacritics
                search-container=".search-list"
                search-in=".item-title"
                :disable-button="!$theme.aurora"
                placeholder="Buscar institución"
            ></f7-searchbar>
        </f7-navbar>

        <f7-list class="searchbar-not-found">
            <f7-list-item title="No hay coincidencias"></f7-list-item>
        </f7-list>

        <f7-list class="no-margin-top search-list searchbar-found" media-list>
            <f7-list-item 
                v-for="(institution, index) in $store.getters.objToArray('allInstitutions')" 
                :key="index"
                :title="institution.name"
                :subtitle="institution.address"
                link="#"
                :class="{selected:(selectedInstitution.id === institution.id)}"
                @click="viewInstitutionDetail(institution)"
            ></f7-list-item>
        </f7-list>

        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close @click="goToNewInstitution()" label="Crear nueva institución">
                    <f7-icon material="playlist_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>
    </f7-page>
</template>

<script>

import { mapState, mapGetters } from 'vuex';

export default {

    data(){
        return {
        }
    },

    computed: {
        ...mapState(['allInstitutions', 'selectedInstitution']),
    },

    methods: {
        viewInstitutionDetail(institution){
            this.$store.commit('setWhatTo',{what:'selectedInstitution', to:institution});
            this.$f7.views.main.router.navigate('/institution/');
        },

        goToNewInstitution(){
            this.$store.commit('setWhatTo',{what:'selectedInstitution', to:{} });
            if(this.$f7.views.main.router.currentRoute.url === '/institutions/'){
                this.$f7.views.main.router.navigate('/new-institution/');
                }
            else{
                this.$f7.views.main.router.navigate('/new-institution/', {reloadCurrent: true});
            }
        },

        getInstitutions(){
            this.$f7.dialog.preloader('Cargando');
            this.$firebase.database().ref('institutions/').orderByChild('enabled').equalTo(true).once('value')
                .then((snapshot) => {
                    this.$store.commit('setObjTo', {what: 'allInstitutions', to: snapshot.val()})
                    this.$f7.dialog.close();
                })
                .catch((err)=>{
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, err.code);
                });
        },

        removeSelectedInstitution(){
            this.$store.commit('setWhatTo', {what: 'selectedInstitution', to: {} });
        }
    },

    mounted(){
        if(!this.allInstitutions){
            this.getInstitutions();
        }
    },
}
</script>

<style scoped>
    .selected{
        border-left: 7px solid #36b5aa;
        color: #2d968c;
        background-color: #f3f3f3;
    }
</style>