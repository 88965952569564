<template>
    <f7-page class="backgris" name="ordenTrabajo">
        <f7-navbar :title="orden.name" subtitle="orden de trabajo" back-link></f7-navbar>

        <f7-row>
            <f7-col width="25">
                <f7-block class="bg-color-white padding">
                    <img
                        class="innerpaxavatar"
                        :src="
                            patinfo.sexo == 'Masculino' ? '../static/img/masc.jpg' : '../static/img/feme.jpg'
                        "
                        alt="avatar"
                    />
                    <f7-list class="paxinfo no-margin">
                        <f7-list-item
                            header="Apellidos"
                            :title="
                                (patinfo.primerApellido || '-') +
                                    ' ' +
                                    (patinfo.segundoApellido ? patinfo.segundoApellido : '')
                            "
                        ></f7-list-item>
                        <f7-list-item header="Nombres" :title="patinfo.nombres"></f7-list-item>
                        <f7-list-item
                            :header="patinfo.tipoIdentificador"
                            :title="formatId(patinfo)"
                        ></f7-list-item>
                        <f7-list-item header="Nacionalidad" :title="patinfo.nacionalidad"></f7-list-item>
                        <f7-list-item
                            header="Fecha de nacimiento"
                            :title="patinfo.fechaNacimiento"
                        ></f7-list-item>
                        <f7-list-item
                            header="Teléfono"
                            :title="patinfo.telefono || 'Sin Información'"
                        ></f7-list-item>
                    </f7-list>
                    <f7-button @click="gotoEditPaxMeta()" outline>Editar datos paciente</f7-button>
                </f7-block>
                <f7-list class="bg-color-white">
                    <f7-list-item divider title="Log"></f7-list-item>
                    <f7-progressbar v-if="fecthingMembers" infinite></f7-progressbar>
                    <f7-list-item
                        v-for="(action, indx) in logexamen"
                        :key="'a' + indx"
                        :title="action.wht"
                        :footer="$moment.unix(action.whn).format('DD/MM/YYYY @ HH:mm')"
                        :header="action.who"
                    ></f7-list-item>
                </f7-list>
            </f7-col>

            <f7-col width="75">
                <f7-block class="unbloqueexamen">
                    <f7-list no-hairlines class="no-margin-top toplistin">
                        <f7-list-item :title="orden.name">
                            <f7-button
                                v-if="
                                    focused_orden.examen.firmado && misprivx.vercolas[focused_section.id].fir
                                "
                                class="margin-right"
                                slot="after"
                                fill
                                small
                                color="red"
                                icon-material="report_problem"
                                @click="reverseSignature()"
                            >
                                Revertir Firma
                            </f7-button>
                            <span slot="after">
                                {{ examStatus }}
                            </span>
                        </f7-list-item>
                    </f7-list>
                    <f7-block-title>
                        Muestras asociadas
                        <f7-preloader v-if="loadingSamplesLog" :size="13"></f7-preloader>
                    </f7-block-title>
                    <f7-row class="rowdesample" v-for="(trued, sampleid) in orden.samples" :key="sampleid">
                        <f7-col width="10">
                            <f7-button
                                @click="requestPrint(sampleid)"
                                color="blue"
                                icon-material="print"
                                fill
                                raised
                            ></f7-button>
                        </f7-col>
                        <f7-col width="90">
                            <f7-list class="no-margin" inset accordion-list>
                                <f7-list-item
                                    accordion-item
                                    :title="focused_orden.samplinfo[sampleid].name"
                                    :footer="sampleid"
                                    :after="getSampleLocation(sampleid)"
                                >
                                    <f7-icon material="location_on" slot="after"></f7-icon>
                                    <f7-accordion-content class="fondogrixsamplog">
                                        <f7-list class="sampllog" inset>
                                            <f7-list-item
                                                v-for="(log, index) in samplesLog[sampleid]"
                                                :key="index"
                                                :title="log.wht"
                                                footer=""
                                                :header="formatDate(log.whn)"
                                            >
                                                <f7-icon slot="after" material="home"></f7-icon>
                                            </f7-list-item>
                                        </f7-list>
                                    </f7-accordion-content>
                                </f7-list-item>
                            </f7-list>
                        </f7-col>
                    </f7-row>

                    <results-form :sendResults="saveChanges" />

                    <hr />
                    <f7-row class="margin-bottom">
                        <f7-col>
                            <f7-button
                                v-if="
                                    focused_orden.examen.ingresado &&
                                        !focused_orden.examen.confirmado &&
                                        misprivx.vercolas[focused_section.id].con
                                "
                                fill
                                raised
                                color="purple"
                                @click="changeStatus('confirmado', focused_orden.examen)"
                            >
                                Confirmar
                            </f7-button>
                        </f7-col>
                        <f7-col>
                            <f7-button
                                v-if="
                                    focused_orden.examen.confirmado &&
                                        !focused_orden.examen.firmado &&
                                        misprivx.vercolas[focused_section.id].fir
                                "
                                outline
                                color="purple"
                                @click="changeStatus('firmado', focused_orden.examen)"
                            >
                                Firmar
                            </f7-button>
                        </f7-col>
                    </f7-row>
                    <f7-row
                        v-if="focused_orden.examen.firmado && !misprivx.resultados"
                        class="margin-bottom text-align-center"
                    >
                        <p class="no-margin">
                            Su usuario no tiene privilegios para generar/imprimir PDFs de resultados. Un
                            usuario con permisos de gestor de privielgios debe activarselos desde el
                            mantenedor de miembros.
                        </p>
                    </f7-row>
                    <f7-row
                        v-else-if="focused_orden.examen.firmado && misprivx.resultados"
                        class="margin-bottom"
                    >
                        <f7-link
                            @click="
                                ViewAndPrint(
                                    true,
                                    { id: focused_orden.admisionid },
                                    [focused_orden.examen.id],
                                    patinfo.id
                                )
                            "
                            disabled
                            icon-material="assignment_returned"
                        >
                            Descargar
                        </f7-link>
                        <f7-link
                            @click="
                                ViewAndPrint(
                                    false,
                                    { id: focused_orden.admisionid },
                                    [focused_orden.examen.id],
                                    patinfo.id
                                )
                            "
                            icon-material="printer"
                        >
                            Ver e Imprimir
                        </f7-link>
                    </f7-row>
                </f7-block>
            </f7-col>
        </f7-row>
    </f7-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { format } from "rut.js";
import { printermixin } from "../mixins/printermixin";
import { paxhelpersmixin } from "../mixins/paxhelpersmixin";
import { pdfermixin } from "../mixins/pdfermixin";
import { saveInfoMixin } from "../mixins/save-info-mixin";
import ResultsForm from "../components/results-form";

export default {
    mixins: [printermixin, paxhelpersmixin, pdfermixin, saveInfoMixin],

    components: {
        ResultsForm,
    },

    data: function() {
        return {
            fecthingMembers: false, //paraelInfiniteprogressbarIndicator,
            loadingSamplesLog: false, //paraelInfiniteprogressbarIndicator,
            loadingExamsResults: false,
            observableLogExam: null,
            logexamen: [],
            free_result: null,
            structured_result: {
                method: null,
                result: null,
                obs: null,
                report_type: null,
            },
            now: "",
            samplesId: [],
            samplesLog: {},
        };
    },

    computed: {
        ...mapState([
            "focused_orden",
            "loadingAvatar",
            "profile_userInfo",
            "sections",
            "misprivx",
            "focused_section",
        ]),

        ...mapGetters(["allMembers"]),

        patinfo() {
            return this.focused_orden.patinfo;
        },

        orden() {
            return this.focused_orden.examen;
        },

        examStatus() {
            if (this.focused_orden.examen.firmado) {
                return "Firmado";
            } else if (this.focused_orden.examen.confirmado) {
                return "Confirmado";
            } else if (this.focused_orden.examen.ingresado) {
                return "Ingresado";
            } else {
                return "Admitido";
            }
        },
    },

    watch: {},

    methods: {
        ...mapActions(["getAPIInfo"]),

        gotoEditPaxMeta() {
            //todo setear patienfocuse
            this.$store.commit("setObjTo", { what: "pacienteSeleccionado", to: this.patinfo });
            this.$f7.views.main.router.navigate("/editPatientMeta/" + this.focused_orden.admisionid);
        },

        getSampleLog() {
            this.loadingSamplesLog = true;
            this.samplesId = Object.keys(this.focused_orden.examen.samples);
            const logRequests = this.samplesId.map((id) => {
                this.samplesLog[id] = {};
                return this.$firebase
                    .database()
                    .ref("samples_log/" + id)
                    .once("value");
            });
            return Promise.all(logRequests)
                .then((responses) => {
                    responses.forEach((resp) => {
                        this.samplesLog[resp.key] = Object.assign({}, Object.values(resp.val()));
                    });
                    this.loadingSamplesLog = false;
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, "Ops, ha ocurrido un error");
                    this.loadingSamplesLog = false;
                });
        },

        formatDate(date) {
            return this.$moment.unix(date).format("LLL");
        },

        requestPrint(sampleid) {
            let sampleinfo = this.focused_orden.samplinfo[sampleid];
            //patinfo
            //identificador
            //this.patinfo.nombres,this.patinfo.primerApellido,this.patinfo.segundoApellido
            let nameString = this.lastNamesAndNames(this.patinfo);
            this.makelabel(
                nameString,
                this.formatId(this.patinfo),
                this.patinfo.fechaNacimiento,
                this.suageEtiquetas(this.patinfo.fechaNacimiento),
                sampleinfo.id,
                sampleinfo.name,
                sampleinfo.dateTime
            );
        },

        getSampleLocation(sampleId) {
            if (!this.focused_orden.samplinfo[sampleId].where) {
                return "En Admisión";
            } else if (Number.isInteger(this.focused_orden.samplinfo[sampleId].where)) {
                const location = this.sections.find(
                    (section) => section.id === this.focused_orden.samplinfo[sampleId].where
                );
                return "En " + location.name;
            } else {
                return this.focused_orden.samplinfo[sampleId].where;
            }
        },

        saveInLog(action, exam) {
            const logInfo = {
                who: this.profile_userInfo.uid,
                whn: this.now,
                wht: action,
            };
            const logId = this.$firebase
                .database()
                .ref("exams_log/" + exam.id)
                .push().key;
            this.$firebase
                .database()
                .ref(`exams_log/${exam.id}/${logId}`)
                .set(logInfo)
                .then(() => {
                    this.$f7.dialog.close();
                    this.$f7.toast
                        .create({
                            text: "Los cambios se han guardado con éxito",
                            closeTimeout: 2000,
                            destroyOnClose: true,
                        })
                        .open();
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, "Ops, ha ocurrido un error");
                });
        },

        updateEnArraydelStore(prop, valox) {
            let objExistenteEnelArray = this.$store.getters.getFromArrayByProVal(
                this.focused_orden.examen.id,
                "exmsPendFirma",
                "id"
            );
            if (objExistenteEnelArray) {
                let loks = {};
                loks[prop] = valox;
                let neolocal = Object.assign({}, objExistenteEnelArray, loks);
                this.$store.commit("replaceInArray", {
                    array: "exmsPendFirma",
                    attr: "id",
                    value: this.focused_orden.examen.id,
                    newValue: neolocal,
                });
            }
        },

        changeStatus(status, exam) {
            let resultType = null;
            let results = null;
            this.$f7.dialog.preloader("Guardando");
            if (status === "firmado") {
                //si esta firmado, eliminar del arreglo de 'examenes pendiente firma' (si es que existe)
                this.$store.commit("removeFromArray", { array: "exmsPendFirma", attr: "id", value: exam.id });
            } else if (status === "confirmado") {
                this.updateEnArraydelStore("confirmado", this.now);
            }
            const changesInfo = {
                attributeNames: [status],
                reverse: false,
                logDetail: "Examen " + status,
                exam,
                samplesId: this.samplesId,
            };
            this.changeExamStatus(changesInfo);
        },

        reverseSignature() {
            this.changeExamStatus({
                attributeNames: ["firmado"],
                reverse: true,
                logDetail: "Revierte firma",
                exam: this.focused_orden.examen,
                samplesId: this.samplesId,
            });
        },

        saveChanges(examResult) {
            const exam = this.focused_orden.examen;
            this.now = this.$moment().unix();
            this.updateEnArraydelStore("ingresado", this.now);
            this.$f7.dialog.preloader("Guardando");
            this.$firebase
                .database()
                .ref("exams_results/" + exam.id)
                .set(examResult)
                .then(() => {
                    return this.$firebase
                        .database()
                        .ref(`admittedExams/${exam.section_id}/${exam.id}/ingresado`)
                        .set(this.now);
                })
                .then(() => {
                    this.$f7.dialog.close();
                    let editedExam = { ...exam, ingresado: this.now };
                    if (!exam.ingresado) {
                        this.saveInLog("Ingresa resultado", exam);
                        this.$store.commit("updateObjectAttribute", {
                            obj: "focused_orden",
                            attr: "examen",
                            value: editedExam,
                        });
                    } else {
                        this.saveInLog("Reingresa resultado", editedExam);
                        if (exam.confirmado || exam.firmado) {
                            let attributeNames = ["confirmado"];
                            let logDetail = "Revierte confirmación";
                            if (exam.firmado) {
                                attributeNames.push("firmado");
                                logDetail = "Revierte firma y confirmación";
                            }
                            this.changeExamStatus({
                                attributeNames,
                                reverse: true,
                                logDetail,
                                exam,
                                samplesId: this.samplesId,
                            });
                        }
                    }
                })
                .catch((err) => {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(err.message, "Ops, ha ocurrido un error");
                });
        },

        encenderObservableLog() {
            let iniprom = [];
            if (this.allMembers.length == 0) {
                //antes de encender el log, llamemos a members para tener los autores
                this.fecthingMembers = true;
                let mymembers = this.$firebase.functions().httpsCallable("mymembers");
                iniprom.push(mymembers({ prod: this.$store.state.env === "production" ? true : false }));
            } else {
                iniprom.push(false);
            }
            return Promise.all(iniprom)
                .then((results) => {
                    if (this.allMembers.length == 0 && results[0]) {
                        let allusers = results[0].data;
                        this.$store.commit("setWhatTo", {
                            what: "allMembers",
                            to: allusers.allMyUsers || [],
                        });
                    }
                    this.fecthingMembers = false;
                    //ahora encender objservable a log
                    this.observableLogExam = this.$firebase
                        .database()
                        .ref("exams_log/" + this.focused_orden.examen.id);
                    this.observableLogExam.on("child_added", (data) => {
                        let olog = data.val();
                        let potmem = this.$store.getters.getFromArrayByProVal(olog.who, "allMembers", "uid");
                        if (potmem) {
                            olog.who = potmem.displayName;
                        }
                        this.logexamen.push(olog);
                    });
                })
                .catch((err) => {
                    this.fecthingMembers = false;
                    this.$f7.dialog.alert(err.message, "Ops, ha ocurrido un error");
                });
        },
    },

    mounted() {
        this.getSampleLog();
        console.log("FOCUSED ODRED", this.focused_orden);
        this.encenderObservableLog();
    },

    beforeDestroy() {
        if (this.observableLogExam) {
            this.observableLogExam.off();
        }
    },
};
</script>

<style>
.innerpaxavatar {
    width: 100%;
}
.paxinfo.list .item-header {
    color: #808080;
}
.paxinfo.list .item-inner {
    padding-bottom: 3px;
    padding-top: 3px;
}
.paxinfo.list .item-inner:after,
.paxinfo.list ul:after,
.paxinfo.list ul:before {
    background-color: #fff;
}
.unbloqueexamen {
    background: #e2e2e2;
    border: 1px solid #afafaf;
    border-radius: 13px;
    margin-top: 19px;
    margin-right: 12px;
}
.toplistin {
    margin-left: -16px;
    margin-right: -16px;
}
.toplistin ul {
    border-radius: 13px 13px 0px 0px;
}
.toplistin .item-title {
    font-weight: bold;
}
.sampllog .item-media .icon {
    margin: 0 auto;
}
.sampllog,
.sampllog ul,
.fondogrixsamplog {
    background: #efefef !important;
}
.rowdesample {
    border-bottom: 1px dotted #2096f3;
    padding-bottom: 5px;
    margin-bottom: 5px;
}
</style>
