import { mapState } from "vuex";

export const saveInfoMixin = {
    computed: {
        ...mapState(['profile_userInfo','env'])
    },

    methods: {
		saveLogInfo(ref, info){
			const id = this.$firebase.database().ref(ref).push().key;
            return this.$firebase.database().ref(ref + id).set(info)
        },
        
        showToast(text){
            this.$f7.toast.create({
                text: text, 
                closeTimeout: 1500,
                position: "center"
            }).open();
        },

        addAdmittedExam(exam, admission, what){
            let examPromises = [];
            let admittedExam = { ...exam };
            admittedExam.admissionId = admission.id;
            examPromises.push(this.$firebase.database().ref(`admittedExams/${exam.section_id}/${exam.id}`).update(admittedExam))
            const logInfo = {
                who: this.profile_userInfo.uid,
                whn: this.$moment().unix(),
                wht: 'Examen ' + what,
            };
            const logId = this.$firebase.database().ref('exams_log/' + exam.id).push().key;
            examPromises.push(this.$firebase.database().ref(`exams_log/${exam.id}/${logId}`).set(logInfo));
            return examPromises;
        },

        addAdmittedSample(sample, admission, date){
            let samplePromises = [];
            let admittedSample = {
                admissionId: admission.id,
                from: date,
                sections: {},
                exams:{},
                admissionDate: date
            };
            Object.values(admission.exams).forEach((exam) => {
                Object.values(exam.samples).forEach((examSample) => {
                    if(examSample.id === sample.id){
                        admittedSample.sections[exam.section_id] = false;
                        admittedSample.exams[exam.id] = false;
                    }
                })
            });
            samplePromises.push(this.$firebase.database().ref('admittedSamples/' + sample.id).set(admittedSample))
            const logInfo = {
                who: this.profile_userInfo.uid,
                whn: this.$moment().unix(),
                how: 'manual',
                wht: 'Admitida',
            };
            const logId =  this.$firebase.database().ref('samples_log/' + sample.id).push().key;
            samplePromises.push(this.$firebase.database().ref(`samples_log/${sample.id}/${logId}`).set(logInfo));
            return samplePromises
        },

        savePatientLog(what, patientId, admissionId = null) {
            let details = 'Agrega nuevo paciente';
            (what === 'edit-patient') && (details = 'Edita datos de paciente');
            let infoLog = {
                who: this.profile_userInfo.uid,
                wht: what,
                whn: this.$moment().unix(),   
                dls: details,
                admission_id: admissionId
            };
            const logId = this.$firebase.database().ref('patients_log/' + patientId).push().key;
            return this.$firebase.database().ref('patients_log/' + patientId + '/' + logId).set(infoLog)
        },

        saveAdmissionLog({what, details, admissionId}, patientId = null) {
            let logInfo = {
                who: this.profile_userInfo.uid,
                wht: what,
                whn: this.$moment().unix(),   
                dls: details,
                ptn_id: patientId,
            };
            const logId = this.$firebase.database().ref('admissions_log/' + admissionId).push().key;
            return this.$firebase.database().ref('admissions_log/' + admissionId + '/' + logId).set(logInfo)
        },

        saveLogExam(date, logDetail, exam) {
            const logInfo = {
                who: this.profile_userInfo.uid,
                whn: date,
                wht: logDetail,
            }
            const logId = this.$firebase.database().ref('exams_log/' + exam.id).push().key;
            this.$firebase.database().ref(`exams_log/${exam.id}/${logId}`).set(logInfo).then(() => {
                this.$f7.dialog.close();
                this.showToast('Los cambios se han guardado con éxito');
            })
            .catch((error) => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code)
            });
        },

        changeExamStatus({attributeNames, reverse, logDetail, exam, samplesId}) {
            let currentDate = this.$moment().unix();
            let newExamInfo = {};
            let requests = [];
            attributeNames.forEach((attr) => {
                let isSigned = false;
                if(reverse){
                    newExamInfo[attr] = null;
                    newExamInfo[attr + '_meta'] = null;
                }
                else{
                    newExamInfo[attr] = currentDate;
                    newExamInfo[attr + '_meta'] = {
                        who_id: this.profile_userInfo.uid,
                        who_name: this.profile_userInfo.displayName,
                    }
                    isSigned = true;
                }
                if(attr === 'firmado'){
                    requests = samplesId.map((sampleId) => {
                        return this.$firebase.database().ref(`admittedSamples/${sampleId}/exams/${exam.id}`).set(isSigned)
                    });
                }
            })
            requests.push(this.$firebase.database().ref(`admittedExams/${exam.section_id}/${exam.id}`).update(newExamInfo));
            Promise.all(requests).then(() => {
                const editedExam = {...exam, ...newExamInfo};
                attributeNames.forEach((attr) => {
                    if(attr === 'firmado' && !reverse){
                        const saveResultsData = this.$firebase.functions().httpsCallable('deepfirma');
                        saveResultsData({
                            prod: this.env === 'production' ? true : false,
                            sectionid: exam.section_id,
                            examenid: exam.id,
                        }).then(() => {
                            this.$store.commit('updateObjectAttribute', {
                                obj:'focused_orden', 
                                attr:'examen', 
                                value: editedExam 
                            });
                            this.saveLogExam(currentDate, logDetail, exam);
                            
                        }).catch((error)=>{
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(error.message, error.code);
                        })
                    }
                    else{
                        this.$store.commit('updateObjectAttribute', {
                            obj:'focused_orden', 
                            attr:'examen', 
                            value: editedExam 
                        });
                        this.saveLogExam(currentDate, logDetail, exam);
                    }
                })
            }).catch((error) => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code)
            });
        }
    }
    
}