<template>
    <f7-page name="processing-methods">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title title="Métodos de" subtitle="Procesamiento"></f7-nav-title>
            <f7-nav-right>
                <f7-link icon-material="refresh" tooltip="Recargar Lista" @click="getMethods"></f7-link>
                <f7-link class="searchbar-enable" data-searchbar=".methods-searchbar" icon-material="search"></f7-link>
            </f7-nav-right>
            <f7-searchbar
                class="methods-searchbar"
                expandable
                remove-diacritics
                search-container=".search-list"
                search-in=".item-title"
                :disable-button="!$theme.aurora"
                placeholder="Buscar método de procesamiento"
            ></f7-searchbar>
        </f7-navbar>

        <f7-list class="searchbar-not-found">
            <f7-list-item title="No hay coincidencias"></f7-list-item>
        </f7-list>

         <f7-list v-if="noMethods" class="searchbar-not-found">
            <f7-list-item title="No hay métodos guardados"></f7-list-item>
        </f7-list>

        <f7-list class="no-margin-top search-list searchbar-found" media-list>
            <f7-list-item 
                v-for="(method, index) in $store.getters.sortArray('processingMethods')"
                :key="index"
                :title="method.name"
                link="#"
                @click="viewMethodDetail(method)"
            ></f7-list-item>
        </f7-list>

        <f7-fab position="right-bottom" slot="fixed" color="teal">
            <f7-icon material="add"></f7-icon>
            <f7-icon ios="f7:close" aurora="f7:close" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close @click="redirectNewMethod()" label="Crear nuevo método de procesamiento">
                    <f7-icon material="playlist_add"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>
    </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    data(){
        return {
            noMethods: false
        }
    },

    computed: {
        ...mapState(['processingMethods', 'selectedMethod'])
    },

    methods: {
        ...mapActions(['getAPIInfo']),

        getMethods(){
            this.$f7.dialog.preloader('Cargando');
            this.$firebase.auth().currentUser.getIdToken()
            .then(idToken => {
                return this.getAPIInfo({url: 'exam_processing_methods/list', userToken: idToken})
                })
            .then(resp => {
                let enabledMethods = resp.data.data.filter((method) => method.enabled === 1)
                this.noMethods = false;
                this.$store.commit('setWhatTo', {what: 'processingMethods', to: enabledMethods});
                this.$f7.dialog.close(); 
            })
            .catch((err) => {
                this.$f7.dialog.close();
                if(err.response.status !== 404){
                    this.$f7.dialog.alert(err.response.data.message || err.message, 'Ops, ha ocurrido un error');
                }
                else{
                    this.noMethods = true;
                }
            })
        },

        viewMethodDetail(method){
            this.$store.commit('setWhatTo',{what: 'selectedMethod', to: method});
            if(this.$f7.views.main.router.currentRoute.url === '/methods/'){
                this.$f7.views.main.router.navigate('/method-details/');
            }
            else{
                this.$f7.views.main.router.navigate('/method-details/', {reloadCurrent: true});
            }
        },

        redirectNewMethod(){
            this.$store.commit('setWhatTo',{what:'selectedMethod', to:{} });
            if(this.$f7.views.main.router.currentRoute.url === '/methods/'){
                this.$f7.views.main.router.navigate('/new-method/');
                }
            else{
                this.$f7.views.main.router.navigate('/new-method/', {reloadCurrent: true});
            }
        }
    },
    mounted(){
        if(!this.processingMethods){
            this.getMethods()
        }
    }

}
</script>