<template>
    <f7-block v-if="patientCopy !== null">
        <f7-row class="margin-bottom">
            <f7-col>
                <label>Tipo de documento</label>
                <f7-input
                    type="select"
                    @change="patientCopy.tipoIdentificador = $event.target.value"
                    :value="patientCopy.tipoIdentificador"
                    :disabled="readonly"
                >
                    <option value="">Seleccione...</option>
                    <option value="RUT">RUN</option>
                    <option value="PASAPORTE">Pasaporte</option>
                    <option value="DNI">DNI</option>
                    <option value="otro">Otro</option>
                </f7-input>
            </f7-col>
            <f7-col>
                <label>Número de documento</label>
                <f7-input
                    type="text"
                    @input="patientCopy.identificador = $event.target.value"
                    :value="patientCopy.identificador"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Alarma</label>
                <f7-input type="select" :disabled="readonly">
                    <option value="sin_deuda">Sin Deuda</option>
                    <option value="con_deuda">Con Deuda</option>
                </f7-input>
            </f7-col>
        </f7-row>

        <f7-row class="margin-bottom">
            <f7-col>
                <label>Apellido paterno</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    @input="patientCopy.primerApellido = $event.target.value"
                    :value="patientCopy.primerApellido"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Apellido materno</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    @input="patientCopy.segundoApellido = $event.target.value"
                    :value="patientCopy.segundoApellido"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Nombres</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    @input="patientCopy.nombres = $event.target.value"
                    :value="patientCopy.nombres"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
        </f7-row>

        <f7-row class="margin-bottom">
            <f7-col>
                <label>Fecha de nacimiento</label>
                <f7-input
                    class="input-uppercase"
                    type="text"
                    :maxlength="10"
                    @input="patientCopy.fechaNacimiento = $event.target.value"
                    :value="patientCopy.fechaNacimiento"
                    placeholder="dd/MM/AAAA"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Edad</label>
                <f7-input disabled>
                    <span class="uppercase">{{ patientAge(patientCopy.fechaNacimiento) }}</span>
                </f7-input>
            </f7-col>
            <f7-col>
                <label>Sexo</label>
                <f7-input
                    type="select"
                    @change="patientCopy.sexo = $event.target.value"
                    :value="patientCopy.sexo"
                    :disabled="readonly"
                >
                    <option value="">Seleccione...</option>
                    <option value="Masculino">M</option>
                    <option value="Femenino">F</option>
                </f7-input>
            </f7-col>
            <f7-col>
                <label>Nacionalidad</label>
                <f7-list class="list-no-margin-vertical no-hairlines">
                    <f7-list-item
                        class="smart-select-list-item-sm"
                        :title="patientCopy.nacionalidad || 'Seleccione...'"
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: true,
                            searchbarPlaceholder: 'Buscar nacionalidad',
                            closeOnSelect: true,
                            setValueText: false,
                            popupCloseLinkText: 'Cerrar',
                            pageTitle: 'Seleccione una nacionalidad',
                        }"
                        :disabled="readonly"
                    >
                        <select
                            @change="patientCopy.nacionalidad = $event.target.value"
                            :value="patientCopy.nacionalidad"
                        >
                            <option value="">Seleccione...</option>
                            <option v-for="country in paises" :key="country.id" :value="country.name">
                                {{ country.name }}
                            </option>
                        </select>
                    </f7-list-item>
                </f7-list>
            </f7-col>
        </f7-row>

        <f7-row>
            <f7-col width="60">
                <label>Dirección</label>
                <f7-input
                    type="text"
                    @input="patientCopy.direccion = $event.target.value"
                    :value="patientCopy.direccion"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
            <f7-col width="40">
                <label>Comuna</label>
                <f7-list no-hairlines class="no-margin-top margin-bottom">
                    <f7-list-item
                        class="smart-select-list-item-sm"
                        :title="patientCopy.comuna || 'Seleccione...'"
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: true,
                            searchbarPlaceholder: 'Buscar comuna',
                            closeOnSelect: true,
                            setValueText: false,
                            popupCloseLinkText: 'Cerrar',
                            pageTitle: 'Seleccione una comuna',
                        }"
                        :disabled="readonly"
                    >
                        <select @change="patientCopy.comuna = $event.target.value" :value="patientCopy.comuna">
                            <option value="">Seleccione...</option>
                            <optgroup
                                v-for="region in comunas.regions"
                                :key="region.number"
                                :label="region.name"
                            >
                                <option
                                    v-for="(comuna, index) in region.communes"
                                    :key="index"
                                    :value="`${comuna.name}`"
                                >
                                    {{ comuna.name }}
                                </option>
                            </optgroup>
                        </select>
                    </f7-list-item>
                </f7-list>
            </f7-col>
        </f7-row>

        <f7-row class="margin-bottom">
            <f7-col>
                <label>Telefono</label>
                <f7-input
                    type="text"
                    @input="patientCopy.telefono = $event.target.value"
                    :value="patientCopy.telefono"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
            <f7-col>
                <label>Email</label>
                <f7-input
                    type="text"
                    @input="patientCopy.email = $event.target.value"
                    :value="patientCopy.email"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
        </f7-row>

        <f7-row class="margin-bottom">
            <f7-col>
                <label>Prevision</label>
                <f7-input
                    type="text"
                    @input="patientCopy.prevision = $event.target.value"
                    :value="patientCopy.prevision"
                    :disabled="readonly"
                ></f7-input>
            </f7-col>
        </f7-row>

        <f7-button v-if="!readonly" fill @click="updatePatientInfo">Actualizar paciente</f7-button>
    </f7-block>
</template>

<script>
import { _patientAge } from "@/helpers/helpers";
import countries from "@/js/countries.json";
import communes from "@/js/comunas.json";

export default {
    props: {
        patient: {
            required: true,
        },
        admissionId: String,
        readonly: {
            type: Boolean,
            default: false,
            required: false,
        },
    },

    data() {
        return {
            patientAge: _patientAge,
            paises: countries,
            comunas: communes,

            patientCopy: null,
        };
    },

    watch: {
        patient() {
            this.patientCopy = Object.assign({}, this.patient);
        },
    },

    methods: {
        updatePatientInfo() {
            this.$emit("patient-info-changed", {
                patient: this.patientCopy,
                admissionId: this.admissionId,
            });
        },
    },
};
</script>
