<template>
    <f7-block class="no-margin-bottom">
        <div class="scanner">
            <video class="video"></video>
        </div>
        <canvas class="drawingBuffer"></canvas>
    </f7-block>
</template>

<script>
import Quagga from 'quagga';

export default {
    props: ['scannerType'],
    
    data(){
        return {
            sampleInfo: {
                barCode: '',
                sampleState: {},
               
            },
            scannerSize: {
                height: 480
            },
            scannedSamples: []
        }
    },
    methods: {
       scanCode(event){
           this.$emit('scanCode', this.sampleInfo)
       },

       detectCode(result){
            const code = result.codeResult.code;
            this.sampleInfo = {
                barCode: '',
                sampleState: {}
            };
            const numbers = /^[0-9]+$/;
            if(code.length !== 10 || !code.match(numbers)){
                return
            }
            if(this.scannedSamples.includes(code)){
                return    
            }
            this.scannedSamples.push(code);
            this.$firebase.database().ref('admittedSamples/' + code).once('value')
            .then((data) => {
                this.sampleInfo.barCode = code;
                if(data.val()){
                    this.sampleInfo.sampleState = data.val();
                    this.scanCode();
                    if(this.scannerType === "one"){
                        Quagga.stop();
                    }
                }
                else{
                    if(this.scannerType === "one"){
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(`La muestra con ID ${this.sampleInfo.barCode} no ha sido encontrada`, 
                        'Ops, ha ocurrido un error');
                    }
                    else{
                        this.sampleInfo.isRejected = true;
                        this.sampleInfo.reason = 'ID no encontrado'
                        this.scanCode();
                    }
                }
            })
            .catch(() => {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.message, 'Ops, ha ocurrido un error')
            })
       }
    },
    
    mounted(){
        this.$f7.dialog.preloader('Cargando...');
        this.scannerSize.height = window.screen.height * 0.6;
        Quagga.init({
            inputStream : {
                name : "Live",
                type : "LiveStream",
                target: '.scanner',
                constraints: {
                    height: this.scannerSize.height,
                },
            },
            decoder : {
                readers : ["code_128_reader"]
            },
            locate: true,
            locator: {
                halfSample: true,
                patchSize: "medium", // x-small, small, medium, large, x-large
            }
            
        }, 
        (err) => {
            if(err) {
                this.$emit('noScanner')
                this.$f7.dialog.close();
                this.$f7.dialog.alert(err.message, 'Ops, ha ocurrido un error');
                return
            }
            this.$f7.dialog.close();
            Quagga.start();
            Quagga.onProcessed((result) => {
                let drawingCtx = Quagga.canvas.ctx.overlay,
                drawingCanvas = Quagga.canvas.dom.overlay;

                if (result) {
                    if (result.boxes) {
                        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
                        result.boxes.filter(function (box) {
                            return box !== result.box;
                        }).forEach(function (box) {
                            Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "green", lineWidth: 2});
                        });
                    }
                    if (result.box) {
                        Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#00F", lineWidth: 2});
                    }
                    if (result.codeResult && result.codeResult.code) {
                        Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
                    }
                }
            });
            Quagga.onDetected(this.detectCode)
        });
    },
    beforeDestroy(){
        Quagga.offDetected(this.detectCode)
        Quagga.stop();
    }
}
</script>

<style scoped>

.drawingBuffer {
    position: absolute;
    top: 0;
}

.video{
    width: 100%;
}

</style>