import { mapState , mapGetters } from 'vuex';

export const photoMixin = {
    data: function(){
        return {
            notifLoading: null,
            progressdialog: null,
            task: null,
            profile_avatarObserver: null
            }
    },
    computed: {
        ...mapState(['loadingAvatar','tempOldAvatar']),
        ...mapGetters(['myAvatar'])
    },
    watch: {
		myAvatar: function(nuevoAvatar){
            if(nuevoAvatar!=this.loadingAvatar){
                //this.$f7.closeNotification(".procesImageUpload");
                //TODO CERRAR NOTIFICACION DE IMAGE UPLOAD
                //this.$f7.notification.close(".procesImageUpload");
                if(this.notifLoading){
                  this.notifLoading.close();
                }
               // this.$localforage.setItem('profile_userInfo',this.profile_userInfo);
            }
        },
    },
    mounted(){

    },
    methods: {
      abrirLoadinNoti(){
        if(!this.notifLoading){
          this.notifLoading=this.$f7.notification.create({
            title:'Carga Ok',
            titleRightText:'100%',
            subtitle: 'Procesando imagen',
            text:'Esto puede tardar algunos segundos...',
            closeTimeout: 90000,
            cssClass: "procesImageUpload",
            icon: '<i class="material-icons">cloud_upload</i>'
        });
        }
        this.notifLoading.open();
      },
      encenderObservableAvatar () {
        console.log('enciendendo observable avatar');
        if(this.profile_avatarObserver){
          this.profile_avatarObserver.off()
        }
        this.profile_avatarObserver = this.$firebase.database().ref('privx/'+this.$firebase.auth().currentUser.uid+'/avtrs');
        this.profile_avatarObserver.on('value', snapshot => {
          let allavatars = snapshot.val() || {};
          console.log('hubo un update de mini',allavatars);
          let thumb=allavatars.thumb;
          console.log('el thumb es',thumb);
          if(thumb && (this.tempOldAvatar!=thumb)){
            this.$store.commit('setWhatTo', {what:'tempOldAvatar', to: ''});
            this.$store.commit('updateMyAvatar', thumb);
            if(this.notifLoading){
              this.notifLoading.close();
            }
          }
      });
    },
        alsnapshot: function (snapshot) {
            let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            let nentero = Math.round(percentage);
            if (!isNaN(nentero)) {
                this.progressdialog.setProgress(nentero);
                this.progressdialog.setText(nentero + '%');
            }
        },
        alerror: function (err) {
            console.log(err.code);
            this.progressdialog.close();
            this.$f7.dialog.alert(err.code,'upload error');
        },
        altermino: function () {
            console.log(this.task,'se subio la foto!');
              this.$store.commit('setWhatTo', {what:'tempOldAvatar', to: this.myAvatar});
              this.$store.commit('updateMyAvatar', this.$store.state.loadingAvatar);
            this.progressdialog.close();
            this.abrirLoadinNoti();
            //al terminar de subir la foto, encender un observable a foto nueva
              this.encenderObservableAvatar();
            //mandar a guardar la URL del original subido a usergen/uid/original

            // this.task.snapshot.ref.getDownloadURL().then((downloadURL)=> {
            //     console.log('LA DOWNNLOAD url ES ',downloadURL,this.task);
            //    // this.$firebase.database().ref('usergen/'+this.$firebase.auth().currentUser.uid+'/original').set(downloadURL);
            // });
        },
        subirfoto: function (filereceive, filename) {
            this.progressdialog = this.$f7.dialog.progress('Cargando imagen', 0);
            this.progressdialog.setText('0%');
            let ruta='usergen/' + this.$firebase.auth().currentUser.uid + '/' + filename;
             this.task = this.$firebase.storage().ref(ruta).put(filereceive);
             this.task.on('state_changed',
                this.alsnapshot,
                this.alerror,
                this.altermino
            );
        },
        tomarFoto: function () {
          if (this.$f7.device && this.$f7.device.cordova) {
            this.tomarFotoCordova();
          }
          else{
              this.$$('#myavtr-input').click();
          }
        },
        tomarFotoCordova: function () {
          this.$f7.dialog.create({
            title:  'Cambiar mi avatar',
            destroyOnClose: true,
            text: 'Use una imagen representativa para que sus contactos puedan identificarlo claramente. Seleccione el origen de la imagen:',
            buttons: [
              {
                text: 'Tomar de la cámara',
                onClick: ()=> {
                  console.log('elegir camara');
                  this.dispararCordovaCamar(true);
                }
              },
              {
                text: 'Tomar del álbum',
                onClick: ()=> {
                    console.log('elegir album');
                    this.dispararCordovaCamar(false);
                }
              },
              {
                text: 'Cancelar'
              },
            ],
            verticalButtons: true,
          }).open();
      },
      dispararCordovaCamar: function(cial){
        let orig=cial ? Camera.PictureSourceType.CAMERA : Camera.PictureSourceType.PHOTOLIBRARY;
        let configs={
            destinationType: Camera.DestinationType.FILE_URI,
            sourceType: orig,
            correctOrientation: true,
            quality: 70
        };
        navigator.camera.getPicture(this.camCordv_onSuccess, this.camCordv_onFail, configs);
    },
    filesysCordv_onResolveSuccess: function (fileEntry) {
      console.log(fileEntry)
      let funtocall = this.subirfoto;
      fileEntry.file((file) => {
          var reader = new FileReader();
          reader.onloadend = function () {
              var type = "image/jpeg";
              var blob = new Blob([new Uint8Array(this.result)], { type: type });
              funtocall(blob, fileEntry.name);
          };
          reader.readAsArrayBuffer(file);
      });
      },
      filesysCordv_onResolveError: function (err) {
          console.log('fallo con error ', err)
      },
    camCordv_onSuccess: function (imageURI) {
        console.log('foto tomada con info ', imageURI)
        window.resolveLocalFileSystemURL(
            imageURI,
            this.filesysCordv_onResolveSuccess,
            this.filesysCordv_onResolveError
        );
    },
    camCordv_onFail: function (message) {
        if(message=='has no access to assets'){
            console.log('Sin selección');
        }
        else{
            this.$f7.dialog.alert(message, 'Error');
        }
    },
        filesChange: function (e) {
            let file = e.target.files[0];
            if (file) {
                console.log(file);
                this.subirfoto(file, file.name);
            }
        }
    }
}